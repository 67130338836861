import * as React from 'react';
import {
  NumberField,
} from 'react-admin';

export default React.memo((props:any) => {
  const { source, className } = props;
  if (source) {
    return (
      <NumberField
        className={className}
        source={source}
        locales="en"
        options={{ style: 'decimal', maximumFractionDigits: 2 }}
      />
    );
  }
  return null;
});
