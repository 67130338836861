import React from 'react';
import { useTranslate, FunctionField, FunctionFieldProps } from 'react-admin';
import get from 'lodash/get';

interface TranslateFieldProps extends Omit<FunctionFieldProps, 'render'> {
  namespace?: string;
}

export default React.memo(({ namespace, ...props }: TranslateFieldProps) => {
  const translate = useTranslate();
  const getKey = (content: string) => {
    try {
      return content.toLowerCase().replace(/ /g, '_');
    } catch {
      return content;
    }
  };
  let prefix = '';
  if (namespace === undefined) {
    prefix = 'mai.';
  } else if (namespace) {
    prefix = `${namespace}.`;
  }

  return (
    <FunctionField
      {...props}
      render={(record: string, source: any) => (get(record, source)
        ? translate(`${prefix}${getKey(get(record, source))}`)
        : '')}
    />
  );
});
