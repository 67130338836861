import React from 'react';
import { Edit } from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import ProductionItemForm from './form/ProductionItemForm';

export default function EditProductionItemContainer() {
  return (
    <Edit redirect="show" actions={<EditActions />}>
      <ProductionItemForm />
    </Edit>
  );
}
