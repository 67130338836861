/* eslint-disable no-nested-ternary */
// eslint-disable-next-line import/no-extraneous-dependencies
import { AxiosError } from 'axios';
import { DataProvider, HttpError, withLifecycleCallbacks } from 'react-admin';
import repositories from '../repositories';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

const convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(file.rawFile);
});

const prepareImageForUpload = async (params: any): Promise<any> => {
  let image;
  if (params.srcImage && params.srcImage.rawFile instanceof File) {
    image = await convertFileToBase64(params.srcImage);
    return {
      ...params,
      image,
    };
  }
  return params;
};

const handleError = (reason: AxiosError | HttpError) => {
  const axiosReason = reason as AxiosError;

  if (axiosReason?.toJSON) {
    const body = axiosReason.toJSON();
    throw new Error(JSON.stringify(body));
  }

  throw reason;
};

const dataProvider: DataProvider = withLifecycleCallbacks({
  getList: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    return repositories.get(`/${resource}`, {
      params: {
        Page: page,
        Limit: perPage,
        SortBy: field,
        Sort: order,
        ...params.filter,
      },
    }).then((res: ResponseGenerator) => ({
      data: res?.data?.list ?? [],
      total: res?.data?.paging?.total ?? 0,
      res: res ?? {},
    })).catch(handleError);
  },

  getOne: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.get(`/${resource}/${id}`).then((res: ResponseGenerator) => ({
      data: res?.data ?? {},
      res: res ?? {},
    })).catch(handleError);
  },

  getMany: (resource: string, params: any) => repositories.get(`/${resource}`, {
    params: {
      ids: params.ids.join(','),
    },
  }).then((res: ResponseGenerator) => ({
    data: res?.data?.list ?? {},
    total: res?.data?.paging?.total ?? 0,
    res: res ?? {},
  })).catch(handleError),

  getManyReference: (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.get(`/${resource}`, {
      Page: page,
      Limit: perPage,
      Field: field,
      Order: order,
      ...params.filter,
      [params.target]: id,
    }).then((res: ResponseGenerator) => ({
      data: res?.data?.list ?? [],
      total: res?.data?.paging?.total ?? 0,
      res: res ?? {},
    })).catch(handleError);
  },

  create: (resource: string, params: any) => repositories.post(`/${resource}`, params.data)
    .then((res: ResponseGenerator) => ({
      data: { ...params.data, id: res?.data?.data?.id ?? 0 },
      res: res ?? {},
    })).catch(handleError),

  update: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.put(`/${resource}/${id}`, params.data).then((res: ResponseGenerator) => ({
      data: params.data ?? {},
      res: res ?? {},
    })).catch(handleError);
  },

  updateMany: (resource: string, params: any) => repositories.put(`/${resource}`, params.data, {
    params: {
      ids: params.ids,
    },
  }).then((res: ResponseGenerator) => ({
    data: res.data ?? {},
    res: res ?? {},
  })).catch(handleError),

  delete: (resource: string, params: any) => {
    const id = params.Id ? params.Id : params.id ? params.id : 0;
    return repositories.delete(`/${resource}/${id}`, { params: params.data }).then((res: ResponseGenerator) => ({
      data: res.data ?? {},
      res: res ?? {},
    })).catch(handleError);
  },

  deleteMany: (resource: string, params: any) => repositories.delete(`/${resource}`, {
    params: {
      ids: params.ids.join(','),
    },
  }).then((res: ResponseGenerator) => ({
    data: [],
    res: res ?? {},
  })).catch(handleError),
}, [
  {
    resource: 'product/raw-materials',
    beforeSave: async (params: any) => prepareImageForUpload(params),
  },
  {
    resource: 'product/products',
    beforeSave: async (params: any) => prepareImageForUpload(params),
  },
  {
    resource: 'stock/shelf',
    beforeSave: async (params: any) => prepareImageForUpload(params),
  },
]);
export default dataProvider;
