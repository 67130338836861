import { Paging } from 'api/models';
import { useCallback } from 'react';
import { useNotify } from 'react-admin';
import {
  QueryKey, UseInfiniteQueryOptions, UseQueryOptions, UseQueryResult, useInfiniteQuery, useQuery,
} from 'react-query';
import api from './api';

export type QueryOptions<M> = Omit<UseQueryOptions<M, unknown, M, QueryKey>, 'queryKey'>;
export type QueryOptionsInfinity<M> = Omit<UseInfiniteQueryOptions<M, unknown, M, M, any[]>, 'queryKey' | 'queryFn'>;

/* eslint-disable arrow-body-style */

const globalId = (() => {
  let currentId = 0;
  const map = new WeakMap();

  return (object: any) => {
    if (!map.has(object)) {
      currentId += 1;
      map.set(object, currentId);
    }

    return map.get(object);
  };
})();

export function getApiQuery<PropsType, T>(
  fn: (fnprops?: PropsType) => Promise<T>,
  globalOptions?: QueryOptions<T>,
): (props?: PropsType, options?: QueryOptions<T>) => UseQueryResult<T>;

export function getApiQuery<PropsType, T>(
  fn: (fnprops: PropsType) => Promise<T>,
  globalOptions?: QueryOptions<T>,
): (props: PropsType, options?: QueryOptions<T>) => UseQueryResult<T>;

export function getApiQuery<PropsType, T>(
  fn: (fnprops?: PropsType) => Promise<T>,
  globalOptions?: QueryOptions<T>,
) {
  return (
    props?: PropsType,
    options?: QueryOptions<T>,
  ) => {
    const boundFunction = fn.bind(api);
    const functionId = globalId(fn);

    return useQuery<T>([functionId, props], () => boundFunction(props), { ...globalOptions, ...options });
  };
}

/* eslint-enable arrow-body-style */
interface ApiCallOptions {
  showMessage?: boolean
}

export function useApiCall<PropsType, T>(fn: (fnprops: PropsType) => Promise<T>): (fnprops: PropsType) => Promise<T>;

export function useApiCall<PropsType, T>(fn: (fnprops?: PropsType) => Promise<T>, options?: ApiCallOptions) {
  const notify = useNotify();

  const result = useCallback(async (fnprops?: PropsType) => {
    try {
      const apiResult = await fn.bind(api)(fnprops);
      return apiResult;
    } catch {
      notify('errrrr', { type: 'error' });
      if (options?.showMessage) {
        console.log('jooo');
      }
    }
  }, [fn, notify, options]);

  return result;
}

interface ListResult<T> {
  paging: Paging,
  list: Array<T>
}

export function getInfiniteApiQuery<PropsType, T>(
  fn: (fnprops: PropsType) => Promise<ListResult<T>>,
  globalOptions?: QueryOptionsInfinity<ListResult<T>>,
) {
  return (
    props: PropsType,
    options?: QueryOptionsInfinity<ListResult<T>>,
  ) => {
    const boundFunction = fn.bind(api);
    const functionId = globalId(fn);

    return useInfiniteQuery(
      [functionId, props],
      (innerProps) => {
        const page: number = innerProps?.pageParam ?? 1;

        const args = {
          ...props,
          page,
        };
        return boundFunction(args);
      },
      {
        getNextPageParam: (lastPage) => (
          lastPage.paging.page < lastPage.paging.totalPages ? lastPage.paging.page + 1 : undefined
        ),
        ...globalOptions,
        ...options,
      },
    );
  };
}

function delay(ms: number) {
  return new Promise((resolve) => { setTimeout(resolve, ms); });
}
/**
 * Retries a function n number of times before giving up
 */
type ApiFuncT = (...arg0: any[]) => any;
export async function retry<T extends ApiFuncT>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  retryCount = 1,
): Promise<Awaited<ReturnType<T>>> {
  const currRetry = typeof retryCount === 'number' ? retryCount : 1;
  try {
    const result = await fn(...args);
    return result;
  } catch (e) {
    console.log(`Retry ${currRetry} failed.`);
    if (currRetry > maxTry) {
      console.log(`All ${maxTry} retry attempts exhausted`);
      throw e;
    }
    await delay(currRetry * 1000);
    return retry(fn, args, maxTry, currRetry + 1);
  }
}
