/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockShipmentLineSchema } from './StockShipmentLineSchema';
import {
    StockShipmentLineSchemaFromJSON,
    StockShipmentLineSchemaFromJSONTyped,
    StockShipmentLineSchemaToJSON,
} from './StockShipmentLineSchema';

/**
 * 
 * @export
 * @interface StockIncomingShipmentCreate
 */
export interface StockIncomingShipmentCreate {
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentCreate
     */
    supplierId: number;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    transportType: StockIncomingShipmentCreateTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentCreate
     */
    estimatedTimeofDeparture?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentCreate
     */
    estimatedTimeofArrival: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentCreate
     */
    actualTimeofDeparture?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    shipmentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    poNumbers?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    ocNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    transportTrackingNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentCreate
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<StockShipmentLineSchema>}
     * @memberof StockIncomingShipmentCreate
     */
    lines?: Array<StockShipmentLineSchema> | null;
}


/**
 * @export
 */
export const StockIncomingShipmentCreateTransportTypeEnum = {
    Sea: 'Sea',
    Air: 'Air',
    Truck: 'Truck',
    Express: 'Express',
    Other: 'Other'
} as const;
export type StockIncomingShipmentCreateTransportTypeEnum = typeof StockIncomingShipmentCreateTransportTypeEnum[keyof typeof StockIncomingShipmentCreateTransportTypeEnum];


/**
 * Check if a given object implements the StockIncomingShipmentCreate interface.
 */
export function instanceOfStockIncomingShipmentCreate(value: object): value is StockIncomingShipmentCreate {
    if (!('supplierId' in value) || value['supplierId'] === undefined) return false;
    if (!('transportType' in value) || value['transportType'] === undefined) return false;
    if (!('estimatedTimeofArrival' in value) || value['estimatedTimeofArrival'] === undefined) return false;
    return true;
}

export function StockIncomingShipmentCreateFromJSON(json: any): StockIncomingShipmentCreate {
    return StockIncomingShipmentCreateFromJSONTyped(json, false);
}

export function StockIncomingShipmentCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockIncomingShipmentCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'supplierId': json['supplierId'],
        'transportType': json['transportType'],
        'estimatedTimeofDeparture': json['estimatedTimeofDeparture'] == null ? undefined : (new Date(json['estimatedTimeofDeparture'])),
        'estimatedTimeofArrival': (new Date(json['estimatedTimeofArrival'])),
        'actualTimeofDeparture': json['actualTimeofDeparture'] == null ? undefined : (new Date(json['actualTimeofDeparture'])),
        'shipmentName': json['shipmentName'] == null ? undefined : json['shipmentName'],
        'poNumbers': json['poNumbers'] == null ? undefined : json['poNumbers'],
        'ocNumber': json['ocNumber'] == null ? undefined : json['ocNumber'],
        'transportTrackingNumber': json['transportTrackingNumber'] == null ? undefined : json['transportTrackingNumber'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'lines': json['lines'] == null ? undefined : ((json['lines'] as Array<any>).map(StockShipmentLineSchemaFromJSON)),
    };
}

export function StockIncomingShipmentCreateToJSON(value?: StockIncomingShipmentCreate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'supplierId': value['supplierId'],
        'transportType': value['transportType'],
        'estimatedTimeofDeparture': value['estimatedTimeofDeparture'] == null ? undefined : ((value['estimatedTimeofDeparture'] as any).toISOString()),
        'estimatedTimeofArrival': ((value['estimatedTimeofArrival']).toISOString()),
        'actualTimeofDeparture': value['actualTimeofDeparture'] == null ? undefined : ((value['actualTimeofDeparture'] as any).toISOString()),
        'shipmentName': value['shipmentName'],
        'poNumbers': value['poNumbers'],
        'ocNumber': value['ocNumber'],
        'transportTrackingNumber': value['transportTrackingNumber'],
        'comment': value['comment'],
        'lines': value['lines'] == null ? undefined : ((value['lines'] as Array<any>).map(StockShipmentLineSchemaToJSON)),
    };
}

