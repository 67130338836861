/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockSplitEntity
 */
export interface StockSplitEntity {
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    articleName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    batchNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    totalAmount: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    packageCount: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitEntity
     */
    sourceStockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    comment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StockSplitEntity
     */
    appliedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockSplitEntity
     */
    applier?: string | null;
}

/**
 * Check if a given object implements the StockSplitEntity interface.
 */
export function instanceOfStockSplitEntity(value: object): value is StockSplitEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
    if (!('packageCount' in value) || value['packageCount'] === undefined) return false;
    if (!('sourceStockLocationId' in value) || value['sourceStockLocationId'] === undefined) return false;
    return true;
}

export function StockSplitEntityFromJSON(json: any): StockSplitEntity {
    return StockSplitEntityFromJSONTyped(json, false);
}

export function StockSplitEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockSplitEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'articleName': json['articleName'] == null ? undefined : json['articleName'],
        'batchNo': json['batchNo'] == null ? undefined : json['batchNo'],
        'totalAmount': json['totalAmount'],
        'packageCount': json['packageCount'],
        'sourceStockLocationId': json['sourceStockLocationId'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'appliedDate': json['appliedDate'] == null ? undefined : (new Date(json['appliedDate'])),
        'applier': json['applier'] == null ? undefined : json['applier'],
    };
}

export function StockSplitEntityToJSON(value?: StockSplitEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'articleNo': value['articleNo'],
        'articleName': value['articleName'],
        'batchNo': value['batchNo'],
        'totalAmount': value['totalAmount'],
        'packageCount': value['packageCount'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'comment': value['comment'],
        'appliedDate': value['appliedDate'] == null ? undefined : ((value['appliedDate'] as any).toISOString()),
        'applier': value['applier'],
    };
}

