import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  SelectInput,
} from 'react-admin';
import { Grid } from '@mui/material';
import EditToolBar from 'components/commons/editToolBar';
import EditActions from 'components/layout/actions/edit';
import useHasAccess from 'hooks/useHasAccess';
import { useCategories } from 'repositories/products/categories';
import Loading from 'components/shared/loading';
import { ColorInput } from 'react-admin-color-picker';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'product/categories/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'product/categories/{id}', httpMethod: 'DELETE' });
  const { data } = useCategories();
  const { id } = useParams();

  if (!data) {
    return <Loading />;
  }

  const filteredCategories = data.filter((e: any) => e.position < 4 && String(e.id) !== id);

  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} isDelete={canDelete} />}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={2} md={1} />
          <Grid item xs={4} md={5}>
            <TextInput
              source="name"
              validate={[required()]}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <ColorInput
              source="color"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2} md={1} />

          <Grid item xs={2} md={1} />
          <Grid item xs={8} md={10}>
            <SelectInput
              source="parentId"
              margin="none"
              choices={filteredCategories ?? []}
              emptyValue="0"
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />

          <Grid item xs={2} md={1} />
          <Grid item xs={8} sm={10}>
            <TextInput
              source="description"
              validate={maxLength(100)}
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />
        </Grid>
      </SimpleForm>
    </Edit>
  );
});
