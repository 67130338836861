import React from 'react';
import {
  AutocompleteInput,
  AutocompleteInputProps,
  required,
} from 'react-admin';
import { useBusinessPartners } from 'repositories/businessPartners/businessPartner';

interface BusinessPartnerInputProps extends Omit<AutocompleteInputProps, 'choices' | 'validate'> {
  required?: boolean
}

export default function BusinessPartnerInput(props: BusinessPartnerInputProps) {
  const {
    required: isRequired,
    ...rest
  } = props;

  const { data } = useBusinessPartners();
  if (!data) {
    return null;
  }

  return (
    <AutocompleteInput
      {...rest}
      validate={isRequired ? [required()] : undefined}
      choices={data}
    />
  );
}
