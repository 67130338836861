/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BusinessPartnerSchema } from './BusinessPartnerSchema';
import {
    BusinessPartnerSchemaFromJSON,
    BusinessPartnerSchemaFromJSONTyped,
    BusinessPartnerSchemaToJSON,
} from './BusinessPartnerSchema';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';

/**
 * 
 * @export
 * @interface PaginatedListBusinessPartnerSchema
 */
export interface PaginatedListBusinessPartnerSchema {
    /**
     * 
     * @type {Array<BusinessPartnerSchema>}
     * @memberof PaginatedListBusinessPartnerSchema
     */
    list: Array<BusinessPartnerSchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListBusinessPartnerSchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListBusinessPartnerSchema interface.
 */
export function instanceOfPaginatedListBusinessPartnerSchema(value: object): value is PaginatedListBusinessPartnerSchema {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListBusinessPartnerSchemaFromJSON(json: any): PaginatedListBusinessPartnerSchema {
    return PaginatedListBusinessPartnerSchemaFromJSONTyped(json, false);
}

export function PaginatedListBusinessPartnerSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListBusinessPartnerSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(BusinessPartnerSchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListBusinessPartnerSchemaToJSON(value?: PaginatedListBusinessPartnerSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(BusinessPartnerSchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

