import {
  ProductRawMaterialEntityIncotermEnum,
  StockIncomingShipmentListStateEnum,
  StockIncomingShipmentListTransportTypeEnum,
} from 'api';
import enumToOptions from 'utils/enumToOptions';

export const incomingShipmentState = enumToOptions(
  StockIncomingShipmentListStateEnum,
  (key) => `mai.incomingShipment.shipmentState.${key}`,
);
export const incomingShipmentTransportType = enumToOptions(
  StockIncomingShipmentListTransportTypeEnum,
  (key) => `mai.incomingShipment.transportType.${key}`,
);

export const incoTerms = enumToOptions(
  ProductRawMaterialEntityIncotermEnum,
);
