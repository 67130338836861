import React from 'react';
import { Edit } from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import ShipmentForm from './form/shipmentForm';

export default function IncomingShipmentCreate() {
  return (
    <Edit actions={<EditActions />} mutationMode="pessimistic" redirect="show">
      <ShipmentForm />
    </Edit>
  );
}
