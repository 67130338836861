/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUpdatePasswordSchema
 */
export interface AuthUpdatePasswordSchema {
    /**
     * 
     * @type {string}
     * @memberof AuthUpdatePasswordSchema
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUpdatePasswordSchema
     */
    newPassword: string;
}

/**
 * Check if a given object implements the AuthUpdatePasswordSchema interface.
 */
export function instanceOfAuthUpdatePasswordSchema(value: object): value is AuthUpdatePasswordSchema {
    if (!('oldPassword' in value) || value['oldPassword'] === undefined) return false;
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function AuthUpdatePasswordSchemaFromJSON(json: any): AuthUpdatePasswordSchema {
    return AuthUpdatePasswordSchemaFromJSONTyped(json, false);
}

export function AuthUpdatePasswordSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUpdatePasswordSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'oldPassword': json['oldPassword'],
        'newPassword': json['newPassword'],
    };
}

export function AuthUpdatePasswordSchemaToJSON(value?: AuthUpdatePasswordSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldPassword': value['oldPassword'],
        'newPassword': value['newPassword'],
    };
}

