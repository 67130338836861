import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
} from 'react-admin';
import { Grid } from '@mui/material';
import useHasAccess from 'hooks/useHasAccess';
import { useCategories } from 'repositories/products/categories';
import Loading from 'components/shared/loading';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canSearch = hasAccess({ url: 'product/categories/', httpMethod: 'GET' });
  const { data } = useCategories();

  if (!data) {
    return <Loading />;
  }

  const filteredCategories = data.filter((e: any) => e.position < 4);
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <SimpleForm>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={2} md={1} />
          <Grid item xs={4} md={5}>
            <TextInput
              source="name"
              validate={[required()]}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={5}>
            <SelectInput
              source="parentId"
              choices={filteredCategories ?? []}
              emptyValue="0"
              margin="none"
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />
          <Grid item xs={2} md={1} />
          <Grid item xs={8} sm={10}>
            <TextInput
              source="description"
              validate={maxLength(100)}
              fullWidth
              variant="outlined"
              multiline
              rows={2}
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={1} />
        </Grid>
      </SimpleForm>
    </Create>
  );
});
