/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockLinesLineDetail } from './StockLinesLineDetail';
import {
    StockLinesLineDetailFromJSON,
    StockLinesLineDetailFromJSONTyped,
    StockLinesLineDetailToJSON,
} from './StockLinesLineDetail';

/**
 * 
 * @export
 * @interface StockMoveLineEntity
 */
export interface StockMoveLineEntity {
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    resourceId: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    stockLineId: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    destinationStockShelfId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    stockLotName: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    supplierLotName: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    stockShelfName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    resourceName: string;
    /**
     * 
     * @type {number}
     * @memberof StockMoveLineEntity
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    resourceCategory: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    articleNo: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    quantityTypeName: string;
    /**
     * 
     * @type {string}
     * @memberof StockMoveLineEntity
     */
    createdBy?: string | null;
    /**
     * 
     * @type {StockLinesLineDetail}
     * @memberof StockMoveLineEntity
     */
    stockLine: StockLinesLineDetail;
    /**
     * 
     * @type {StockLinesLineDetail}
     * @memberof StockMoveLineEntity
     */
    destinationStockLine?: StockLinesLineDetail;
    /**
     * 
     * @type {Date}
     * @memberof StockMoveLineEntity
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the StockMoveLineEntity interface.
 */
export function instanceOfStockMoveLineEntity(value: object): value is StockMoveLineEntity {
    if (!('resourceId' in value) || value['resourceId'] === undefined) return false;
    if (!('stockLineId' in value) || value['stockLineId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('stockLotName' in value) || value['stockLotName'] === undefined) return false;
    if (!('supplierLotName' in value) || value['supplierLotName'] === undefined) return false;
    if (!('resourceName' in value) || value['resourceName'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('resourceCategory' in value) || value['resourceCategory'] === undefined) return false;
    if (!('articleNo' in value) || value['articleNo'] === undefined) return false;
    if (!('quantityTypeName' in value) || value['quantityTypeName'] === undefined) return false;
    if (!('stockLine' in value) || value['stockLine'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function StockMoveLineEntityFromJSON(json: any): StockMoveLineEntity {
    return StockMoveLineEntityFromJSONTyped(json, false);
}

export function StockMoveLineEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveLineEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'resourceId': json['resourceId'],
        'stockLineId': json['stockLineId'],
        'destinationStockShelfId': json['destinationStockShelfId'] == null ? undefined : json['destinationStockShelfId'],
        'amount': json['amount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'id': json['id'],
        'stockLotName': json['stockLotName'],
        'supplierLotName': json['supplierLotName'],
        'stockShelfName': json['stockShelfName'] == null ? undefined : json['stockShelfName'],
        'resourceName': json['resourceName'],
        'quantityTypeId': json['quantityTypeId'],
        'resourceCategory': json['resourceCategory'],
        'articleNo': json['articleNo'],
        'quantityTypeName': json['quantityTypeName'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'stockLine': StockLinesLineDetailFromJSON(json['stockLine']),
        'destinationStockLine': json['destinationStockLine'] == null ? undefined : StockLinesLineDetailFromJSON(json['destinationStockLine']),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function StockMoveLineEntityToJSON(value?: StockMoveLineEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'resourceId': value['resourceId'],
        'stockLineId': value['stockLineId'],
        'destinationStockShelfId': value['destinationStockShelfId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'id': value['id'],
        'stockLotName': value['stockLotName'],
        'supplierLotName': value['supplierLotName'],
        'stockShelfName': value['stockShelfName'],
        'resourceName': value['resourceName'],
        'quantityTypeId': value['quantityTypeId'],
        'resourceCategory': value['resourceCategory'],
        'articleNo': value['articleNo'],
        'quantityTypeName': value['quantityTypeName'],
        'createdBy': value['createdBy'],
        'stockLine': StockLinesLineDetailToJSON(value['stockLine']),
        'destinationStockLine': StockLinesLineDetailToJSON(value['destinationStockLine']),
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

