import { AuthGroupEntity } from 'api';
import urls from 'app/urls';
import TranslateField from 'components/commons/translateField';
import ListActions from 'components/layout/actions/list';
import List from 'components/list/List';
import { ActionButtons } from 'components/list/actionButtons';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  TextField,
} from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';

export default React.memo(() => {
  const s = Source<AuthGroupEntity>();

  return (
    <List filters={filters} actions={<ListActions exporter />}>
      <Datagrid>
        <TextField {...s('id')} sortable={false} />
        <TextField {...s('name')} sortable={false} />
        <TranslateField {...s('typeName')} sortable={false} />
        <TextField {...s('description')} sortable={false} />
        <ActionButtons url={urls.Groups_GetDetail.url} label="action" />
      </Datagrid>
    </List>
  );
});
