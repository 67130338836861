import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Popover } from '@mui/material';
import clsx from 'clsx';
import { PropertyPath } from 'lodash';
import get from 'lodash/get';
import * as React from 'react';
import {
  FieldProps,
  useRecordContext,
} from 'react-admin';
import styles from './imagePreview.module.scss';

export interface ImageFieldProps<
  RecordType extends Record<string, any> = Record<string, any>,
> extends Omit<FieldProps<RecordType>, 'source'> {
  source: PropertyPath,
  title?: string;
}

export function ImagePreview<
  RecordType extends Record<string, any> = Record<string, any>,
>(
  props: ImageFieldProps<RecordType>,
) {
  const {
    className,
    source,
    title,
  } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source);
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

  if (!sourceValue) {
    return null;
  }
  const open = Boolean(anchorEl);
  // const open = true;

  const titleValue = title ? get(record, title)?.toString() || title : null;

  return (
    <div className={clsx(styles.preview, open && 'active', className)}>
      <PhotoCameraIcon
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        color={open ? 'error' : undefined}
      />
      <Popover
        className={styles.preview_box}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <img
          title={titleValue}
          alt={titleValue}
          src={sourceValue?.toString()}
        />
      </Popover>
    </div>

  );
}
