import ra from './vi/ra';
import mai from './vi/mai';
import resources from './vi/resources';

const messages = {
  ra,
  mai,
  resources,
};

export default messages;
