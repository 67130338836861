import StockSplitList from './list';
import StockSplitCreate from './create';
import ShowSplitMove from './show';

export default {
  name: 'stock/stock-split',
  list: StockSplitList,
  create: StockSplitCreate,
  show: ShowSplitMove,
  options: {
    label: 'Stock Split',
  },
  children: [
  ],
};
