import api from 'repositories/api';
import { getApiQuery } from 'repositories/base';

export const useStockLocations = getApiQuery(api.stockLocationSearch, {
  staleTime: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});
export default {
  getShelfDetail: (id: number) => api.stockShelfGet({ id }),
};
