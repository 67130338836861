/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShipmentComparisonLine
 */
export interface StockShipmentComparisonLine {
    /**
     * 
     * @type {number}
     * @memberof StockShipmentComparisonLine
     */
    rawMaterialId: number;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentComparisonLine
     */
    resourceName: string;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentComparisonLine
     */
    articleNo?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentComparisonLine
     */
    quantityTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentComparisonLine
     */
    plannedAmount: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentComparisonLine
     */
    actualAmount: number;
}

/**
 * Check if a given object implements the StockShipmentComparisonLine interface.
 */
export function instanceOfStockShipmentComparisonLine(value: object): value is StockShipmentComparisonLine {
    if (!('rawMaterialId' in value) || value['rawMaterialId'] === undefined) return false;
    if (!('resourceName' in value) || value['resourceName'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('plannedAmount' in value) || value['plannedAmount'] === undefined) return false;
    if (!('actualAmount' in value) || value['actualAmount'] === undefined) return false;
    return true;
}

export function StockShipmentComparisonLineFromJSON(json: any): StockShipmentComparisonLine {
    return StockShipmentComparisonLineFromJSONTyped(json, false);
}

export function StockShipmentComparisonLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShipmentComparisonLine {
    if (json == null) {
        return json;
    }
    return {
        
        'rawMaterialId': json['rawMaterialId'],
        'resourceName': json['resourceName'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'quantityTypeId': json['quantityTypeId'],
        'plannedAmount': json['plannedAmount'],
        'actualAmount': json['actualAmount'],
    };
}

export function StockShipmentComparisonLineToJSON(value?: StockShipmentComparisonLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rawMaterialId': value['rawMaterialId'],
        'resourceName': value['resourceName'],
        'articleNo': value['articleNo'],
        'quantityTypeId': value['quantityTypeId'],
        'plannedAmount': value['plannedAmount'],
        'actualAmount': value['actualAmount'],
    };
}

