export default {
  GroupType: {
    PRIVATE: 10,
    DEFAULT: 20,
    CUSTOM: 30,
  },
  DefaultGroup: {
    ADMIN: 'ADM',
    EMPLOYEE: 'EMP',
  },
  SizePrint: {
    k58: {
      width: '58mm',
      height: '40mm',
    },
  },
  Labels: {
    SHELF: {
      string: 'SS_{id}',
      regex: /SS_([\d\w\-_]+)/,
    },
    STOCKLINE: {
      string: 'SL{id}_{amount}',
      regex: /SL(\d+)_(\d+)/,
    },
  },
  StockTraceType: {
    NONE: {
      VALUE: 'None',
    },
    LOT: {
      VALUE: 'Lot',
    },
    SERIAL: {
      VALUE: 'Serial',
    },
  },
};
