import React from 'react';
import {
  DateTimeInput, DateTimeInputProps,
} from 'react-admin';

interface DateTimeRangeInputProps extends DateTimeInputProps {
  since: string,
  until: string
}

function DateTimeRangeInput({ since, until }: DateTimeRangeInputProps) {
  return (
    <>
      <DateTimeInput source={since} variant="outlined" sx={{ marginRight: '2%' }} />
      <DateTimeInput source={until} variant="outlined" />
    </>
  );
}

export default DateTimeRangeInput;
