import list from './groupListContainer';
import edit from './editGroupContainer';
import create from './createGroupContainer';
import show from './showGroupContainer';

export default {
  name: 'auth/groups',
  list,
  edit,
  create,
  show,
  recordRepresentation: 'name',
  options: {
    label: 'Business Partners',
  },
};
