import React from 'react';
import { Create } from 'react-admin';
import InventoryControlForm from './form/inventoryControlForm';

export default function InventoryControlCreate() {
  return (
    <Create redirect="show">
      <InventoryControlForm />
    </Create>
  );
}
