import * as React from 'react';
import {
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useListContext,
  Button,
  FilterContext,
} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';
import useResourcePermissions from 'components/layout/actions/resourcePermissions';

export interface ListActionProps extends React.PropsWithChildren {
  exporter?: (() => void) | boolean,
  excelExporter?: () => void,
}

export default function ListActions(props: ListActionProps) {
  const permissions = useResourcePermissions();
  const filters = React.useContext(FilterContext);
  const {
    exporter, excelExporter, children,
  } = props;
  const { total, isLoading } = useListContext();

  let exportFunction;
  if (!!exporter && exporter !== true) {
    exportFunction = exporter;
  }

  return (
    <TopToolbar>
      {filters && <FilterButton />}
      {permissions.create && <CreateButton />}
      {!!exporter && <ExportButton disabled={isLoading || total === 0} exporter={exportFunction} />}
      {!!excelExporter
        && (
          <Button label="ra.action.export" disabled={isLoading || total === 0} onClick={excelExporter}>
            <DownloadIcon />
          </Button>
        )}
      {children}
    </TopToolbar>
  );
}
