import * as React from 'react';
import {
  Create,
  SimpleForm,
  PasswordInput,
  TopToolbar,
  ListButton,
  required,
  regex,
  useI18nProvider,
} from 'react-admin';
import { Box, Grid, Container } from '@mui/material';
import CreateToolBar from 'components/commons/createToolBar';
import useHasAccess from 'hooks/useHasAccess';
import { useGroupsQuery } from 'repositories/auth/users';
import UserForm from './userForm';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const i18n = useI18nProvider();
  const canPost = hasAccess({ url: 'auth/users/', httpMethod: 'POST' });
  const canSearch = hasAccess({ url: 'auth/users/', httpMethod: 'GET' });
  const { data } = useGroupsQuery();

  if (!data) return null;

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.password !== value) {
      return 'mai.auth.msg.confirm_password_invalidate';
    }
    return undefined;
  };
  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canPost} />}>
        <Box display="flex" flexDirection="column" gap="16px">
          <UserForm />
          <Container>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid item xs={4} md={6}>
                <PasswordInput
                  source="password"
                  validate={[
                    required(),
                    regex(
                      // eslint-disable-next-line max-len, no-useless-escape
                      /^(?=.*[0-9])(?=.*[!@#$%^&*()_=+{|}[\]:";'<>?,.~`\\\-\/])[a-zA-Z0-9!@#$%^&*()_=+{|}[\]:";'<>?,.~`\\\-\/]{8,50}$/,
                      i18n.translate('mai.auth.msg.password_format_invalidate'),
                    ),
                  ]}
                  fullWidth
                  variant="outlined"
                  helperText={false}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xs={4} md={6}>
                <PasswordInput
                  source="confirmPassword"
                  validate={[
                    required(),
                    confirmPasswordValidation,
                  ]}
                  fullWidth
                  variant="outlined"
                  helperText={false}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>

      </SimpleForm>
    </Create>
  );
});
