import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import {
  ProductBillOfResourceLineSchema, ProductBillOfResourcesDTO,
} from 'api';
import ProductChooser from 'components/chooser/productChooser';
import ShowQuantity from 'components/commons/showQuantity';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import React from 'react';
import {
  ArrayInput, FormDataConsumer, SimpleFormIterator, TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useResources } from 'repositories/products/product';
import { Source } from 'utils/source';
import styles from './billOfResourceForm.module.scss';
import PasteBillOfResources from './pasteBillOfResources';

interface BillOfResourcesFormProps {
  source: string
}

interface BillOfResourcesLineFormProps {
  getSource: (arg0: keyof (ProductBillOfResourceLineSchema)) => string,
  scopedFormData: ProductBillOfResourceLineSchema,
}

function BillOfResourcesLineForm(props: BillOfResourcesLineFormProps) {
  const {
    getSource,
    scopedFormData,
  } = props;

  const { data: resources } = useResources();
  const [edit, setEdit] = React.useState(false);
  const resource = React.useMemo(
    () => resources?.list.find((x) => x.id === scopedFormData.resourceId),
    [resources],
  );
  let content;

  if (!edit) {
    content = (
      <div className="show">
        <p>{resource?.name}</p>
        <ShowQuantity
          quantityTypeId={resource?.quantityTypeId}
          value={scopedFormData.amount}
        />
        <p>{scopedFormData.comment}</p>
      </div>
    );
  } else {
    content = (
      <div className="edit">
        <ProductChooser
          source={getSource('resourceId')}
          required
          className="resourcesSelect"
          label="resources.billOfResources.fields.resources"
          sx={{ width: '40%' }}
        />
        <ResourceQuantityInput
          className="amountInput"
          label="resources.billOfResources.fields.amount"
          source={getSource('amount')!}
          resourceId={scopedFormData?.resourceId}
        />
        <TextInput
          label="resources.billOfResources.fields.comment"
          source={getSource('comment')}
          fullWidth
          inputProps={{ maxLength: 250 }}
          variant="outlined"
          helperText={false}
          className="commentInput"
        />
      </div>
    );
  }

  return (
    <div className={styles.bom_form}>
      {content}
      <Button variant="outlined" onClick={() => setEdit(!edit)}>
        {
          edit ? <CheckIcon /> : <EditIcon />
        }
      </Button>
    </div>
  );
}

interface BillOfResourcePasterProps {
  onChange: () => void;
  source: string;
}

export function usePasteData() {
  const { data: resources } = useResources();

  return React.useCallback((data: [number, number][]) => {
    const resourceIds = new Map(resources?.list.map((resource) => [resource.articleNo, resource.id]));

    // @ts-ignore
    const newLines = data.map(([articleNo, amount]) => ({
      resourceId: articleNo ? resourceIds.get(articleNo.toString()) : undefined,
      amount,
    }));

    return newLines.filter((resource) => resource.resourceId !== undefined);
  }, [resources]);
}

function BillOfResourcePaster(props: BillOfResourcePasterProps) {
  const {
    source,
    onChange,
  } = props;
  const form = useFormContext();
  const getPasteData = usePasteData();
  const lines: ProductBillOfResourceLineSchema[] | undefined = form.watch(source);
  const hasLines = React.useMemo(() => lines?.length, [lines]);

  const onPaste = async (data: [number, number][]) => {
    const newLines = getPasteData(data)
      .filter((line) => line.amount > 0)
      .map((line) => ({ ...line, comment: '' }));

    form.setValue(source, newLines, { shouldDirty: true });
    onChange();
  };

  if (hasLines) {
    return null;
  }

  return <PasteBillOfResources onSubmit={onPaste} />;
}

export default function BillOfResourcesForm(props: BillOfResourcesFormProps) {
  const {
    source,
  } = props;
  const s = Source<ProductBillOfResourcesDTO>(source);
  const [key, setKey] = React.useState(0);
  return (
    <>
      <BillOfResourcePaster {...s('lines')} onChange={() => setKey(key + 1)} />
      <ArrayInput
        {...s('lines')}
        key={key}
        sx={{
          marginTop: '0',
        }}
      >
        <SimpleFormIterator inline fullWidth className="billOfResourceLinesForm">
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <BillOfResourcesLineForm
                getSource={getSource}
                // @ts-ignore
                scopedFormData={scopedFormData}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
