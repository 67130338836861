import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import { StockMoveEntity, StockMoveLineEntity } from 'api';
import urls from 'app/urls';
import List from 'components/list/List';
import * as React from 'react';
import {
  BulkDeleteButton,
  Datagrid,
  FunctionField,
  ListButton,
  ShowButton,
  SimpleForm,
  TextField,
  TopToolbar,
  useRefresh,
} from 'react-admin';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router';
import api from 'repositories/api';
import { useEnumQuery } from 'repositories/app';
import { useApiCall } from 'repositories/base';
import { useStockMoveDetail } from 'repositories/stock/stockMoves';
import exportStockLineQrCode, { StockLineQrCodeProps } from 'resources/stock/stockLine/qrCode';
import formatString from 'utils/formatString';
import useNotify from 'utils/notify';
import { Source } from 'utils/source';
import StockMoveLinePopup from './popup/popupAddMoveLine';
import FormState from './state';
import styles from './stockMoveLine.module.scss';

interface ShowStockMoveProps {
  id: string;
  canDelete: boolean;
  isApplied: boolean;
  hideSourceStockLine?: boolean;
  hideShelf?: boolean;
  stockMove?: StockMoveEntity;
}

export function ShowStockMoveLines(props: ShowStockMoveProps) {
  const {
    id,
    stockMove,
    canDelete,
    isApplied,
    hideSourceStockLine,
    hideShelf,
  } = props;
  const url = formatString(urls.StockMoves_GetListStockMoveLine.url, { id });
  const bulkActionButtons = canDelete ? <BulkDeleteButton label="Delete" /> : false;
  const sline = Source<StockMoveLineEntity>();
  const { data: enums } = useEnumQuery();

  return (
    <List
      resource={url}
      title=" "
      actions={(
        <TopToolbar>
          <ListButton resource="stock/stock-moves" />
          <ShowButton resource="stock/stock-moves" record={stockMove} />
        </TopToolbar>
      )}
    >
      <Datagrid bulkActionButtons={bulkActionButtons}>
        <TextField {...sline('articleNo')} />
        <TextField {...sline('resourceName')} />
        <TextField {...sline('supplierLotName')} />
        <TextField {...sline('stockLotName')} />
        <TextField {...sline('amount')} />
        {!hideShelf && <TextField {...sline('stockShelfName')} />}
        <TextField {...sline('createdBy')} />
        <FunctionField
          label="Print"
          render={(record: StockMoveLineEntity) => {
            if (record.destinationStockLine) {
              return (
                <div className={styles.stockMoveLineButtons}>
                  {!hideSourceStockLine && (
                    <>
                      <Button
                        startIcon={<PrintIcon />}
                        onClick={() => exportStockLineQrCode(
                          [{
                            ...record,
                            ...record.stockLine,
                            amount: record.stockLine.amount - (isApplied ? 0 : record.amount),
                          }],
                          enums?.quantityTypes,
                        )}
                      />
                      <ArrowForwardIcon />
                    </>
                  )}
                  <Button
                    startIcon={<PrintIcon />}
                    onClick={() => exportStockLineQrCode(
                      [{
                        ...record,
                        ...record.destinationStockLine!,
                        amount: record.destinationStockLine!.amount + (isApplied ? 0 : record.amount),
                      }],
                      enums?.quantityTypes,
                    )}

                  />
                </div>
              );
            }
            return (
              <Button
                startIcon={<PrintIcon />}
                onClick={() => {
                  let lines: StockLineQrCodeProps[];
                  if (record.destinationStockLine) {
                    lines = [
                      {
                        ...record,
                        ...record.stockLine,
                      },
                      {
                        ...record,
                        ...record.destinationStockLine,
                      },
                    ];
                    if (!isApplied) {
                      lines[0].amount = record.stockLine.amount - record.amount;
                      lines[1].amount = record.amount;
                    }
                  } else {
                    lines = [
                      {
                        ...record,
                        ...record.stockLine,
                      },
                    ];
                  }
                  exportStockLineQrCode(lines, enums?.quantityTypes);
                }}
              />
            );
          }}

        />
      </Datagrid>
    </List>
  );
}

export default function () {
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const idStockMove = Number(id);
  const { data } = useStockMoveDetail({ id: idStockMove });
  const addLine = useApiCall(api.stockMovesAddLine);
  const { data: enums } = useEnumQuery();

  const handleSave: SubmitHandler<FormState> = async (dataSubmit: FormState) => {
    const postData = dataSubmit;
    if (postData.amount === undefined) {
      notify(`Invalid amount: ${postData}, ${dataSubmit}`, { type: 'error' });
      throw new Error('invalid amount');
    }
    const response = await addLine({
      id: idStockMove,
      stockCreateLineDTO: dataSubmit,
    });
    refresh();
    notify('Record created', { type: 'success' });

    if (response.destinationStockLine && response.amount !== undefined) {
      const { articleNo } = response.destinationStockLine;
      if (response.amount !== undefined && articleNo !== undefined) {
        exportStockLineQrCode(
          [
            {
              ...response.destinationStockLine,
              amount: response.amount,
              articleNo,
            },
            {
              ...response.stockLine,
              amount: response.stockLine.amount - response.amount,
              articleNo,
            },
          ],
          enums?.quantityTypes,
        );
      } else {
        notify('error undefined');
      }
    }
  };

  return (
    <div>
      {id != null && (
        <ShowStockMoveLines
          id={id.toString()}
          stockMove={data}
          isApplied={!!data?.appliedDate}
          canDelete={!!data?.appliedDate}
        />
      )}
      <SimpleForm
        mode="onSubmit"
        toolbar={false}
        // @ts-ignore
        onSubmit={handleSave}
      >
        {data && <StockMoveLinePopup onSubmit={handleSave} data={data} />}
      </SimpleForm>
    </div>
  );
}
