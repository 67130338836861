import { PurchasingPurchaseOrderLineShipment } from 'api';
import urls from 'app/urls';
import QuantityTypeField from 'components/fields/QuantityTypeField';
import Datagrid from 'components/list/dataGrid';
import React from 'react';
import {
  DateField, FunctionField,
  Link, List, TextField, useCreatePath, useRecordContext,
} from 'react-admin';
import { Source } from 'utils/source';

export default function OpenOrderLines() {
  const s = Source<PurchasingPurchaseOrderLineShipment>();
  const { id: rawMaterialId } = useRecordContext();
  const resource = urls.PurchaseOrder_Search.url;
  const getPath = useCreatePath();

  // const { data } = useOrderLines({ id: id as number });

  return (
    // @ts-ignore
    <List resource={urls.PurchaseOrder_GetOpenOrderLines.url} filterDefaultValues={{ rawMaterialId }} title=" ">
      <Datagrid
        rowClick={false}
      >
        <FunctionField
          label="purchaseOrderName"
          render={(elem: PurchasingPurchaseOrderLineShipment) => (
            <Link to={getPath({ id: elem.purchaseOrderId, resource, type: 'show' })}>{elem.purchaseOrderName}</Link>
          )}
        />
        <QuantityTypeField {...s('amount')} idsource={s('quantityTypeId').source} />
        <QuantityTypeField {...s('openAmount')} idsource={s('quantityTypeId').source} />
        <DateField {...s('confirmedDeliveryDate')} />
        <TextField {...s('comment')} />
      </Datagrid>
    </List>
  );
}
