/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BusinessPartnerAddressSchema } from './BusinessPartnerAddressSchema';
import {
    BusinessPartnerAddressSchemaFromJSON,
    BusinessPartnerAddressSchemaFromJSONTyped,
    BusinessPartnerAddressSchemaToJSON,
} from './BusinessPartnerAddressSchema';

/**
 * 
 * @export
 * @interface BusinessPartnerCreate
 */
export interface BusinessPartnerCreate {
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerCreate
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    defaultDeliveryAddress: BusinessPartnerCreateDefaultDeliveryAddressEnum;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerCreate
     */
    taxId?: string | null;
    /**
     * 
     * @type {BusinessPartnerAddressSchema}
     * @memberof BusinessPartnerCreate
     */
    address: BusinessPartnerAddressSchema;
}


/**
 * @export
 */
export const BusinessPartnerCreateDefaultDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type BusinessPartnerCreateDefaultDeliveryAddressEnum = typeof BusinessPartnerCreateDefaultDeliveryAddressEnum[keyof typeof BusinessPartnerCreateDefaultDeliveryAddressEnum];


/**
 * Check if a given object implements the BusinessPartnerCreate interface.
 */
export function instanceOfBusinessPartnerCreate(value: object): value is BusinessPartnerCreate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('defaultDeliveryAddress' in value) || value['defaultDeliveryAddress'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    return true;
}

export function BusinessPartnerCreateFromJSON(json: any): BusinessPartnerCreate {
    return BusinessPartnerCreateFromJSONTyped(json, false);
}

export function BusinessPartnerCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPartnerCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'defaultDeliveryAddress': json['defaultDeliveryAddress'],
        'name': json['name'],
        'phone': json['phone'],
        'taxId': json['taxId'] == null ? undefined : json['taxId'],
        'address': BusinessPartnerAddressSchemaFromJSON(json['address']),
    };
}

export function BusinessPartnerCreateToJSON(value?: BusinessPartnerCreate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'defaultDeliveryAddress': value['defaultDeliveryAddress'],
        'name': value['name'],
        'phone': value['phone'],
        'taxId': value['taxId'],
        'address': BusinessPartnerAddressSchemaToJSON(value['address']),
    };
}

