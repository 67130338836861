import EditActions from 'components/layout/actions/edit';
import * as React from 'react';
import {
  Edit,
} from 'react-admin';
import RawMaterialForm from './rawMaterialForm';

export default function () {
  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
    >
      <RawMaterialForm edit />
    </Edit>
  );
}
