/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBillOfResourcesDTO } from './ProductBillOfResourcesDTO';
import {
    ProductBillOfResourcesDTOFromJSON,
    ProductBillOfResourcesDTOFromJSONTyped,
    ProductBillOfResourcesDTOToJSON,
} from './ProductBillOfResourcesDTO';

/**
 * 
 * @export
 * @interface StockMoveUpdateEntity
 */
export interface StockMoveUpdateEntity {
    /**
     * 
     * @type {Date}
     * @memberof StockMoveUpdateEntity
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveUpdateEntity
     */
    comment?: string | null;
    /**
     * 
     * @type {ProductBillOfResourcesDTO}
     * @memberof StockMoveUpdateEntity
     */
    billOfResources?: ProductBillOfResourcesDTO;
    /**
     * 
     * @type {number}
     * @memberof StockMoveUpdateEntity
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof StockMoveUpdateEntity
     */
    sourceStockLocationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveUpdateEntity
     */
    destinationStockLocationId?: number | null;
}

/**
 * Check if a given object implements the StockMoveUpdateEntity interface.
 */
export function instanceOfStockMoveUpdateEntity(value: object): value is StockMoveUpdateEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function StockMoveUpdateEntityFromJSON(json: any): StockMoveUpdateEntity {
    return StockMoveUpdateEntityFromJSONTyped(json, false);
}

export function StockMoveUpdateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveUpdateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'billOfResources': json['billOfResources'] == null ? undefined : ProductBillOfResourcesDTOFromJSON(json['billOfResources']),
        'id': json['id'],
        'sourceStockLocationId': json['sourceStockLocationId'] == null ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': json['destinationStockLocationId'] == null ? undefined : json['destinationStockLocationId'],
    };
}

export function StockMoveUpdateEntityToJSON(value?: StockMoveUpdateEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'comment': value['comment'],
        'billOfResources': ProductBillOfResourcesDTOToJSON(value['billOfResources']),
        'id': value['id'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
    };
}

