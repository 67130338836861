/* AUTOGENERATED, DO NOT EDIT! */
/* eslint-disable */
const translations = {
  "action": {
    "add": "Thêm",
    "add_filter": "Thêm bộ lọc",
    "apply": "Áp dụng",
    "back": "Trở về",
    "bulk_actions": "%{smart_count} đã chọn",
    "cancel": "Hủy bỏ",
    "clear_array_input": "Xóa danh sách",
    "clear_input_value": "Xóa giá trị",
    "clone": "Nhân bản",
    "close": "Đóng",
    "close_menu": "Đóng menu",
    "confirm": "Xác nhận",
    "create": "Tạo mới",
    "create_item": "Tạo %{item}",
    "delete": "Xóa",
    "edit": "Sửa",
    "edit_line": "Sửa dòng",
    "expand": "Mở rộng",
    "export": "Xuất",
    "export_pdf": "Xuất PDF",
    "finish": "Kết thúc",
    "goToStockMove": "Nhập chuyển kho",
    "home": "Quay lại trang chủ",
    "import": "Nhập",
    "list": "Danh sách",
    "move_down": "Đưa xuống",
    "move_up": "Đưa lên",
    "open": "Mở",
    "open_menu": "Mở menu",
    "refresh": "Làm mới",
    "remove": "Xóa bỏ",
    "remove_all_filters": "Xóa tất cả bộ lọc",
    "remove_filter": "Bỏ bộ lọc",
    "renderLotNo": "Xuất số lô",
    "save": "Lưu",
    "search": "Tìm kiếm",
    "select_all": "Chọn tất cả",
    "select_columns": "Cột",
    "select_row": "Chọn dòng này",
    "show": "Hiển thị",
    "sort": "Sắp xếp",
    "toggle_theme": "Đổi giao diện",
    "undo": "Hoàn tác",
    "unselect": "Huỷ chọn",
    "update": "Cập nhật",
    "update_application": "Tải lại ứng dụng"
  },
  "auth": {
    "auth_check_error": "Vui lòng đăng nhập để tiếp tục",
    "logout": "Đăng xuất",
    "password": "Mật khẩu",
    "sign_in": "Đăng nhập",
    "sign_in_error": "Xác thực không thành công, vui lòng thử lại",
    "user_menu": "Hồ sơ",
    "username": "Tên đăng nhập"
  },
  "boolean": {
    "false": "Không",
    "null": "Trống",
    "true": "Có"
  },
  "configurable": {
    "Datagrid": {
      "title": "Lưới dữ liệu",
      "unlabeled": "Cột không có nhãn #%{column}"
    },
    "SimpleForm": {
      "title": "Biểu mẫu",
      "unlabeled": "Đầu vào không có nhãn #%{input}"
    },
    "SimpleList": {
      "primaryText": "Văn bản chính",
      "secondaryText": "Văn bản phụ",
      "tertiaryText": "Văn bản bậc ba",
      "title": "Danh sách"
    },
    "configureMode": "Cấu hình trang này",
    "customize": "Tùy chỉnh",
    "inspector": {
      "content": "Di chuột qua các thành phần giao diện ứng dụng để cấu hình chúng",
      "hideAll": "Ẩn tất cả",
      "reset": "Đặt lại cài đặt",
      "showAll": "Hiển thị tất cả",
      "title": "Kiểm tra"
    }
  },
  "input": {
    "file": {
      "upload_several": "Nhấp để chọn một tệp tin hoặc kéo thả nhiều tệp tin để tải lên.",
      "upload_single": "Nhấp để chọn một tệp tin hoặc kéo thả để tải lên."
    },
    "image": {
      "upload_several": "Nhấp để chọn một bức ảnh hoặc kéo thả nhiều bức ảnh để tải lên.",
      "upload_single": "Nhấp để chọn một bức ảnh hoặc kéo thả để tải lên."
    },
    "password": {
      "toggle_hidden": "Hiện mật khẩu",
      "toggle_visible": "Ẩn mật khẩu"
    },
    "references": {
      "all_missing": "Không thể tìm thấy dữ liệu tham khảo.",
      "many_missing": "Ít nhất một tài liệu tham khảo liên quan không còn tồn tại.",
      "single_missing": "Tài liệu tham khảo liên quan không còn tồn tại."
    }
  },
  "message": {
    "about": "Về",
    "are_you_sure": "Bạn có chắc chắn muốn thực hiện hành động này không?",
    "auth_error": "Đã xảy ra lỗi khi kiểm tra mã xác thực",
    "bulk_delete_content": "Bạn có chắc chắn muốn xóa %{name} này? |||| Bạn có chắc chắn muốn xóa %{smart_count} %{name} này?",
    "bulk_delete_title": "Xóa %{name} |||| Xóa %{smart_count} %{name}",
    "bulk_update_content": "Bạn có muốn cập nhật %{name} này? |||| Bạn có chắc chắn muốn cập nhật %{smart_count} %{name} này?",
    "bulk_update_title": "Cập nhật %{name} |||| Cập nhật %{smart_count} %{name}",
    "clear_array_input": "Bạn có chắc chắn muốn xóa toàn bộ danh sách không?",
    "delete_content": "Bạn có chắc chắn muốn xóa %{name} này?",
    "delete_title": "Xóa %{name} #%{id}",
    "details": "Chi tiết",
    "error": "Đã xảy ra lỗi ở phía người dùng và yêu cầu của bạn không thể hoàn thành.",
    "invalid_form": "Dữ liệu nhập không đúng. Vui lòng kiểm tra lại",
    "loading": "Trang web đang tải, vui lòng đợi trong giây lát",
    "no": "Không",
    "no_access": "Bạn không có quyền truy cập trang này",
    "not_found": "Bạn đã nhập một URL sai hoặc bạn đã làm theo một liên kết không đúng.",
    "unsaved_changes": "Một vài thay đổi của bạn chưa được lưu. Bạn có chắc là muốn bỏ qua chúng?",
    "yes": "Có"
  },
  "navigation": {
    "current_page": "Trang %{page}",
    "first": "Đầu tiên",
    "last": "Cuối cùng",
    "next": "Tiếp",
    "no_more_results": "Trang số %{page} đã vượt giới hạn. Vui lòng trở lại trang trước.",
    "no_results": "Không tìm thấy kết quả nào",
    "page": "Đi đến trang %{page}",
    "page_out_from_begin": "Không thể trở lại trước trang 1",
    "page_out_from_end": "Không thể đi tiếp sau trang cuối",
    "page_out_of_boundaries": "Trang số %{page} đã vượt giới hạn",
    "page_range_info": "%{offsetBegin}-%{offsetEnd} của %{total}",
    "page_rows_per_page": "Số hàng trên mỗi trang:",
    "partial_page_range_info": "%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}",
    "previous": "Trước",
    "skip_nav": "Bỏ qua"
  },
  "notification": {
    "application_update_available": "Một phiên bản mới có sẵn.",
    "bad_item": "Thành phần không đúng",
    "canceled": "Hủy bỏ hành động",
    "created": "Đã tạo thành công",
    "data_provider_error": "Lỗi dataProvider. Kiểm tra console để biết chi tiết.",
    "deleted": "Đã xóa thành công |||| Xóa thành công %{smart_count} thành phần",
    "http_error": "Lỗi giao tiếp với máy chủ",
    "i18n_error": "Không thể tải bản dịch cho ngôn ngữ này",
    "item_doesnt_exist": "Thành phần không tồn tại",
    "logged_out": "Phiên làm việc của bạn đã kết thúc, vui lòng kết nối lại.",
    "not_authorized": "Bạn không có quyền truy cập thông tin này",
    "updated": "Cập nhật thành công |||| Cập nhật thành công %{smart_count} thành phần"
  },
  "page": {
    "create": "Tạo %{name}",
    "dashboard": "Bảng điều khiển",
    "edit": "Sửa %{name} #%{id}",
    "empty": "Chưa có %{name} nào.",
    "error": "Đã xảy ra lỗi",
    "invite": "Bạn có muốn tạo mới không?",
    "list": "Danh sách %{name}",
    "loading": "Đang tải",
    "no_access": "Từ chối truy cập",
    "not_found": "Không tìm thấy",
    "show": "Hiển thị %{name} #%{id}"
  },
  "saved_queries": {
    "help": "Lọc danh sách và lưu truy vấn này cho sau",
    "label": "Truy vấn đã lưu",
    "new_dialog_title": "Lưu truy vấn hiện tại dưới dạng",
    "new_label": "Lưu truy vấn hiện tại...",
    "query_name": "Tên truy vấn",
    "remove_dialog_title": "Xóa truy vấn đã lưu?",
    "remove_label": "Xóa truy vấn đã lưu",
    "remove_label_with_name": "Xóa truy vấn \"%{name}\"",
    "remove_message": "Bạn có chắc chắn muốn xóa mục đó khỏi danh sách truy vấn đã lưu không?"
  },
  "sort": {
    "ASC": "tăng dần",
    "DESC": "giảm dần",
    "sort_by": "Sắp xếp theo %{field} %{order}"
  },
  "validation": {
    "email": "Phải là địa chỉ email hợp lệ",
    "maxLength": "Phải nhỏ hơn hoặc bằng %{max} ký tự",
    "maxValue": "Phải nhỏ hơn hoặc bằng %{max}",
    "minLength": "Phải ít nhất %{min} ký tự",
    "minValue": "Phải lớn hơn hoặc bằng %{min}",
    "number": "Phải là số",
    "oneOf": "Phải chọn một trong số lựa chọn sau: %{options}",
    "positive": "Phải lớn hơn 0",
    "regex": "Phải phù hợp với định dạng (regexp): %{pattern}",
    "required": "Bắt buộc",
    "unique": "Phải là duy nhất"
  }
};
export default translations;
