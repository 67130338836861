import { StockMovesSearchMoveTypeEnum, StockSplitEntity } from 'api';
import NumberFormatField from 'components/commons/numberFormatField';
import ListActions from 'components/layout/actions/list';
import List from 'components/list/List';
import React from 'react';
import {
  Datagrid, DateField, ReferenceField, TextField,
} from 'react-admin';
import { Source } from 'utils/source';

export default function StockSplitList() {
  const s = Source<StockSplitEntity>();
  return (
    <List
      actions={<ListActions />}
      filterDefaultValues={{ moveType: StockMovesSearchMoveTypeEnum.StockSplit }}
      sort={{ field: 'appliedDate', order: 'DESC' }}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
      >

        <TextField {...s('articleNo')} sortable={false} />
        <TextField {...s('articleName')} sortable={false} />
        <TextField {...s('batchNo')} sortable={false} />

        <TextField {...s('packageCount')} />
        <ReferenceField {...s('sourceStockLocationId')} reference="stock/stocklocations">
          <TextField source="name" />
        </ReferenceField>
        <DateField {...s('appliedDate')} showTime />
        <TextField {...s('applier')} />
        <TextField {...s('comment')} />
        <NumberFormatField {...s('totalAmount')} />
      </Datagrid>
    </List>
  );
}
