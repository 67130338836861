/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import {
  AppBar, TitlePortal,
} from 'react-admin';
import UserMenu from './userMenu';

export default function () {
  return (
    <AppBar
      userMenu={<UserMenu />}
    >
      <TitlePortal />
    </AppBar>
  );
}
