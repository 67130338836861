/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
    /**
     * 
     * @type {number}
     * @memberof FileDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    mimeType: string;
    /**
     * 
     * @type {string}
     * @memberof FileDTO
     */
    fileName: string;
}

/**
 * Check if a given object implements the FileDTO interface.
 */
export function instanceOfFileDTO(value: object): value is FileDTO {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('fileName' in value) || value['fileName'] === undefined) return false;
    return true;
}

export function FileDTOFromJSON(json: any): FileDTO {
    return FileDTOFromJSONTyped(json, false);
}

export function FileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mimeType': json['mimeType'],
        'fileName': json['fileName'],
    };
}

export function FileDTOToJSON(value?: FileDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'mimeType': value['mimeType'],
        'fileName': value['fileName'],
    };
}

