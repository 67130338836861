import React from 'react';
import { Grid } from '@mui/material';
import {
  PasswordInput, regex, required, SaveButton, SimpleForm, Title,
  Toolbar, useNotify,
} from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { ProfileChangePasswordRequest } from 'api';
import api from 'repositories/api';
import { useProfile } from 'repositories/auth/profile';
import Loading from 'components/shared/loading';
import useTranslate from 'utils/translate';

export default function () {
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const mutation = useMutation(
    ['ChangePassword'],
    (data: ProfileChangePasswordRequest) => api.profileChangePassword(data),
  );
  const { isLoading, data: profile } = useProfile();

  // return early while fetching
  if (!profile || isLoading) {
    return <Loading />;
  }

  const savePassword = (data: any) => {
    mutation.mutateAsync({ id: `${profile?.id}`, authUpdatePasswordSchema: data })
      .then(() => {
        notify('mai.msg.change_password_success', { type: 'info' });
        navigate('/');
      })
      .catch((error) => {
        notify(error.message, { type: 'error' });
      });
  };

  const confirmPasswordValidation = (value: string, allValues: any) => {
    if (allValues.newPassword !== value) {
      return 'mai.msg.cornfirm_password_invalidate';
    }
    return undefined;
  };
  return (
    <div className="mai-form">
      <SimpleForm
        toolbar={<Toolbar><SaveButton /></Toolbar>}
        onSubmit={savePassword}
      >
        <Title title={translate('mai.changePassword')} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="oldPassword"
              label={translate('mai.oldPassword')}
              validate={required()}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="newPassword"
              label={translate('mai.newPassword')}
              validate={[
                required(),
                regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,50}$/, 'mai.msg.password_format_invalidate'),
              ]}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <PasswordInput
              source="confirmPassword"
              label={translate('mai.confirmPassword')}
              validate={[required(), confirmPasswordValidation]}
              fullWidth
              inputProps={{ maxLength: 50 }}
              variant="outlined"
              helperText={false}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </div>
  );
}
