/* eslint-disable max-len */
import api from 'repositories/api';
import { ReceiveShipmentDeleteLineRequest } from 'api';
import { getApiQuery } from 'repositories/base';

export const useReceiveShipment = getApiQuery(api.receiveShipmentGetStockMove);
export const useShipmentComparison = getApiQuery(api.incomingShipmentCompare);
export const useShipment = getApiQuery(api.incomingShipmentGet);

export default {
  deleteReceiveLine:
    (request: ReceiveShipmentDeleteLineRequest) => api.receiveShipmentDeleteLine(request),
  applyReceiveShipment: (id: number) => api.receiveShipmentApply({ id }),
  getStockReceiveShipmentDetail: (id: number) => api.receiveShipmentGetStockMove({
    id,
  }),
};
