import { Grid } from '@mui/material';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useRecordContext,
} from 'react-admin';
import { Source } from 'utils/source';
import { StockMoveUpdateEntity } from 'api';
import EditToolBar from 'components/commons/editToolBar';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import BillOfResourcesForm from 'resources/product/billOfResourceForm';

export default function StockMoveForm(props: any) {
  const { alwaysEnable = false } = props;
  const hasAccess = useHasAccess();
  const stockMove = useRecordContext();
  const canPut = hasAccess({ url: 'stock/stock-moves/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'stock/stock-moves/{id}', httpMethod: 'DELETE' });
  const s = Source<StockMoveUpdateEntity>();
  return (
    <SimpleForm
      toolbar={(
        <EditToolBar
          isSave={canPut}
          alwaysEnable={alwaysEnable}
          isDelete={canDelete && !stockMove?.appliedDate}
        />
      )}
    >
      <Grid container spacing={2} rowSpacing={0.1}>

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>

          <ReferenceInput label="SourceStockLocation" {...s('sourceStockLocationId')} reference="stock/stocklocations">
            <SelectInput optionText="name" fullWidth disabled={!!stockMove?.appliedDate} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={4} md={5}>
          <ReferenceInput
            label="DestinationStockLocation"
            {...s('destinationStockLocationId')}
            reference="stock/stocklocations"
          >
            <SelectInput optionText="name" fullWidth disabled={!!stockMove?.appliedDate} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2} md={1} />

        <Grid item xs={2} md={1} />
        <Grid item xs={4} md={5}>
          <CustomDatePicker
            {...s('plannedDate')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={4} md={5}>
          <TextInput
            {...s('comment')}
            fullWidth
            variant="outlined"
            helperText={false}
          />
        </Grid>
        <Grid item xs={2} md={1} />
        <Grid item md={12}>
          <BillOfResourcesForm {...s('billOfResources')} />

        </Grid>
      </Grid>
    </SimpleForm>
  );
}
