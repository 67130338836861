import FactCheckIcon from '@mui/icons-material/FactCheck';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material';
import { StockMoveDetail, StockMoveEntity } from 'api';
import urls from 'app/urls';
import UserChooser from 'components/chooser/userChooser';
import useResourcePermissions from 'components/layout/actions/resourcePermissions';
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Button,
  Confirm,
  DeleteButton,
  EditButton,
  HttpError,
  ListButton,
  TopToolbar,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'repositories/api';
import formatString from 'utils/formatString';
import useNotify from 'utils/notify';

interface ApplyForm {
  recipientId?: number;
}

function ApplyDirective() {
  // const stockMove = useRecordContext<StockMoveEntity>();
  const stockMove = useRecordContext<StockMoveDetail>();
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const form = useForm<ApplyForm>();

  if (!stockMove) {
    return null;
  }
  const recipientId = form.watch('recipientId');

  const handleConfirm = async () => {
    setOpen(false);

    try {
      await api.stockMovesApplyStockMove({
        id: stockMove.id,
        recipientId,
      });
      notify('mai.stockMove.apply_success', { type: 'success' });
      refresh();
    } catch (e) {
      if (e instanceof HttpError) {
        notify('mai.stockMove.apply_failed', { type: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        label="mai.stockMove.apply_btn"
        startIcon={<FactCheckIcon />}
        onClick={() => setOpen(true)}
        disabled={!stockMove.canApply}
      />
      <Dialog
        open={open}
      >
        <DialogTitle>{translate('mai.stockMove.apply_confirm_title')}</DialogTitle>
        <FormProvider {...form}>
          <form {...form}>
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
              <p>{translate('mai.stockMove.apply_confirm_content')}</p>
              <UserChooser source="recipientId" label="resources.stock/stock-moves.fields.recipient" />

            </DialogContent>
            <DialogActions>
              <Button label="Cancel" onClick={() => setOpen(false)} />
              <Button label="Apply" onClick={handleConfirm} />
            </DialogActions>

          </form>

        </FormProvider>
      </Dialog>
    </>
  );
}

export function UndoButton() {
  const stockMove = useRecordContext<StockMoveDetail>();
  const [open, setOpen] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  if (!stockMove) {
    return null;
  }

  const handleConfirm = async () => {
    setOpen(false);

    try {
      await api.stockMovesUndo({ id: stockMove.id });
      notify('mai.stockMove.undo_apply_success', { type: 'success' });
      refresh();
    } catch (e) {
      if (e instanceof HttpError) {
        notify('mai.stockMove.undo_apply_failed', { type: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        label="mai.stockMove.undo_apply_btn"
        startIcon={<UndoIcon />}
        onClick={() => setOpen(true)}
        disabled={!stockMove.canUndo}
      />
      <Confirm
        isOpen={open}
        title="mai.stockMove.undo_apply_confirm_title"
        content="mai.stockMove.undo_apply_confirm_content"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </>
  );
}

interface EditStockMoveLineButtonProps {
  disabled: boolean
}

function EditStockMoveLineButton({ disabled }: EditStockMoveLineButtonProps) {
  const { id } = useParams();
  const stockMove = useRecordContext<StockMoveDetail>();

  return (
    <Link
      to={stockMove?.billOfResources?.lines?.length
        ? `/${formatString(urls.StockMoves_GetListPlannedStockLine.url, { id: id?.toString() ?? '' })}`
        : `/${formatString(urls.StockMoves_AddLine.url, { id: id?.toString() ?? '' })}`}
    >
      <Button
        startIcon={<PlaylistAddIcon />}
        disabled={disabled}
        label="ra.action.edit_line"
      />
    </Link>
  );
}

export function ExportPdfButton() {
  const stockMove = useRecordContext();
  if (!stockMove) {
    return null;
  }

  const { id } = stockMove;
  const exportPdf = () => {
    api.stockMovesPdfExport({ id: Number(id) }).then((blob: Blob) => {
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.setAttribute('download', `stock_move-${id}`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
    });
  };
  return <Button onClick={exportPdf} label="ra.action.export_pdf"><PictureAsPdf /></Button>;
}

export default function StockMoveActions() {
  const permissions = useResourcePermissions();
  const hasAccess = useHasAccess();
  const stockMove: StockMoveEntity | undefined = useRecordContext<StockMoveEntity>();

  const canAddLines = hasAccess(urls.StockMoves_AddLine);
  const canApply = hasAccess(urls.StockMoves_ApplyStockMove);
  const canUndo = hasAccess(urls.StockMoves_Undo);
  const canExport = hasAccess(urls.StockMoves_PdfExport);

  return (
    <TopToolbar>
      {permissions.update && <EditButton />}
      {canAddLines && <EditStockMoveLineButton disabled={!!stockMove?.appliedDate} />}
      {canApply && !stockMove?.appliedDate && <ApplyDirective />}
      {canUndo && stockMove?.appliedDate && <UndoButton />}
      {permissions.list && <ListButton />}
      {permissions.delete && (
        <DeleteButton
          label="ra.action.delete"
          mutationMode="pessimistic"
          disabled={!!stockMove?.appliedDate}
        />
      )}
      {canExport && <ExportPdfButton />}
    </TopToolbar>
  );
}
