/* AUTOGENERATED, DO NOT EDIT! */
/* eslint-disable */
const translations = {
  "auth/groups": {
    "fields": {
      "description": "Description",
      "id": "Key",
      "name": "Name of group",
      "typeName": "Type"
    },
    "name": "Group |||| Groups"
  },
  "auth/users": {
    "fields": {
      "Group": "Group",
      "Phone": "Phone",
      "Query": "Search",
      "address": "Address",
      "birthday": "Date of birth",
      "confirmPassword": "Confirm password",
      "email": "Email",
      "firstname": "First name",
      "fullname": "Full name",
      "gender": "Gender",
      "groupIds": "Groups",
      "groups": "Groups",
      "isActive": "Active",
      "isSpecialAccess": "Has Worldwide Access",
      "lastLogin": "Last login",
      "lastLogout": "Last logout",
      "lastname": "Last name",
      "note": "Note",
      "password": "Password",
      "phone": "Phone",
      "status": "Status",
      "username": "Username"
    },
    "name": "User |||| Users"
  },
  "billOfResources": {
    "fields": {
      "amount": "Amount",
      "comment": "Comment",
      "dependencies": "Workloads dependence",
      "description": "Description",
      "detail": "workloads details",
      "id": "Id",
      "lines": "Lines",
      "name": "Name",
      "products": "Products",
      "resourceName": "Resources name",
      "resources": "Resources"
    },
    "name": "Bill of Resources |||| Bills of Resources"
  },
  "business-partner/business-partners": {
    "fields": {
      "address": "Address",
      "address1": "Address 1",
      "address2": "Address 2",
      "addressName": "Address name",
      "city": "City",
      "countryId": "Country",
      "countryName": "Country",
      "name": "Name",
      "phone": "Phone",
      "state": "State",
      "taxId": "TaxID",
      "zipcode": "Zip code"
    },
    "name": "Business partners"
  },
  "product/categories": {
    "fields": {
      "color": "Color",
      "description": "Description",
      "fullName": "Full Name",
      "name": "Name",
      "parentId": "Parent",
      "position": "Position",
      "query": "Search"
    },
    "name": "Categories"
  },
  "product/productionItems": {
    "fields": {
      "amount": "Amount",
      "batchNumber": "Batch Number",
      "comment": "Comment",
      "plannedEndDate": "Planned End Date",
      "plannedExportDate": "Planned Export Date",
      "serialNo": "Serial No"
    }
  },
  "product/products": {
    "fields": {
      "billofresources": "Bill of resources",
      "categoryId": "Category",
      "categoryName": "Category",
      "currencyId": "Currency",
      "currencyName": "Currency",
      "detail": "Product details",
      "isActive": "Active",
      "name": "Name",
      "price": "Price",
      "quantityTypeId": "Quantity Type",
      "quantityTypeName": "Quantity Type",
      "stateCode": "State",
      "stockTracingCode": "StockTracing",
      "stockTracingName": "StockTracing"
    },
    "name": "Product |||| Products",
    "tabs": {
      "billofresources": "Bill of Materials",
      "detail": "Detail",
      "openOrders": "Open Orders",
      "stockLine": "Stock Lines"
    }
  },
  "product/raw-materials": {
    "fields": {
      "articleNo": "Article Number",
      "businessPartnerId": "Business Partner",
      "businessPartnerName": "Business Partner",
      "categoryId": "Category",
      "categoryName": "Category",
      "currencyId": "Currency",
      "currencyName": "Currency",
      "decimals": "Decimals",
      "detail": "Raw material details",
      "isActive": "Active",
      "leadTime": "Lead Time (days)",
      "moq": "MOQ",
      "name": "Name",
      "price": "Price",
      "quantityTypeId": "Quantity Type",
      "quantityTypeName": "Quantity Type",
      "stateCode": "Active",
      "stockConditioning": "Stock Conditioning",
      "stockTracingCode": "Stock Tracing",
      "stockTracingName": "Stock Tracing",
      "supplierConditioning": "Supplier Conditioning",
      "supplierName": "Supplier",
      "unit": "Unit",
      "image": "Image",
      "hsCode": "Hs Code",
      "articleNumber": "Article Number",
      "weight": "Weight",
      "size": "Size",
      "color": "Color",
      "isReadyToSell": "Ready to sell",
      "productionNumberSuffix": "Production Number Prefix"
    },
    "name": "Raw Materials |||| Raw Materials"
  },
  "purchasing": {
    "purchaseOrder": {
      "Amount": "Amount",
      "DoneAmount": "Delivered Amount",
      "OpenAmount": "Remaining Amount",
      "ConfirmedDeliveryDate": "Confirmed Delivery Date"
    }
  },
  "stock/incomingShipment": {
    "fields": {
      "comment": "Comment",
      "estimatedTimeofArrival": "ETA (Estimated time of arrival)",
      "estimatedTimeofDeparture": "ETD (Estimated time of departure)",
      "receiveShipment": "Receive Shipment",
      "shipmentName": "Shipment Name",
      "state": "State",
      "supplier": "Supplier",
      "transportType": "Transport Type"
    },
    "name": "Incoming Shipment"
  },
  "stock/inventoryControl": {
    "fields": {
      "addInventoryLine": "Check Inventory",
      "comment": "Comment",
      "finishedDate": "Finished date",
      "plannedDate": "Planned date",
      "status": "Status",
      "stockLocationName": "Stock Location Name"
    },
    "name": "Inventory Control"
  },
  "stock/inventoryControlLine": {
    "fields": {
      "articleName": "Article Name",
      "articleNo": "Article No",
      "changeAmount": "Change Amount",
      "lotNo": "Lot No"
    }
  },
  "stock/stock-moves": {
    "fields": {
      "applied": "Applied",
      "appliedBy": "Applied By",
      "appliedDate": "Applied date",
      "appliedSince": "Applied since",
      "appliedUntil": "Applied until",
      "applier": "Applied By",
      "recipient": "Recipient",
      "comment": "Comment",
      "creator": "Created By",
      "planed": "Planned",
      "planedSince": "Planned since",
      "planedUntil": "Planned until",
      "plannedDate": "Planned date",
      "resourceId": "Resource ID",
      "source_location": "Source location",
      "stockMoveLines": "Stock move lines",
      "target_location": "Target location",
      "totalAmount": "Total amount"
    },
    "name": "StockMoves"
  },
  "stock/stockLocations/shelf": {
    "fields": {
      "comment": "Comment",
      "name": "Name",
      "picture": "Picture",
      "stockLocationName": "Stock location"
    },
    "name": "Shelf"
  },
  "stock/stocklines": {
    "fields": {
      "amount": "Amount",
      "categoryId": "Category",
      "delFlag": "Deleted",
      "id": "Id",
      "isDelete": "Deleted",
      "lotNo": "LotNo",
      "name": "StockLine",
      "resourceId": "Resource ID",
      "resourceName": "Resource Name"
    },
    "name": "StockLine |||| StockLines"
  }
};
export default translations;
