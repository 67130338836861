/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockInventoryControlList } from './StockInventoryControlList';
import {
    StockInventoryControlListFromJSON,
    StockInventoryControlListFromJSONTyped,
    StockInventoryControlListToJSON,
} from './StockInventoryControlList';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';

/**
 * 
 * @export
 * @interface PaginatedListInventoryControl
 */
export interface PaginatedListInventoryControl {
    /**
     * 
     * @type {Array<StockInventoryControlList>}
     * @memberof PaginatedListInventoryControl
     */
    list: Array<StockInventoryControlList>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListInventoryControl
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListInventoryControl interface.
 */
export function instanceOfPaginatedListInventoryControl(value: object): value is PaginatedListInventoryControl {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListInventoryControlFromJSON(json: any): PaginatedListInventoryControl {
    return PaginatedListInventoryControlFromJSONTyped(json, false);
}

export function PaginatedListInventoryControlFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListInventoryControl {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockInventoryControlListFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListInventoryControlToJSON(value?: PaginatedListInventoryControl | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockInventoryControlListToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

