import * as React from 'react';
import {
  ShowButton, ListButton, TopToolbar,
} from 'react-admin';
import { ToolbarProps } from '@mui/material';
import useResourcePermissions from './resourcePermissions';

interface EditActionsProps extends ToolbarProps {
  canList?: boolean,
  canShow?: boolean
}

export default function EditActions(props: EditActionsProps) {
  const {
    resource,
  } = props;
  const permissions = useResourcePermissions(resource);
  console.log(resource, permissions);

  return (
    <TopToolbar {...props}>
      {permissions.list && <ListButton resource={resource} />}
      {permissions.show && <ShowButton resource={resource} />}
    </TopToolbar>
  );
}
