import * as React from 'react';
import {
  ListButton, TopToolbar, DeleteButton, EditButton,
} from 'react-admin';
import { ToolbarProps } from '@mui/material';
import useResourcePermissions from './resourcePermissions';

export default function ShowActions(props: ToolbarProps) {
  const {
    children,
  } = props;
  const permissions = useResourcePermissions();

  return (
    <TopToolbar {...props}>
      {permissions.list && <ListButton />}
      {permissions.update && <EditButton />}
      {permissions.delete && <DeleteButton />}
      {children}
    </TopToolbar>
  );
}
