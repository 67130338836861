/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockLinesLot
 */
export interface StockLinesLot {
    /**
     * 
     * @type {string}
     * @memberof StockLinesLot
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StockLinesLot
     */
    supplierName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof StockLinesLot
     */
    date: Date;
}

/**
 * Check if a given object implements the StockLinesLot interface.
 */
export function instanceOfStockLinesLot(value: object): value is StockLinesLot {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function StockLinesLotFromJSON(json: any): StockLinesLot {
    return StockLinesLotFromJSONTyped(json, false);
}

export function StockLinesLotFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesLot {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'supplierName': json['supplierName'] == null ? undefined : json['supplierName'],
        'date': (new Date(json['date'])),
    };
}

export function StockLinesLotToJSON(value?: StockLinesLot | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'supplierName': value['supplierName'],
        'date': ((value['date']).toISOString()),
    };
}

