import { Typography } from '@mui/material';
import get from 'lodash/get';
import * as React from 'react';
import { useRecordContext } from 'react-admin';
import { useCurrency } from 'repositories/app';
import styles from './priceField.module.scss';

interface PriceFieldProps {
  source?: string;
  currencySource?: string;
  decimals?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  label?: string;
}

export default function PriceField(props: PriceFieldProps) {
  const {
    source,
    currencySource,
    decimals,
  } = props;

  const record = useRecordContext();

  let price = get(record, source || 'price');
  const currencyId = get(record, currencySource || 'currency');
  const currency = useCurrency(currencyId);

  if (decimals != null) {
    price = price.ToFixed(decimals);
  }

  let data = [
    <span>{price}</span>,
  ];

  if (currency) {
    data.push(<span>{currency.symbol}</span>);

    if (currency.direction < 0) {
      data = data.reverse();
    }
  }

  return (
    <Typography variant="body2" className={styles.show_price}>
      {data}
    </Typography>
  );
}
