import { ToolbarProps } from '@mui/material';
import * as React from 'react';
import { Toolbar, SaveButton } from 'react-admin';

interface CreateToolbarProps extends ToolbarProps {
  isSave: boolean;
}

export default React.memo((props: CreateToolbarProps) => {
  const { isSave, ...rest } = props;
  return (
    <Toolbar {...rest}>
      {isSave !== false && <SaveButton />}
    </Toolbar>
  );
});
