import React from 'react';
import { Edit } from 'react-admin';
import InventoryControlForm from './form/inventoryControlForm';

export default function IncomingShipmentCreate() {
  return (
    <Edit mutationMode="pessimistic" redirect="show">
      <InventoryControlForm />
    </Edit>
  );
}
