import ListActions from 'components/layout/actions/list';
import React from 'react';
import { List, ListProps, Pagination } from 'react-admin';

const defaultActions = <ListActions />;

export default function CustomList(props: ListProps) {
  const {
    children,
    actions = defaultActions,
    ...rest
  } = props;

  return (
    <List
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[20, 50, 100, 200, 400]} />}
      actions={actions}
      {...rest}
    >
      {children}
    </List>
  );
}
