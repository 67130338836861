import { Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';
import * as React from 'react';
import {
  RaRecord, useRecordContext, useTranslate,
} from 'react-admin';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

interface BooleanFieldProps {
  source: string;
  className?: string;
  emptyText?: string;
  trueText?: string;
  falseText?: string;
  showIcon?: boolean
}

const PREFIX = 'RaBooleanField';

const classes = {
  trueIcon: `${PREFIX}-trueIcon`,
  falseIcon: `${PREFIX}-falseIcon`,
};

export default function BooleanField<RecordType extends RaRecord>(props: BooleanFieldProps) {
  const {
    source,
    className,
    emptyText,
    trueText,
    falseText,
    showIcon,
  } = props;

  const record = useRecordContext<RecordType>(props);
  const translate = useTranslate();

  const value = get(record, source);
  const sx = {
    // verticalAlign: 'text-top',
  };
  const ariaLabel = value ? 'ra.boolean.true' : 'ra.boolean.false';
  const icon = showIcon === false ? null : (
    <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
      {
        value ? (
          <DoneIcon
            data-testid="true"
            fontSize="small"
            className={classes.trueIcon}
          />
        ) : (
          <ClearIcon
            data-testid="false"
            fontSize="small"
            className={classes.falseIcon}
          />
        )

      }
    </Tooltip>
  );
  const label = trueText || falseText ? <span>{value ? trueText : falseText}</span> : null;

  if (value === false || value === true) {
    return (
      <Typography
        component="span"
        variant="body2"
        sx={sx}
        className={className}
      >

        {label}
        {icon}
      </Typography>
    );
  }

  return (
    <Typography
      component="span"
      variant="body2"
      className={className}
    >
      {emptyText && translate(emptyText, { _: emptyText })}
    </Typography>
  );
}
