/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderList
 */
export interface PurchasingPurchaseOrderList {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    state: PurchasingPurchaseOrderListStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    confirmationState: PurchasingPurchaseOrderListConfirmationStateEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderList
     */
    doneRatio: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    deliveryAddress: PurchasingPurchaseOrderListDeliveryAddressEnum;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    orderSequence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    businessPartner: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderList
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    plannedDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    confirmationDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderList
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderList
     */
    createdBy?: string | null;
}


/**
 * @export
 */
export const PurchasingPurchaseOrderListStateEnum = {
    Waiting: 'Waiting',
    Partial: 'Partial',
    Done: 'Done'
} as const;
export type PurchasingPurchaseOrderListStateEnum = typeof PurchasingPurchaseOrderListStateEnum[keyof typeof PurchasingPurchaseOrderListStateEnum];

/**
 * @export
 */
export const PurchasingPurchaseOrderListConfirmationStateEnum = {
    Waiting: 'Waiting',
    Partial: 'Partial',
    Done: 'Done'
} as const;
export type PurchasingPurchaseOrderListConfirmationStateEnum = typeof PurchasingPurchaseOrderListConfirmationStateEnum[keyof typeof PurchasingPurchaseOrderListConfirmationStateEnum];

/**
 * @export
 */
export const PurchasingPurchaseOrderListDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type PurchasingPurchaseOrderListDeliveryAddressEnum = typeof PurchasingPurchaseOrderListDeliveryAddressEnum[keyof typeof PurchasingPurchaseOrderListDeliveryAddressEnum];


/**
 * Check if a given object implements the PurchasingPurchaseOrderList interface.
 */
export function instanceOfPurchasingPurchaseOrderList(value: object): value is PurchasingPurchaseOrderList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('confirmationState' in value) || value['confirmationState'] === undefined) return false;
    if (!('doneRatio' in value) || value['doneRatio'] === undefined) return false;
    if (!('deliveryAddress' in value) || value['deliveryAddress'] === undefined) return false;
    if (!('businessPartner' in value) || value['businessPartner'] === undefined) return false;
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function PurchasingPurchaseOrderListFromJSON(json: any): PurchasingPurchaseOrderList {
    return PurchasingPurchaseOrderListFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'confirmationState': json['confirmationState'],
        'doneRatio': json['doneRatio'],
        'deliveryAddress': json['deliveryAddress'],
        'orderSequence': json['orderSequence'] == null ? undefined : json['orderSequence'],
        'businessPartner': json['businessPartner'],
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'confirmationDate': json['confirmationDate'] == null ? undefined : (new Date(json['confirmationDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

export function PurchasingPurchaseOrderListToJSON(value?: PurchasingPurchaseOrderList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'state': value['state'],
        'confirmationState': value['confirmationState'],
        'doneRatio': value['doneRatio'],
        'deliveryAddress': value['deliveryAddress'],
        'orderSequence': value['orderSequence'],
        'businessPartner': value['businessPartner'],
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'confirmationDate': value['confirmationDate'] == null ? undefined : ((value['confirmationDate'] as any).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
        'comment': value['comment'],
        'createdBy': value['createdBy'],
    };
}

