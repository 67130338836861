/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDetailSchema } from './ProductDetailSchema';
import {
    ProductDetailSchemaFromJSON,
    ProductDetailSchemaFromJSONTyped,
    ProductDetailSchemaToJSON,
} from './ProductDetailSchema';

/**
 * 
 * @export
 * @interface ProductProductionItemDetailSchema
 */
export interface ProductProductionItemDetailSchema {
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    productId: number;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    serialNo: string;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    batchNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    amount: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionItemDetailSchema
     */
    plannedEndDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ProductProductionItemDetailSchema
     */
    plannedExportDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProductProductionItemDetailSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductProductionItemDetailSchema
     */
    id: number;
    /**
     * 
     * @type {ProductDetailSchema}
     * @memberof ProductProductionItemDetailSchema
     */
    product?: ProductDetailSchema;
}

/**
 * Check if a given object implements the ProductProductionItemDetailSchema interface.
 */
export function instanceOfProductProductionItemDetailSchema(value: object): value is ProductProductionItemDetailSchema {
    if (!('productId' in value) || value['productId'] === undefined) return false;
    if (!('serialNo' in value) || value['serialNo'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function ProductProductionItemDetailSchemaFromJSON(json: any): ProductProductionItemDetailSchema {
    return ProductProductionItemDetailSchemaFromJSONTyped(json, false);
}

export function ProductProductionItemDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductProductionItemDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'serialNo': json['serialNo'],
        'batchNumber': json['batchNumber'] == null ? undefined : json['batchNumber'],
        'amount': json['amount'],
        'plannedEndDate': json['plannedEndDate'] == null ? undefined : (new Date(json['plannedEndDate'])),
        'plannedExportDate': json['plannedExportDate'] == null ? undefined : (new Date(json['plannedExportDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'id': json['id'],
        'product': json['product'] == null ? undefined : ProductDetailSchemaFromJSON(json['product']),
    };
}

export function ProductProductionItemDetailSchemaToJSON(value?: ProductProductionItemDetailSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productId': value['productId'],
        'serialNo': value['serialNo'],
        'batchNumber': value['batchNumber'],
        'amount': value['amount'],
        'plannedEndDate': value['plannedEndDate'] == null ? undefined : ((value['plannedEndDate'] as any).toISOString()),
        'plannedExportDate': value['plannedExportDate'] == null ? undefined : ((value['plannedExportDate'] as any).toISOString()),
        'comment': value['comment'],
        'id': value['id'],
        'product': ProductDetailSchemaToJSON(value['product']),
    };
}

