import categoryList from './categoryListContainer';
import editCategory from './editCategoryContainer';
import createCategory from './createCategoryContainer';
import showCategory from './showCategoryContainer';

export default {
  name: 'product/categories',
  list: categoryList,
  edit: editCategory,
  create: createCategory,
  show: showCategory,
  options: {
    label: 'Categories',
  },
};
