import {
  Button, Grid, Table, TableCell, TableRow,
} from '@mui/material';
import { StockLinesLineDetail, StockLinesResourceDetail, StockLinesShelf } from 'api/models';
import ShowField from 'components/commons/showField';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Show,
  SimpleShowLayout,
  TextField,
  // useResourceContext,
  useRecordContext,
} from 'react-admin';
import { Source } from 'utils/source';
// import exportStockLineQrCode from '../stockLine/qrCode';
import PrintIcon from '@mui/icons-material/Print';
import ShowQuantity from 'components/commons/showQuantity';
import { ImagePreview } from 'components/fields/imagePreview';
import { useEnumQuery } from 'repositories/app';
import { useStockLines } from 'repositories/stock/stockLines';
import { useStockLocations } from 'repositories/stock/stockLocations';
import exportStockLineQrCode from '../stockLine/qrCode';
import './showStock.css';

interface PrintButtonProps {
  line: StockLinesLineDetail;
}

function PrintButton(props: PrintButtonProps) {
  const {
    line,
  } = props;
  const { data: enums } = useEnumQuery();

  return (
    <Button
      startIcon={<PrintIcon />}
      onClick={() => exportStockLineQrCode(
        [{
          ...line,
        }],
        enums?.quantityTypes,
      )}
    />
  );
  /*
  */
}

interface StockLineProps {
  resourceId: number;
  quantityTypeId: number;
}
function StockLineInfo(props: StockLineProps) {
  const {
    resourceId,
    quantityTypeId,
  } = props;

  const record = useRecordContext<StockLinesShelf>();
  const stockLines = useStockLines({
    resourceId,
    shelfId: record.shelfId,
    stockLocationId: record.stockLocationId,
  });
  // const stockLocations = useStockLocations();
  // const getStockLocation = (id?: number | null) => id && stockLocations.data?.list.find((x) => x.id === id)?.name;
  return (
    <Table>
      {stockLines.data?.list?.map((x) => (
        <TableRow>
          <TableCell>{x.stockLot.date.toDateString()}</TableCell>
          <TableCell>{x.stockLot.name}</TableCell>
          <TableCell>
            {x.stockLot.supplierName}
          </TableCell>
          <TableCell>
            <ShowQuantity value={x.amount} quantityTypeId={quantityTypeId} />
          </TableCell>
          <PrintButton line={x} />
        </TableRow>
      ))}
    </Table>
  );
}

function StockLineTable() {
  // const stockLocations = useStockLocations();
  const record = useRecordContext<StockLinesResourceDetail>();
  const s = Source<StockLinesResourceDetail>();
  const line = Source<StockLinesShelf>();
  const { data: stockLocations } = useStockLocations();
  return (
    <ArrayField label="resources.stock/stock-moves.fields.stockMoveLines" {...s('shelfs')}>
      <Datagrid
        expand={
          (
            <StockLineInfo
              resourceId={record?.id}
              quantityTypeId={record?.quantityTypeId}
            />
          )
        }
        bulkActionButtons={false}
      >
        <FunctionField
          label="Stock Location"
          render={(data: StockLinesShelf) => (
            <div>{stockLocations?.list.find((x) => x.id === data.stockLocationId)?.name}</div>
          )}
        />
        <TextField {...line('name')} label="Shelf Name" />
        <ImagePreview
          {...line('image')}
          label="Shelf image"
        />
        <TextField {...line('count')} />
        <FunctionField
          label="Amount"
          render={(shelf: StockLinesShelf) => (
            <ShowQuantity value={shelf.amount} quantityTypeId={record?.quantityTypeId} />
          )}
        />
      </Datagrid>
    </ArrayField>
  );
}

export default function () {
  const s = Source<StockLinesResourceDetail>();
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>

          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.product/raw-materials.fields.articleNo" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('articleNo')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.product/raw-materials.fields.name" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField {...s('name')} className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />

          <Grid item md={2} />
          <Grid item xs={8} md={8}>
            <StockLineTable />
          </Grid>
          <Grid item md={2} />
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
