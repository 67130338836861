import businessPartnerList from './list';
import editBusinessPartner from './edit';
import createBusinessPartner from './create';
import showBusinessPartner from './showBusinessPartnerContainer';

export default {
  name: 'business-partner/business-partners',
  list: businessPartnerList,
  edit: editBusinessPartner,
  create: createBusinessPartner,
  show: showBusinessPartner,
  recordRepresentation: 'name',
  options: {
    label: 'Business Partners',
  },
};
