import React from 'react';
import {
  HttpError,
  NotificationOptions,
  NotificationType,
  useNotify as _useNotify,
  useTranslate,
} from 'react-admin';

const useNotify = () => {
  const notify = _useNotify();
  const translate = useTranslate();

  return React.useMemo(() => (
    error: string | HttpError,
    options?: (NotificationOptions & { type?: NotificationType | undefined }),
  ) => {
    const newOptions = options || {};
    let message: string;
    if (error instanceof HttpError) {
      message = translate(error.message);
      if (error.body) {
        const data = Object.entries(error.body).map((entry) => `${entry[0]}: ${entry[1]}`);

        message += `\n${data.join('\n')}`;
      }
      newOptions.multiLine = true;
    } else {
      message = translate(error);
    }

    notify(
      message,
      newOptions,
    );
  }, [notify, translate]);
};

export default useNotify;
