import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Dialog,
  IconButton,
  List,
  ListItem,
  TextField,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import * as React from 'react';
import {
  NullableBooleanInput,
  SelectInputProps,
  useInput,
} from 'react-admin';
import { useInfiniteResourcesQuery, useResourceQuery } from 'repositories/products/product';
import useTranslate from 'utils/translate';
import InfiniteScroll from 'components/list/infiniteScroll';
import CategoryChoose from './categoryChoose';
import styles from './productChooser.module.scss';

export interface ProductChooserProps extends Omit<SelectInputProps, 'onChange'> {
  isFullWidth?: boolean;
  value?: number;
  onChange?: (id: number) => void;
}

export default function ProductChooser(props: ProductChooserProps) {
  const {
    source,
    onChange = () => { },
    required,
    disabled = false,
    isFullWidth = false,
    value,
  } = props;

  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const [filter, setFilter] = React.useState('');
  const [category, setCategory] = React.useState<null | number[]>(null);
  const [state, setState] = React.useState<null | boolean>(null);

  const {
    data, fetchNextPage, isLoading, hasNextPage, remove,
  } = useInfiniteResourcesQuery(
    {
      query: filter,
      categoryIds: typeof category === 'number' ? [category] : undefined,
      isActive: state || undefined,
    },
    {
      enabled: !!open,
    },
  );
  const allEntries = data?.pages.flatMap((page) => page.list);

  const {
    field,
  } = useInput({
    onChange,
    source: source || '',
  });
  const id: number | undefined = value !== undefined ? value : field.value;

  const { data: currentChoice } = useResourceQuery(id as number, { enabled: !!id });
  // const [selectedValue, setSelectedValue] = React.useState<number>(field.value);
  const [disableFocus, setDisableFocus] = React.useState(false);

  const setClose = () => {
    setDisableFocus(true);
    remove();
    setOpen(false);
  };

  let placeholderText = '';
  if (currentChoice) {
    placeholderText = `${currentChoice.articleNo} ${currentChoice.name}`;
  }

  if (open) {
    placeholderText = filter;
  } else if (currentChoice) {
    placeholderText = `${currentChoice.articleNo} ${currentChoice.name}`;
  }

  return (
    <div>
      <Box>
        <TextField
          id="placehold"
          size="small"
          label={translate('mai.resource')}
          variant="outlined"
          fullWidth={isFullWidth}
          disabled={disabled}
          inputProps={{
            endadornment: !!id && !required ? (
              <IconButton>
                <ClearIcon />
              </IconButton>
            ) : null,
          }}
          value={placeholderText}
          onChange={(ev) => { setFilter(ev.target.value); }}
          onClick={() => setOpen(true)}
          required={required}
          onFocus={() => {
            if (disableFocus) {
              setDisableFocus(false);
            } else {
              setOpen(true);
            }
          }}
        // type="search"
        />

      </Box>

      <Dialog
        open={open}
        onClose={setClose}
        className={styles.productChooser}
      >
        <DialogTitle>
          Choose Product
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <TextField
            id="outlined-basic"
            size="small"
            label={translate('mai.keyword')}
            variant="outlined"
            autoFocus
            disabled={disabled}
            value={filter}
            onChange={(ev) => setFilter(ev.target.value)}
          />
          <div className={styles.categoryChoose}>
            <CategoryChoose
              // @ts-ignore
              value={category ? [category] : null}
              onChange={(cat) => setCategory(cat as number[])}
              placeholder={translate('mai.product.categoryId')}
            />
            <NullableBooleanInput
              value={state}
              source={translate('mai.product.stateActive')}
              onChange={(ev) => setState(ev.target.value)}
              placeholder={translate('mai.product.stateCode')}
              nullLabel={translate('mai.product.stateNull')}
              falseLabel={translate('mai.product.stateInactive')}
              trueLabel={translate('mai.product.stateActive')}
              variant="outlined"
              sx={{
                '& .MuiFormHelperText-root': { display: 'none' },
              }}
            />

          </div>

          {/* <Divider sx={{ my: 0.5 }} /> */}
          <div className="products">
            <InfiniteScroll
              fetchNextPage={fetchNextPage}
              isLoading={isLoading}
              hasNextPage={!!hasNextPage}
            >
              <List className={styles.list}>
                {allEntries?.map(
                  (child) => {
                    const repr = child.articleNo ? `${child.articleNo} - ${child.name}` : child.name;
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    // jsx-a11y/no-static-element-interactions
                    return (
                      <ListItem
                        className="choice"
                        key={child.id}
                        value={child.id}
                        onClick={() => {
                          field.onChange(child.id);
                          setClose();
                        }}
                      >
                        <p className="name">{repr}</p>
                        <p className="description">{child.description}</p>

                      </ListItem>
                    );
                  },
                )}
              </List>
            </InfiniteScroll>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
