import useHasAccess from 'hooks/useHasAccess';
import { useCreatePath, useResourceContext } from 'react-admin';

export default function useResourcePermissions(resource?: string) {
  const resourceContext = useResourceContext();
  const createPath = useCreatePath();
  const hasAccess = useHasAccess();

  // eslint-disable-next-line no-param-reassign
  resource = resource || resourceContext;

  const baseUrl = createPath({ resource, type: 'list' });
  const resourceUrl = `${baseUrl}/{id:long}`;

  return {
    list: hasAccess({ url: baseUrl, httpMethod: 'GET' }),
    show: hasAccess({ url: resourceUrl, httpMethod: 'GET' }),
    create: hasAccess({ url: baseUrl, httpMethod: 'POST' }),
    update: hasAccess({ url: resourceUrl, httpMethod: 'PUT' }),
    delete: hasAccess({ url: resourceUrl, httpMethod: 'DELETE' }),
    deleteMany: hasAccess({ url: baseUrl, httpMethod: 'DELETE' }),
  };
}
