import { Grid } from '@mui/material';
import ShowField from 'components/commons/showField';
import ShowLabel from 'components/commons/showLabel';
import ShowTitle from 'components/commons/showTitle';
import ShowActions from 'components/layout/actions/show';
import * as React from 'react';
import {
  DateField,
  Show,
  SimpleShowLayout,
} from 'react-admin';

export default function () {
  return (
    <Show actions={<ShowActions />}>
      <SimpleShowLayout>
        <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
          <Grid item xs={12} md={12}>
            <ShowTitle />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/productionItems.fields.serialNo" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="serialNo" className="highLightField" />
          </Grid>
          <Grid className="show-row-odd" item xs={2} md={1.5} />
          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.product/productionItems.fields.batchNumber" />
          </Grid>
          <Grid item xs={8} md={9}>
            <ShowField source="batchNumber" />
          </Grid>

          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/products.fields.name" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="product.name" className="highLightField" />
          </Grid>

          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.product/productionItems.fields.amount" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="amount" className="highLightField" />
          </Grid>
          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5}>
            <ShowLabel label="resources.product/productionItems.fields.plannedEndDate" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <DateField source="plannedEndDate" />
          </Grid>

          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/productionItems.fields.plannedExportDate" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <DateField source="plannedExportDate" className="highLightField" />
          </Grid>

          <Grid item xs={2} md={1.5} />
          <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
            <ShowLabel label="resources.product/productionItems.fields.comment" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <ShowField source="comment" className="highLightField" />
          </Grid>

        </Grid>
      </SimpleShowLayout>
    </Show>
  );
}
