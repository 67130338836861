import { ProductRawMaterialEntity, RawMaterialsSearchRequest } from 'api';
import api from 'repositories/api';

export enum PopupSupplier {
  Dominico,
  Porcher,
  Edelrid,
}

export interface SupplierScanResult {
  resource?: ProductRawMaterialEntity,
  stockLotName?: string,
  amount?: number,
  error?: string,
  supplierPopup?: PopupSupplier,
}

export async function handleSupplierBarcode(state: SupplierScanResult | undefined, barcode: string) {
  const result: SupplierScanResult = {
    resource: undefined,
    stockLotName: undefined,
    amount: undefined,
    supplierPopup: undefined,
    error: undefined,
    ...state,
  };

  // in a multi-barcode context?
  // catch early
  if (state?.supplierPopup === PopupSupplier.Porcher) {
    const matchSerial = barcode.match(/^(\d{10})$/);
    const matchAmount = barcode.match(/^(\d{1,8})$/);
    if (matchSerial) {
      result.stockLotName = barcode;
    } else if (matchAmount) {
      result.amount = parseInt(barcode, 10);
    } else {
      result.error = `Invalid barcode for Porcher: ${barcode}`;
    }

    return result;
  }
  if (state?.supplierPopup === PopupSupplier.Edelrid) {
    const matchSerial = barcode.match(/^([A-Z])-(\d{4})-(\d{4})-(\d{3})$/);
    if (matchSerial) {
      result.stockLotName = barcode;
      result.supplierPopup = undefined;
    } else {
      result.error = `Invalid barcode for Edelrid: ${barcode}`;
    }
    return result;
  }
  if (state?.supplierPopup === PopupSupplier.Dominico) {
    console.log('no');
    console.log('B');
  }

  const getProduct = async (data: RawMaterialsSearchRequest, barcodeSupplier?: PopupSupplier) => {
    const products = await api.rawMaterialsSearch(data);
    const resource = products.list[0];

    if (resource === undefined || products.list.length !== 1) {
      result.error = 'product not found';
    } else {
      result.resource = resource;
      result.supplierPopup = barcodeSupplier;
    }
  };

  // starter barcodes
  // Dominico
  const matchDominico = barcode.match(/^([^\d]+)(\d+)\s+(\d)\s+(\d+)\s+M[0-9.]+/);
  if (matchDominico) {
    console.log(matchDominico);
    /*
    let product = await api.rawMaterialsSearch({ supplierArticleNo: matchDominico });
    result.resourceId =
      resourceId = materials?.find((m) => matchDominico?.[1] === m.supplierName)?.id;
    lotNo = `${matchDominico?.[2]}/${matchDominico?.[3]}`;
    amount = Number(matchDominico?.[4]);
    supplier = 'Dominico';
    */

    return result;
  }
  const matchPorcher = barcode.match(/^(\d{9})([0-9A-Za-z]{3})(\d{3})$/);
  if (matchPorcher) {
    const supplierArticleNo = barcode;
    await getProduct({ supplierArticleNo }, PopupSupplier.Porcher);
    return result;
  }

  // Edelrid

  const matchEdelrid = barcode.match(/[2]{1}(\d{11})/);
  if (matchEdelrid) {
    await getProduct({ supplierArticleNo: barcode }, PopupSupplier.Edelrid);
    return result;
  }

  // Liros
  const matchLiros = barcode.match(/([0-9]{13})/);
  if (matchLiros) {
    result.stockLotName = matchLiros?.[0];
  }

  // amann
  const matchAmann = barcode.match(/^(\d{4})-(\d{4})-([0-9A-Z]{5})$/);
  if (matchAmann) {
    await getProduct({ supplierArticleNo: `${matchAmann[1]}-${matchAmann[2]}` });
    const lotName = matchAmann[3];
    result.stockLotName = lotName;
    return result;
  }

  result.error = 'no matching barcode found';
  return result;
}
