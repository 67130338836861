/* eslint-disable react/jsx-no-duplicate-props */
import { InputAdornment } from '@mui/material';
import { AppQuantityTypeOption } from 'api/models/AppQuantityTypeOption';
import * as React from 'react';
import {
  TextInput,
  TextInputProps,
  Validator,
  number,
  required,
} from 'react-admin';
import NumberFormat, { InputAttributes, NumberFormatProps } from 'react-number-format';
import { useEnumQuery } from 'repositories/app';
import useTranslate from 'utils/translate';

interface QuantityInputProps extends NumberFormatProps {
  quantityType: AppQuantityTypeOption | undefined,
  onChange: (event: { target: { name: string; value: string } }) => void;
  value: number,
  name: string,
  disabled?: boolean,
  max: number,
}

const QuantityInput = React.forwardRef<NumberFormat<InputAttributes>, QuantityInputProps
>((props: QuantityInputProps, ref) => {
  const {
    onChange, quantityType, disabled, max, ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      fixedDecimalScale
      thousandSeparator=","
      decimalScale={quantityType?.decimals}
      disabled={!quantityType || disabled}
      onValueChange={(values, event) => {
        if (event.event) {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }
      }}
      maxLength={20}
      isNumericString
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        if (floatValue == null) {
          return formattedValue === '';
        }
        if (max && floatValue > max) {
          return false;
        }
        return true;
      }}
    />
  );
});

interface QuantityTypeInputProps extends TextInputProps {
  positiveRequired?: boolean;
  quantityType: number | undefined;
  max?: number;
}

export default function (props: QuantityTypeInputProps) {
  const {
    className,
    label,
    source,
    quantityType: quantityTypeId,
    disabled,
    max,
    positiveRequired,
    ...rest
  } = props;
  const { data: enums } = useEnumQuery();
  const translate = useTranslate();

  const quantityType = enums?.quantityTypes.find((x) => x.id === quantityTypeId);
  const unit = quantityType ? quantityType.unit : '';
  const validatePositiveValue = (value: any) => {
    if (value <= 0.000) {
      return translate('ra.validation.positive');
    }
  };
  const validation: Validator[] = [number()];
  // eslint-disable-next-line react/destructuring-assignment
  if (props.required) {
    validation.push(required());
  }
  if (positiveRequired) {
    validation.push(validatePositiveValue);
  }
  return (
    <TextInput
      {...rest}
      className={className}
      label={label}
      source={source}
      validate={validation}
      variant="outlined"
      helperText={false}
      fullWidth
      disabled={disabled}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit ?? ''}</InputAdornment>,
        inputComponent: QuantityInput as any,
        inputProps: { quantityType, disabled, max },
      }}
    />
  );
}
