/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockIncomingShipmentList
 */
export interface StockIncomingShipmentList {
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentList
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    state: StockIncomingShipmentListStateEnum;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    supplier: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    transportType: StockIncomingShipmentListTransportTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentList
     */
    estimatedTimeofArrival: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockIncomingShipmentList
     */
    actualTimeofArrival?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    shipmentName: string;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockIncomingShipmentList
     */
    stockMoveId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockIncomingShipmentList
     */
    createdBy?: string | null;
}


/**
 * @export
 */
export const StockIncomingShipmentListStateEnum = {
    WaitingForDeparture: 'waiting_for_departure',
    DuringShipment: 'during_shipment',
    Processing: 'processing',
    Done: 'done',
    Error: 'error'
} as const;
export type StockIncomingShipmentListStateEnum = typeof StockIncomingShipmentListStateEnum[keyof typeof StockIncomingShipmentListStateEnum];

/**
 * @export
 */
export const StockIncomingShipmentListTransportTypeEnum = {
    Sea: 'Sea',
    Air: 'Air',
    Truck: 'Truck',
    Express: 'Express',
    Other: 'Other'
} as const;
export type StockIncomingShipmentListTransportTypeEnum = typeof StockIncomingShipmentListTransportTypeEnum[keyof typeof StockIncomingShipmentListTransportTypeEnum];


/**
 * Check if a given object implements the StockIncomingShipmentList interface.
 */
export function instanceOfStockIncomingShipmentList(value: object): value is StockIncomingShipmentList {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('supplier' in value) || value['supplier'] === undefined) return false;
    if (!('transportType' in value) || value['transportType'] === undefined) return false;
    if (!('estimatedTimeofArrival' in value) || value['estimatedTimeofArrival'] === undefined) return false;
    if (!('shipmentName' in value) || value['shipmentName'] === undefined) return false;
    return true;
}

export function StockIncomingShipmentListFromJSON(json: any): StockIncomingShipmentList {
    return StockIncomingShipmentListFromJSONTyped(json, false);
}

export function StockIncomingShipmentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockIncomingShipmentList {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'state': json['state'],
        'supplier': json['supplier'],
        'transportType': json['transportType'],
        'estimatedTimeofArrival': (new Date(json['estimatedTimeofArrival'])),
        'actualTimeofArrival': json['actualTimeofArrival'] == null ? undefined : (new Date(json['actualTimeofArrival'])),
        'shipmentName': json['shipmentName'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'stockMoveId': json['stockMoveId'] == null ? undefined : json['stockMoveId'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
    };
}

export function StockIncomingShipmentListToJSON(value?: StockIncomingShipmentList | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'state': value['state'],
        'supplier': value['supplier'],
        'transportType': value['transportType'],
        'estimatedTimeofArrival': ((value['estimatedTimeofArrival']).toISOString()),
        'actualTimeofArrival': value['actualTimeofArrival'] == null ? undefined : ((value['actualTimeofArrival'] as any).toISOString()),
        'shipmentName': value['shipmentName'],
        'comment': value['comment'],
        'stockMoveId': value['stockMoveId'],
        'createdBy': value['createdBy'],
    };
}

