function getFilename(contentDisposition?: string) {
  let fileName = 'unknown';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"|filename=([\w._-]+)/);

    if (fileNameMatch) {
      const [, match1, match2] = fileNameMatch;
      fileName = match1 || match2;
    }
  }

  return fileName;
}

export function canShow(mime_type: string) {
  return mime_type === 'application/pdf';
}

export const openBlob = (blob: Blob, contentDisposition?: string) => {
  const url = window.URL.createObjectURL(blob);
  const fileName = getFilename(contentDisposition);

  const newWindow = window.open();
  newWindow?.document.write(
    `<title>${fileName}</title>
    <iframe
      src="${url}"
      frameborder="0"
      style="border:0;
      top:0px;
      left:0px;
      bottom:0px;
      right:0px;
      width:100%;
      height:100%;"
      allowfullscreen>
    </iframe>
    `,
  );
};

export const loadBlob = (blob: Blob, contentDisposition?: string): void => {
  const url = window.URL.createObjectURL(blob);
  const fileName = getFilename(contentDisposition);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default loadBlob;
