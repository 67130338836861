import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Create,
  ListButton,
  TopToolbar,
} from 'react-admin';
import ProductForm from './form';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canSearch = hasAccess({ url: 'product/products/', httpMethod: 'GET' });

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <ProductForm create />
    </Create>
  );
});
