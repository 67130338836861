import BalanceIcon from '@mui/icons-material/Balance';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  Grid,
  ListItemText,
  Button as MUIButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { PurchasingPurchaseOrderLineShipment, StockIncomingShipmentUpdate, StockShipmentLineSchema } from 'api';
import RawMaterialChooser from 'components/chooser/rawMaterialChooser';
import { ResourceQuantityInput } from 'components/input/quantity/resourceFields';
import InfiniteScroll from 'components/list/infiniteScroll';
import React from 'react';
import {
  ArrayInput, FormDataConsumer,
  Button as RaButton,
  SimpleFormIterator, TextInput,
} from 'react-admin';
import { Path, useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useInfinitePurchaseOrderLines } from 'repositories/purchasing/purchaseOrder';
import { usePasteData } from 'resources/product/billOfResourceForm';
import PasteBillOfResources from 'resources/product/pasteBillOfResources';
import useTranslate from 'utils/translate';
import './shipmentLine.css';

interface ShipmentLineDialogProps {
  supplierId: number;
  onAdd: (selectedLines: StockShipmentLineSchema[]) => void;
}

interface FormLineData extends PurchasingPurchaseOrderLineShipment {
  active: boolean;
}

interface FormData {
  choices: FormLineData[];
}

function ShipmentLineDialog(props: ShipmentLineDialogProps) {
  const {
    supplierId, onAdd,
  } = props;
  const { id } = useParams();
  const translate = useTranslate();
  const form = useFormContext<FormData>();
  const { fields: choices } = useFieldArray({
    control: form.control,
    name: 'choices',
    keyName: 'key',
  });
  const [open, setOpen] = React.useState(false);
  const [selectedLines, setSelectedLines] = React.useState<PurchasingPurchaseOrderLineShipment[]>([]);

  const {
    data, fetchNextPage, isLoading, hasNextPage, refetch,
  } = useInfinitePurchaseOrderLines(
    {
      businessPartnerId: supplierId as number,
    },
    {
      enabled: !!open, // disable initial load
    },
  );

  const lines = data?.pages.flatMap((page) => page.list);

  React.useEffect(() => {
    if (lines?.length) {
      const newChoices = lines?.map((x) => ({
        ...x,
        active: false,
      }));
      form.setValue('choices', newChoices);
    }
  }, [lines]);

  const handleOpen = () => {
    refetch();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleToggle = (line: PurchasingPurchaseOrderLineShipment) => {
    setSelectedLines((prevSelectedLines) => {
      const currentIndex = prevSelectedLines.findIndex((l) => l.id === line.id);
      const newSelectedLineIds = [...prevSelectedLines];
      if (currentIndex === -1) {
        newSelectedLineIds.push(line);
      } else {
        newSelectedLineIds.splice(currentIndex, 1);
      }
      return newSelectedLineIds;
    });
  };

  const onAddLines = (newSelectedLines: PurchasingPurchaseOrderLineShipment[]) => {
    const choicesList = form.getValues('choices');
    const newLines = choicesList.filter((choice) => newSelectedLines.some((line) => line.id === choice.id));
    const newLineList = newLines.map((line) => ({
      rawMaterialId: line.rawMaterialId,
      purchaseOrderLineId: line.id,
      amount: line.openAmount,
    }));
    handleClose();
    onAdd(newLineList);
  };

  return (
    <>
      {
        id && (
          <RaButton
            onClick={handleOpen}
            label="Add from Purchase Order"
            startIcon={<BalanceIcon />}
          />
        )
      }

      <Dialog open={open && Boolean(supplierId)} onClose={handleClose} classes={{ paper: 'custom-dialog-paper' }}>
        <DialogTitle>Select Shipment Lines</DialogTitle>
        <DialogContent>
          <Divider sx={{ my: 0.5 }} />
          <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
            <InfiniteScroll
              fetchNextPage={fetchNextPage}
              isLoading={isLoading}
              hasNextPage={!!hasNextPage}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Checkbox</TableCell>
                    <TableCell>Order Sequence</TableCell>
                    <TableCell>Article No</TableCell>
                    <TableCell>Resource</TableCell>
                    <TableCell>Amount Open</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {choices?.map((line, index) => (
                    <TableRow key={line.id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedLines.some((l) => l.id === line.id)}
                          tabIndex={-1}
                          disableRipple
                          onChange={() => handleToggle(line)}
                        />
                      </TableCell>
                      <TableCell><ListItemText primary={line.purchaseOrderName} /></TableCell>
                      <TableCell><ListItemText primary={line.articleNo} /></TableCell>
                      <TableCell><ListItemText primary={line.rawMaterial} /></TableCell>
                      <TableCell><ListItemText primary={line.openAmount} /></TableCell>
                      <TableCell>
                        <ResourceQuantityInput
                          className="amount"
                          style={{ maxWidth: '130px', marginRight: '16px' }}
                          label={translate('mai.amount')}
                          disabled={!selectedLines.some((l) => l.id === line.id)}
                          source={`choices.${index}.openAmount`}
                          resourceId={line.rawMaterialId}
                        />
                      </TableCell>
                      <TableCell>

                        <ListItemText primary={line.quantityTypeName} />
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleClose}>Cancel</MUIButton>
          <MUIButton onClick={() => onAddLines(selectedLines)} color="primary">
            Add To sShipment
          </MUIButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface ShipmentLineFormProps {
  getSource: (q: Path<StockShipmentLineSchema>) => string,
  scopedFormData: StockShipmentLineSchema,
  defaultSupplier: number | undefined,
}

function ShipmentLineForm(props: ShipmentLineFormProps) {
  const {
    getSource,
    scopedFormData,
    defaultSupplier,
  } = props;
  const translate = useTranslate();

  return (
    <Grid container spacing={2} marginTop="10px" marginBottom="10px">
      <Grid item md={2} />
      <Grid item md={4}>

        <RawMaterialChooser
          // focused={false}
          required
          disabled={!!scopedFormData.purchaseOrderLineId}
          label={translate('mai.resources')}
          // validate={[required()]}
          source={getSource('rawMaterialId') || ''}
          className="resourcesSelect"
          defaultSupplier={defaultSupplier}
        // onChange={() => onChangeHandle(scopedFormData ?? '0')}
        />
      </Grid>
      <Grid item md={4}>
        <ResourceQuantityInput
          className="amount"
          label={translate('mai.amount')}
          source={getSource('amount')!}
          resourceId={scopedFormData?.rawMaterialId}
        />
      </Grid>
      <Grid item md={2} />

      <Grid item md={2} />
      <Grid item md={8}>
        <TextInput
          label={translate('mai.comment')}
          source={getSource('comment')!}
          fullWidth
          variant="outlined"
          helperText={false}
          className="comment"
        />
      </Grid>
      <Grid item md={2} />

    </Grid>
  );
}

interface ShipmentLinesFormProps {
  source: string;
}

export default function ShipmentLinesForm(props: ShipmentLinesFormProps) {
  const {
    source,
  } = props;
  const form = useFormContext<StockIncomingShipmentUpdate>();
  const supplierId = form.watch('supplierId');
  const getPasteData = usePasteData();

  const onPaste = React.useCallback(async (data: [number, number][]) => {
    const newData = getPasteData(data)
      .filter((el) => el.resourceId !== undefined && el.amount > 0)
      .map((el) => ({ rawMaterialId: el.resourceId as number, amount: el.amount, comment: '' }));

    form.setValue(
      'lines',
      newData,
      { shouldDirty: true },
    );
  }, [form, getPasteData]);

  const handleAddShipmentLines = (selectedLines: StockShipmentLineSchema[]) => {
    const oldLines = form.getValues('lines') || [];
    const newLines = oldLines?.filter((item) => (
      item.id || selectedLines.findIndex((sl) => sl.purchaseOrderLineId === item.purchaseOrderLineId) === -1
    ));
    form.setValue('lines', [...newLines, ...selectedLines], { shouldDirty: true });
  };

  return (
    <>

      <PasteBillOfResources onSubmit={onPaste} />
      <ShipmentLineDialog
        supplierId={supplierId}
        onAdd={handleAddShipmentLines}
      />
      <ArrayInput source={source}>
        <SimpleFormIterator className="form" disableClear>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <ShipmentLineForm
                getSource={getSource!}
                // @ts-ignore
                scopedFormData={scopedFormData}
                defaultSupplier={supplierId}
              />
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
}
