/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldTitle, useInput } from 'ra-core';
import clsx from 'clsx';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { CommonInputProps, InputHelperText, sanitizeInputRestProps } from 'react-admin';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

function CustomDatePicker({
  className,
  defaultValue,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  format = getStringFromDate,
  label,
  name,
  source,
  resource,
  helperText,
  margin,
  onBlur,
  onChange,
  parse,
  validate,
  variant,
  ...rest
}: DateInputProps) {
  const {
    field, fieldState, formState, id, isRequired,
  } = useInput({
    defaultValue,
    name,
    format,
    parse,
    onBlur,
    onChange,
    resource,
    source,
    validate,
    ...rest,
  });

  const { error, invalid, isTouched } = fieldState;

  const { isSubmitted } = formState;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
    >
      <DatePicker
        {...field}
        displayWeekNumber
        format="DD/MM/YYYY"
        value={field?.value ? dayjs(field?.value) : null}
        onChange={(value) => field.onChange(value?.toDate())}
        slotProps={{
          textField: {
            id,
            className: clsx('ra-input', `ra-input-${source}`, className),
            size: 'small',
            error: (isTouched || isSubmitted) && invalid,
            helperText: (
              <InputHelperText
                touched={isTouched || isSubmitted}
                error={error?.message}
                helperText={helperText}
              />
            ),
            label: (
              <FieldTitle
                label={label}
                source={source}
                resource={source}
                isRequired={isRequired}
              />
            ),
            ...sanitizeInputRestProps(rest),
          },
        }}
      />
    </LocalizationProvider>
  );
}

export type DateInputProps = CommonInputProps & Omit<TextFieldProps, 'helperText' | 'label'>;

const convertDateToString = (value: Date) => {
  // eslint-disable-next-line no-restricted-globals
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();
  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
// const defaultInputLabelProps = { shrink: true };

const getStringFromDate = (value: string | Date) => {
  // null, undefined and empty string values should not go through dateFormatter
  // otherwise, it returns undefined and will make the input an uncontrolled one.
  if (value == null || value === '') {
    return '';
  }

  if (value instanceof Date) {
    return convertDateToString(value);
  }

  // valid dates should not be converted
  if (dateRegex.test(value)) {
    return value;
  }
  return convertDateToString(new Date(value));
};

export default CustomDatePicker;
