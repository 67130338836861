/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockInventoryControlUpdate
 */
export interface StockInventoryControlUpdate {
    /**
     * 
     * @type {Date}
     * @memberof StockInventoryControlUpdate
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlUpdate
     */
    stockLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof StockInventoryControlUpdate
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StockInventoryControlUpdate
     */
    partialInventory: boolean;
    /**
     * 
     * @type {number}
     * @memberof StockInventoryControlUpdate
     */
    id: number;
}

/**
 * Check if a given object implements the StockInventoryControlUpdate interface.
 */
export function instanceOfStockInventoryControlUpdate(value: object): value is StockInventoryControlUpdate {
    if (!('stockLocationId' in value) || value['stockLocationId'] === undefined) return false;
    if (!('partialInventory' in value) || value['partialInventory'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function StockInventoryControlUpdateFromJSON(json: any): StockInventoryControlUpdate {
    return StockInventoryControlUpdateFromJSONTyped(json, false);
}

export function StockInventoryControlUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockInventoryControlUpdate {
    if (json == null) {
        return json;
    }
    return {
        
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'stockLocationId': json['stockLocationId'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'partialInventory': json['partialInventory'],
        'id': json['id'],
    };
}

export function StockInventoryControlUpdateToJSON(value?: StockInventoryControlUpdate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'stockLocationId': value['stockLocationId'],
        'comment': value['comment'],
        'partialInventory': value['partialInventory'],
        'id': value['id'],
    };
}

