import messages from 'i18n/en';
import { Path } from 'react-hook-form';

export interface Option<EnumType> {
  id: keyof EnumType,
  label: Path<typeof messages>,
  name: string
}

export default function enumToOptions<
  EnumType extends Record<string, string>,
>(
  enumOptions: EnumType,
  getKey?: (value: keyof (EnumType)) => Path<typeof messages>,
): Option<EnumType>[] {
  return Object.entries(enumOptions).map(
    ([key, name]) => {
      let fullName: Path<typeof messages>;
      if (getKey !== undefined) {
        fullName = getKey(key);
      } else {
        // @ts-ignore
        fullName = name;
      }
      return {
        id: name,
        label: fullName,
        name: fullName,
      };
    },
  );
}
