import productList from './productListContainer';
import createProduct from './createProductContainer';
import editProduct from './editProductContainer';
import showProduct from './showProductContainer';

export default {
  name: 'product/products',
  list: productList,
  create: createProduct,
  edit: editProduct,
  show: showProduct,
};
