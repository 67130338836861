/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BusinessPartnerAddressSchema } from './BusinessPartnerAddressSchema';
import {
    BusinessPartnerAddressSchemaFromJSON,
    BusinessPartnerAddressSchemaFromJSONTyped,
    BusinessPartnerAddressSchemaToJSON,
} from './BusinessPartnerAddressSchema';

/**
 * 
 * @export
 * @interface BusinessPartnerSchema
 */
export interface BusinessPartnerSchema {
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    defaultDeliveryAddress: BusinessPartnerSchemaDefaultDeliveryAddressEnum;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    taxId?: string | null;
    /**
     * 
     * @type {BusinessPartnerAddressSchema}
     * @memberof BusinessPartnerSchema
     */
    address: BusinessPartnerAddressSchema;
    /**
     * 
     * @type {Date}
     * @memberof BusinessPartnerSchema
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerSchema
     */
    createdBy: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    createdIp?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BusinessPartnerSchema
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerSchema
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    updatedIp?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof BusinessPartnerSchema
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof BusinessPartnerSchema
     */
    deletedBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerSchema
     */
    deletedIp?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessPartnerSchema
     */
    delFlag: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BusinessPartnerSchema
     */
    forceDel: boolean;
}


/**
 * @export
 */
export const BusinessPartnerSchemaDefaultDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type BusinessPartnerSchemaDefaultDeliveryAddressEnum = typeof BusinessPartnerSchemaDefaultDeliveryAddressEnum[keyof typeof BusinessPartnerSchemaDefaultDeliveryAddressEnum];


/**
 * Check if a given object implements the BusinessPartnerSchema interface.
 */
export function instanceOfBusinessPartnerSchema(value: object): value is BusinessPartnerSchema {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('defaultDeliveryAddress' in value) || value['defaultDeliveryAddress'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('delFlag' in value) || value['delFlag'] === undefined) return false;
    if (!('forceDel' in value) || value['forceDel'] === undefined) return false;
    return true;
}

export function BusinessPartnerSchemaFromJSON(json: any): BusinessPartnerSchema {
    return BusinessPartnerSchemaFromJSONTyped(json, false);
}

export function BusinessPartnerSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPartnerSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'defaultDeliveryAddress': json['defaultDeliveryAddress'],
        'name': json['name'],
        'phone': json['phone'],
        'taxId': json['taxId'] == null ? undefined : json['taxId'],
        'address': BusinessPartnerAddressSchemaFromJSON(json['address']),
        'createdAt': (new Date(json['createdAt'])),
        'createdBy': json['createdBy'],
        'createdIp': json['createdIp'] == null ? undefined : json['createdIp'],
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
        'updatedBy': json['updatedBy'] == null ? undefined : json['updatedBy'],
        'updatedIp': json['updatedIp'] == null ? undefined : json['updatedIp'],
        'deletedAt': json['deletedAt'] == null ? undefined : (new Date(json['deletedAt'])),
        'deletedBy': json['deletedBy'] == null ? undefined : json['deletedBy'],
        'deletedIp': json['deletedIp'] == null ? undefined : json['deletedIp'],
        'delFlag': json['delFlag'],
        'forceDel': json['forceDel'],
    };
}

export function BusinessPartnerSchemaToJSON(value?: BusinessPartnerSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'defaultDeliveryAddress': value['defaultDeliveryAddress'],
        'name': value['name'],
        'phone': value['phone'],
        'taxId': value['taxId'],
        'address': BusinessPartnerAddressSchemaToJSON(value['address']),
        'createdAt': ((value['createdAt']).toISOString()),
        'createdBy': value['createdBy'],
        'createdIp': value['createdIp'],
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'updatedBy': value['updatedBy'],
        'updatedIp': value['updatedIp'],
        'deletedAt': value['deletedAt'] == null ? undefined : ((value['deletedAt'] as any).toISOString()),
        'deletedBy': value['deletedBy'],
        'deletedIp': value['deletedIp'],
        'delFlag': value['delFlag'],
        'forceDel': value['forceDel'],
    };
}

