import React from 'react';
import { Route } from 'react-router';
import { Authenticated } from 'react-admin';
import stockMoveList from './list/stockMoveList';
import editStockMove from './editStockMoveContainer';
import createStockMove from './createStockMoveContainer';
import showStockMove from './showStockMoveContainer';
import StockMoveLineContainerCreate from './lines/stockMoveLines';
import StockLinePlannedContainer from './lines/plannedStockLines';

export default {
  name: 'stock/stock-moves',
  list: stockMoveList,
  edit: editStockMove,
  create: createStockMove,
  show: showStockMove,
  options: {
    label: 'Stock moves',
  },
  children: [
    <Route
      path="line/:id"
      element={<Authenticated><StockMoveLineContainerCreate /></Authenticated>}
    />,
    <Route
      path="line/planned/:id"
      element={<Authenticated><StockLinePlannedContainer /></Authenticated>}
    />,
  ],
};
