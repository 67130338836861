import userList from './userList';
import editUser from './editUserContainer';
import createUser from './createUserContainer';
import showUser from './showUser';

export default {
  name: 'auth/users',
  list: userList,
  edit: editUser,
  create: createUser,
  show: showUser,
};
