import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTheme, Title } from 'react-admin';

import useTranslate from 'utils/translate';
import { darkTheme, lightTheme } from '../../components/shared/themes';

export default React.memo(() => {
  const translate = useTranslate();
  const [theme, setTheme] = useTheme();
  return (
    <Card>
      <Title title={translate('mai.configuration')} />
      <CardContent>
        <Box sx={{ width: '10em', display: 'inline-block' }}>
          {translate('mai.darklightmode')}
        </Box>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={
            theme === 'light'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setTheme(lightTheme)}
        >
          {translate('mai.light')}
        </Button>
        <Button
          variant="contained"
          sx={{ margin: '1em' }}
          color={
            theme === 'dark'
              ? 'primary'
              : 'secondary'
          }
          onClick={() => setTheme(darkTheme)}
        >
          {translate('mai.dark')}
        </Button>
      </CardContent>
    </Card>
  );
});
