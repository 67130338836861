import * as React from 'react';
import {
  Create,
  SimpleForm,
} from 'react-admin';
import CreateActions from 'components/layout/actions/create';
import BusinessPartnerForm from './form';

export default function BusinessPartnerCreateView() {
  return (
    <Create actions={<CreateActions />}>
      <SimpleForm>
        <BusinessPartnerForm />
      </SimpleForm>
    </Create>
  );
}
