import GridOnIcon from '@mui/icons-material/GridOn';
import { StockMoveList, StockMovesSearchMoveTypeEnum } from 'api';
import urls from 'app/urls';
import DefaultBulkActions from 'components/layout/actions/bulkActions';
import { ActionButtons } from 'components/list/actionButtons';
import { Badges, StatusBadge } from 'components/list/colorBadge';
import List from 'components/list/List';
import * as React from 'react';
import {
  Datagrid, DateField, FunctionField, ReferenceField,
  TextField,
} from 'react-admin';
import { CustomBulkExport } from 'resources/purchasing/purchaseOrder/list';
import { Source } from 'utils/source';
import styles from '../stockMoves.module.scss';
import filters from './filters';

function BulkActions() {
  return (
    <>
      <DefaultBulkActions />
      <CustomBulkExport
        action={urls.StockMoves_PdfExport}
        multiple
      />
      <CustomBulkExport
        action={urls.StockMoves_ExcelExport}
        icon={<GridOnIcon />}
      />
    </>
  );
}

export default React.memo(() => {
  const s = Source<StockMoveList>();
  return (
    <List
      filters={filters}
      filterDefaultValues={{ moveType: StockMovesSearchMoveTypeEnum.Manual, isOpen: true }}
      sort={{ field: 'appliedDate', order: 'DESC' }}
    >
      <Datagrid
        bulkActionButtons={<BulkActions />}
        className={styles.smTable}
        rowClick="show"
      >
        <FunctionField
          render={(record: StockMoveList) => {
            const isApplied = !!record.appliedDate;

            console.log(record.appliedDate, isApplied);
            return (
              <Badges>
                <StatusBadge green={isApplied} orange={!isApplied} />
              </Badges>
            );
          }}
          label="State"
        />
        <FunctionField
          label="comment"
          render={(record: StockMoveList) => (
            <span>
              {record.comment}
              {record.shipmentName ? `shipment: ${record.shipmentName}` : ''}
            </span>
          )}
        />
        <ReferenceField {...s('sourceStockLocationId')} reference="stock/stocklocations">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField {...s('destinationStockLocationId')} reference="stock/stocklocations">
          <TextField source="name" />
        </ReferenceField>
        <DateField {...s('appliedDate')} showTime />
        <DateField {...s('plannedDate')} showTime />
        <TextField {...s('recipient')} />
        <TextField {...s('applier')} />
        <ActionButtons url={urls.StockMoves_GetDetail.url} label="actions" />
      </Datagrid>
    </List>
  );
});
