import ReceiptIcon from '@mui/icons-material/Receipt';
import { Route } from 'react-router';
import { Authenticated } from 'react-admin';
import React from 'react';
import inventoryControlCreate from './inventoryControlCreate';
import inventoryControlEdit from './inventoryControlEdit';
import inventoryControlList from './inventoryControlList';
import inventoryControlShow from './inventoryControlShow';
import InventoryControlConfirmList from './lines/inventoryControlConfirmList';

export default {
  name: 'stock/inventory-controls',
  icon: ReceiptIcon,
  list: inventoryControlList,
  create: inventoryControlCreate,
  edit: inventoryControlEdit,
  show: inventoryControlShow,
  options: {
    label: 'resources.stock/inventoryControl.name',
  },
  children: [
    <Route
      path=":id/lines"
      element={<Authenticated><InventoryControlConfirmList /></Authenticated>}
    />,
  ],
};
