/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderCreate
 */
export interface PurchasingPurchaseOrderCreate {
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderCreate
     */
    deliveryAddress: PurchasingPurchaseOrderCreateDeliveryAddressEnum;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderCreate
     */
    businessPartnerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderCreate
     */
    plannedDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderCreate
     */
    prefix: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderCreate
     */
    comment?: string | null;
}


/**
 * @export
 */
export const PurchasingPurchaseOrderCreateDeliveryAddressEnum = {
    Andat: 'Andat',
    France: 'France',
    Swiss: 'Swiss'
} as const;
export type PurchasingPurchaseOrderCreateDeliveryAddressEnum = typeof PurchasingPurchaseOrderCreateDeliveryAddressEnum[keyof typeof PurchasingPurchaseOrderCreateDeliveryAddressEnum];


/**
 * Check if a given object implements the PurchasingPurchaseOrderCreate interface.
 */
export function instanceOfPurchasingPurchaseOrderCreate(value: object): value is PurchasingPurchaseOrderCreate {
    if (!('deliveryAddress' in value) || value['deliveryAddress'] === undefined) return false;
    if (!('businessPartnerId' in value) || value['businessPartnerId'] === undefined) return false;
    if (!('plannedDate' in value) || value['plannedDate'] === undefined) return false;
    if (!('prefix' in value) || value['prefix'] === undefined) return false;
    return true;
}

export function PurchasingPurchaseOrderCreateFromJSON(json: any): PurchasingPurchaseOrderCreate {
    return PurchasingPurchaseOrderCreateFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderCreate {
    if (json == null) {
        return json;
    }
    return {
        
        'deliveryAddress': json['deliveryAddress'],
        'businessPartnerId': json['businessPartnerId'],
        'plannedDate': (new Date(json['plannedDate'])),
        'prefix': json['prefix'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function PurchasingPurchaseOrderCreateToJSON(value?: PurchasingPurchaseOrderCreate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deliveryAddress': value['deliveryAddress'],
        'businessPartnerId': value['businessPartnerId'],
        'plannedDate': ((value['plannedDate']).toISOString()),
        'prefix': value['prefix'],
        'comment': value['comment'],
    };
}

