/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockLinesShelf } from './StockLinesShelf';
import {
    StockLinesShelfFromJSON,
    StockLinesShelfFromJSONTyped,
    StockLinesShelfToJSON,
} from './StockLinesShelf';

/**
 * 
 * @export
 * @interface StockLinesResourceDetail
 */
export interface StockLinesResourceDetail {
    /**
     * 
     * @type {number}
     * @memberof StockLinesResourceDetail
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    articleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StockLinesResourceDetail
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof StockLinesResourceDetail
     */
    quantityTypeName: string;
    /**
     * 
     * @type {Array<StockLinesShelf>}
     * @memberof StockLinesResourceDetail
     */
    shelfs: Array<StockLinesShelf>;
}

/**
 * Check if a given object implements the StockLinesResourceDetail interface.
 */
export function instanceOfStockLinesResourceDetail(value: object): value is StockLinesResourceDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('quantityTypeName' in value) || value['quantityTypeName'] === undefined) return false;
    if (!('shelfs' in value) || value['shelfs'] === undefined) return false;
    return true;
}

export function StockLinesResourceDetailFromJSON(json: any): StockLinesResourceDetail {
    return StockLinesResourceDetailFromJSONTyped(json, false);
}

export function StockLinesResourceDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockLinesResourceDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'name': json['name'],
        'quantityTypeId': json['quantityTypeId'],
        'quantityTypeName': json['quantityTypeName'],
        'shelfs': ((json['shelfs'] as Array<any>).map(StockLinesShelfFromJSON)),
    };
}

export function StockLinesResourceDetailToJSON(value?: StockLinesResourceDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'articleNo': value['articleNo'],
        'name': value['name'],
        'quantityTypeId': value['quantityTypeId'],
        'quantityTypeName': value['quantityTypeName'],
        'shelfs': ((value['shelfs'] as Array<any>).map(StockLinesShelfToJSON)),
    };
}

