/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockLinesLine } from './StockLinesLine';
import {
    StockLinesLineFromJSON,
    StockLinesLineFromJSONTyped,
    StockLinesLineToJSON,
} from './StockLinesLine';
import type { ProductBillOfResourcesDetailDTO } from './ProductBillOfResourcesDetailDTO';
import {
    ProductBillOfResourcesDetailDTOFromJSON,
    ProductBillOfResourcesDetailDTOFromJSONTyped,
    ProductBillOfResourcesDetailDTOToJSON,
} from './ProductBillOfResourcesDetailDTO';

/**
 * 
 * @export
 * @interface ProductDetailSchema
 */
export interface ProductDetailSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    stockTracing: ProductDetailSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductDetailSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    quantityTypeName: string;
    /**
     * 
     * @type {Array<StockLinesLine>}
     * @memberof ProductDetailSchema
     */
    stockLines: Array<StockLinesLine>;
    /**
     * 
     * @type {ProductBillOfResourcesDetailDTO}
     * @memberof ProductDetailSchema
     */
    billOfResources?: ProductBillOfResourcesDetailDTO;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    hsCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailSchema
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    size?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailSchema
     */
    isReadyToSell: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailSchema
     */
    productionNumberSuffix?: string | null;
}


/**
 * @export
 */
export const ProductDetailSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductDetailSchemaStockTracingEnum = typeof ProductDetailSchemaStockTracingEnum[keyof typeof ProductDetailSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductDetailSchema interface.
 */
export function instanceOfProductDetailSchema(value: object): value is ProductDetailSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('quantityTypeName' in value) || value['quantityTypeName'] === undefined) return false;
    if (!('stockLines' in value) || value['stockLines'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    if (!('isReadyToSell' in value) || value['isReadyToSell'] === undefined) return false;
    return true;
}

export function ProductDetailSchemaFromJSON(json: any): ProductDetailSchema {
    return ProductDetailSchemaFromJSONTyped(json, false);
}

export function ProductDetailSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDetailSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'id': json['id'],
        'quantityTypeName': json['quantityTypeName'],
        'stockLines': ((json['stockLines'] as Array<any>).map(StockLinesLineFromJSON)),
        'billOfResources': json['billOfResources'] == null ? undefined : ProductBillOfResourcesDetailDTOFromJSON(json['billOfResources']),
        'hsCode': json['hsCode'] == null ? undefined : json['hsCode'],
        'weight': json['weight'],
        'size': json['size'] == null ? undefined : json['size'],
        'color': json['color'] == null ? undefined : json['color'],
        'isReadyToSell': json['isReadyToSell'],
        'productionNumberSuffix': json['productionNumberSuffix'] == null ? undefined : json['productionNumberSuffix'],
    };
}

export function ProductDetailSchemaToJSON(value?: ProductDetailSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'id': value['id'],
        'quantityTypeName': value['quantityTypeName'],
        'stockLines': ((value['stockLines'] as Array<any>).map(StockLinesLineToJSON)),
        'billOfResources': ProductBillOfResourcesDetailDTOToJSON(value['billOfResources']),
        'hsCode': value['hsCode'],
        'weight': value['weight'],
        'size': value['size'],
        'color': value['color'],
        'isReadyToSell': value['isReadyToSell'],
        'productionNumberSuffix': value['productionNumberSuffix'],
    };
}

