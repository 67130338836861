import { TableRow as MuiTableRow, TableRowProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import styles from './table.module.scss';

interface MyTableRowProps extends TableRowProps {
  clickable?: boolean;
}

export function TableRow(props: MyTableRowProps) {
  const {
    clickable,
    ...rest
  } = props;

  return (
    <MuiTableRow {...rest} className={clsx(clickable && styles.table_row)} />
  );
}
