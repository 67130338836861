import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CategoryIcon from '@mui/icons-material/Category';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FactoryIcon from '@mui/icons-material/Factory';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import GroupsIcon from '@mui/icons-material/Groups';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import PersonIcon from '@mui/icons-material/Person';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Endpoint } from 'app/urls';
import * as React from 'react';
import { TranslationKey } from 'utils/translate';

export interface MenuType {
  name: TranslationKey;
  icon: React.ReactElement;
  permissionEndpoint?: Endpoint;
}

export interface ParentMenu extends MenuType {
  url?: string;
  isOpen: boolean;
  children: ChildMenu[];
}

export interface ChildMenu extends MenuType {
  url: string;
}

const menus: ParentMenu[] = [
  {
    url: '/',
    name: 'mai.menu.dashboard',
    icon: <DashboardIcon />,
    isOpen: false,
    children: [],
  },
  {
    name: 'mai.menu.parties',
    icon: <GroupsIcon />,
    isOpen: false,
    children: [
      {
        url: '/auth/users',
        name: 'mai.menu.users',
        icon: <PersonIcon />,
      },
      {
        url: '/auth/groups',
        name: 'mai.menu.groups',
        icon: <GroupsIcon />,
      },
      {
        url: '/business-partner/business-partners',
        name: 'mai.menu.business_partners',
        icon: <BusinessCenterIcon />,
      },
    ],
  },
  {
    name: 'mai.menu.products',
    icon: <AccountTreeIcon />,
    isOpen: false,
    children: [
      {
        url: '/product/categories',
        name: 'mai.menu.categories',
        icon: <AccountTreeIcon />,
      },
      {
        url: '/product/raw-materials',
        name: 'mai.menu.raw_materials',
        icon: <FormatColorFillIcon />,
      },
      {
        url: '/product/products',
        name: 'mai.menu.products',
        icon: <CategoryIcon />,
      },
    ],
  },
  {
    name: 'mai.menu.stock',
    icon: <WarehouseIcon />,
    isOpen: false,
    children: [
      {
        url: '/stock/stock',
        name: 'mai.menu.stock',
        icon: <WarehouseIcon />,
      },
      {
        url: '/stock/stock-split',
        name: 'mai.menu.stocksplit',
        icon: <CallSplitIcon />,
      },
      {
        url: '/stock/stock-moves',
        name: 'mai.menu.moves',
        icon: <MoveDownIcon />,
      },
      {
        url: '/stock/inventory-controls',
        name: 'mai.menu.inventory_controls',
        icon: <FactCheckIcon />,
      },
      {
        url: '/stock/shelf',
        name: 'mai.menu.shelf',
        icon: <FactCheckIcon />,
      },
    ],
  },
  {
    name: 'mai.menu.purchasing',
    icon: <AddShoppingCartIcon />,
    isOpen: false,
    children: [
      {
        url: '/purchasing/purchase-order',
        name: 'mai.menu.purchase_order',
        icon: <AddShoppingCartIcon />,
      },
      {
        url: '/stock/incoming-shipments',
        name: 'mai.menu.incoming_shipments',
        icon: <FlightLandIcon />,
      },
    ],
  },
  {
    name: 'mai.menu.production',
    icon: <FactoryIcon />,
    isOpen: false,
    children: [

      {
        url: '/manufacturing/production-items',
        name: 'mai.menu.production_items',
        icon: <ChecklistIcon />,
      },
    ],
  },
];

export default menus;
