/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockReceiveShipmentLine
 */
export interface StockReceiveShipmentLine {
    /**
     * 
     * @type {number}
     * @memberof StockReceiveShipmentLine
     */
    rawMaterialId: number;
    /**
     * 
     * @type {string}
     * @memberof StockReceiveShipmentLine
     */
    batchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockReceiveShipmentLine
     */
    amount: number;
}

/**
 * Check if a given object implements the StockReceiveShipmentLine interface.
 */
export function instanceOfStockReceiveShipmentLine(value: object): value is StockReceiveShipmentLine {
    if (!('rawMaterialId' in value) || value['rawMaterialId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function StockReceiveShipmentLineFromJSON(json: any): StockReceiveShipmentLine {
    return StockReceiveShipmentLineFromJSONTyped(json, false);
}

export function StockReceiveShipmentLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockReceiveShipmentLine {
    if (json == null) {
        return json;
    }
    return {
        
        'rawMaterialId': json['rawMaterialId'],
        'batchName': json['batchName'] == null ? undefined : json['batchName'],
        'amount': json['amount'],
    };
}

export function StockReceiveShipmentLineToJSON(value?: StockReceiveShipmentLine | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rawMaterialId': value['rawMaterialId'],
        'batchName': value['batchName'],
        'amount': value['amount'],
    };
}

