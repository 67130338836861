import React from 'react';
import { renderToString } from 'react-dom/server';
import Constants from './constants';

interface PageSize {
  width: string;
  height: string;
}

const show = false;

const exportPDF = (components: React.ReactElement, pageSize?: PageSize): void => {
  const renderSize = pageSize === undefined ? Constants.SizePrint.k58 : pageSize;

  if (document.getElementById('printElement')) {
    document.getElementById('printElement')?.remove();
  }
  if (document.getElementById('printFrame')) {
    document.getElementById('printFrame')?.remove();
  }

  const iframe = document.createElement('iframe');

  iframe.id = 'printFrame';
  iframe.style.display = 'none';

  document.body.appendChild(iframe);

  if (show) {
    const printElement = document.createElement('div');
    printElement.id = 'printElement';
    document.body.appendChild(printElement);
    const printDiv = (
      <div
        style={{
          position: 'fixed',
          zIndex: 1000,
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          border: '1px solid black',
        }}
      >
        {components}
      </div>
    );
    printElement.innerHTML = renderToString(printDiv);
  }

  const styles = Array.from(document
    .querySelectorAll<HTMLLinkElement>('head link[rel=stylesheet]'))
    .map((styleLink) => {
      const url = new URL(styleLink.href, document.baseURI).toString();
      return url === undefined ? null : <link rel="stylesheet" href={url} />;
    });

  const placeholderImage = styles.length > 0 ? null : (
    <img
      src=""
      style={{
        width: 0,
        height: 0,
        float: 'left',
        zIndex: -999,
      }}
      alt="onLoadImg"
    />
  );

  const iframeContent = (
    <html lang="en">
      <head>
        <title>PDF Iframe</title>
        {styles}
        <style>
          {`
          @media print {
            body {
              min-width: unset!important;
              -webkit-print-color-adjust: exact!important;
              color-adjust: exact!important;
            }
            .paper {
              page-break-inside: avoid;
            }
            .break-inside - avoid {
              page-break-inside: avoid;
            }
            .break-after - always {
              page-break-after: always;
            }
            @page {
              size: ${renderSize.width} ${renderSize.height};
              margin: 0;
            }
          }
          `}
        </style>
      </head>
      <body style={{ margin: '0 !important', padding: '0 !important', color: 'black !important' }}>
        <div style={{ width: '100%' }}>
          {placeholderImage}
          {components}
        </div>
      </body>
    </html>
  );

  const imagePDF = renderToString(iframeContent);

  if (iframe.contentWindow) {
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(imagePDF);
    iframe.contentWindow.document.close();

    // the img tag triggers onload
    iframe.onload = () => {
      console.log('load');
      if (iframe.contentWindow) {
        if (iframe.contentWindow.parent) {
          document.querySelectorAll('head style').forEach((styleSheet) => {
            iframe.contentWindow?.document.head.appendChild(
              styleSheet.cloneNode(true),
            );
          });
        }

        iframe.contentWindow.addEventListener('afterprint', () => {
          // afterPrint();
        });

        // const pdfBackup = iframe.contentWindow.parent.document.title;
        iframe.contentWindow.document.title = 'document.pdf';

        if (!show) {
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
          setTimeout(() => {
            // remove iframe
            if (iframe.contentWindow) {
              // iframe.contentWindow.parent.document.title = pdfBackup;
              document.getElementById('printElement')?.remove();
              document.getElementById('printFrame')?.remove();
            }
          }, 0);
        }
      }
    };
  }
};

export default exportPDF;
