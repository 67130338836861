import englishMessages from 'ra-language-english';
import mai from './en/mai';
import resources from './en/resources';

const messages = {
  ...englishMessages,
  mai,
  resources,
};

export default messages;
