import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import EditToolBar from '../../components/commons/editToolBar';
import useHasAccess from '../../hooks/useHasAccess';
import { useCountries } from '../../repositories/app';
import BusinessPartnerForm from './form';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'business-partner/business-partners/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'business-partner/business-partners/{id}', httpMethod: 'DELETE' });
  const { data: countries } = useCountries();

  if (!countries) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      className="mai-form"
      actions={<EditActions />}
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} isDelete={canDelete} />}>
        <BusinessPartnerForm />
      </SimpleForm>
    </Edit>
  );
});
