/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductCategorySchema
 */
export interface ProductCategorySchema {
    /**
     * 
     * @type {Date}
     * @memberof ProductCategorySchema
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductCategorySchema
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    parentId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductCategorySchema
     */
    readonly fullName: string;
    /**
     * 
     * @type {number}
     * @memberof ProductCategorySchema
     */
    readonly position: number;
}

/**
 * Check if a given object implements the ProductCategorySchema interface.
 */
export function instanceOfProductCategorySchema(value: object): value is ProductCategorySchema {
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('fullName' in value) || value['fullName'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    return true;
}

export function ProductCategorySchemaFromJSON(json: any): ProductCategorySchema {
    return ProductCategorySchemaFromJSONTyped(json, false);
}

export function ProductCategorySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategorySchema {
    if (json == null) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
        'id': json['id'],
        'name': json['name'],
        'color': json['color'] == null ? undefined : json['color'],
        'description': json['description'] == null ? undefined : json['description'],
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
        'fullName': json['fullName'],
        'position': json['position'],
    };
}

export function ProductCategorySchemaToJSON(value?: Omit<ProductCategorySchema, 'fullName'|'position'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
        'id': value['id'],
        'name': value['name'],
        'color': value['color'],
        'description': value['description'],
        'parentId': value['parentId'],
    };
}

