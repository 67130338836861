/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBillOfResourcesDTO } from './ProductBillOfResourcesDTO';
import {
    ProductBillOfResourcesDTOFromJSON,
    ProductBillOfResourcesDTOFromJSONTyped,
    ProductBillOfResourcesDTOToJSON,
} from './ProductBillOfResourcesDTO';

/**
 * 
 * @export
 * @interface ProductUpdateSchema
 */
export interface ProductUpdateSchema {
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateSchema
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateSchema
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    stockTracing: ProductUpdateSchemaStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductUpdateSchema
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    size?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    color?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdateSchema
     */
    isReadyToSell: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    id: number;
    /**
     * 
     * @type {ProductBillOfResourcesDTO}
     * @memberof ProductUpdateSchema
     */
    billOfResources?: ProductBillOfResourcesDTO;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    hsCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdateSchema
     */
    weight: number;
    /**
     * 
     * @type {string}
     * @memberof ProductUpdateSchema
     */
    productionNumberSuffix?: string | null;
}


/**
 * @export
 */
export const ProductUpdateSchemaStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductUpdateSchemaStockTracingEnum = typeof ProductUpdateSchemaStockTracingEnum[keyof typeof ProductUpdateSchemaStockTracingEnum];


/**
 * Check if a given object implements the ProductUpdateSchema interface.
 */
export function instanceOfProductUpdateSchema(value: object): value is ProductUpdateSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('isReadyToSell' in value) || value['isReadyToSell'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('weight' in value) || value['weight'] === undefined) return false;
    return true;
}

export function ProductUpdateSchemaFromJSON(json: any): ProductUpdateSchema {
    return ProductUpdateSchemaFromJSONTyped(json, false);
}

export function ProductUpdateSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductUpdateSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'size': json['size'] == null ? undefined : json['size'],
        'color': json['color'] == null ? undefined : json['color'],
        'isReadyToSell': json['isReadyToSell'],
        'id': json['id'],
        'billOfResources': json['billOfResources'] == null ? undefined : ProductBillOfResourcesDTOFromJSON(json['billOfResources']),
        'hsCode': json['hsCode'] == null ? undefined : json['hsCode'],
        'weight': json['weight'],
        'productionNumberSuffix': json['productionNumberSuffix'] == null ? undefined : json['productionNumberSuffix'],
    };
}

export function ProductUpdateSchemaToJSON(value?: ProductUpdateSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'size': value['size'],
        'color': value['color'],
        'isReadyToSell': value['isReadyToSell'],
        'id': value['id'],
        'billOfResources': ProductBillOfResourcesDTOToJSON(value['billOfResources']),
        'hsCode': value['hsCode'],
        'weight': value['weight'],
        'productionNumberSuffix': value['productionNumberSuffix'],
    };
}

