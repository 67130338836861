import { StockShelfSchema } from 'api';
import { ImagePreview } from 'components/fields/imagePreview';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import { TextField } from 'react-admin';
import { Source } from 'utils/source';
import filters from './filters';

export default function () {
  // const s = Source<StockInventoryControlList>();
  const s = Source<StockShelfSchema>();
  return (
    <List
      filters={filters}
    >
      <Datagrid rowClick="show">
        <TextField {...s('name')} />
        <ImagePreview
          {...s('image')}
          label="resources.product/raw-materials.fields.image"
          sortable={false}
        />
        <TextField {...s('stockLocationName') || ''} />
        <TextField {...s('comment') || ''} />
      </Datagrid>
    </List>
  );
}
