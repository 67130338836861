import { Grid } from '@mui/material';
import { PurchasingPurchaseOrderEdit } from 'api';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import BusinessPartnerInput from 'components/input/reference/businessPartner';
import React from 'react';
import {
  Edit, SimpleForm,
} from 'react-admin';
import { Source } from 'utils/source';
import CustomToolBar from 'components/commons/editToolBar';
import EnumSelectField from 'components/input/enumSelectField';
import { PurchaseOrderLines } from './form/line';
import { deliveryAddresses } from './enums';

export default function PurchaseOrderEdit() {
  const s = Source<PurchasingPurchaseOrderEdit>();

  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm toolbar={<CustomToolBar />}>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={2} md={2} />

          <Grid item xs={4} md={4}>
            <BusinessPartnerInput {...s('businessPartnerId')} required />
          </Grid>
          <Grid item xs={4} md={4}>
            <EnumSelectField
              {...s('deliveryAddress')}
              choices={deliveryAddresses}
              fullWidth
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={2} />

          <Grid item xs={2} md={2} />
          <Grid item xs={4} md={4}>
            <CustomDatePicker
              {...s('plannedDate')}
              required
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <CustomDatePicker
              {...s('confirmationDate')}
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={2} md={2} />

          <Grid item xs={2} md={2} />
          <Grid item md={8}>
            <PurchaseOrderLines {...s('lines')} supplierIdSource={s('businessPartnerId').source} />
          </Grid>
          <Grid item xs={2} md={2} />
        </Grid>
      </SimpleForm>
    </Edit>
  );
}
