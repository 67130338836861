import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useMutation, useQuery } from 'react-query';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import repository from 'repositories';
import { FileDTO } from 'api';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './FileView.module.scss';
import { fileUrl } from './FileView';

interface FileInputProps {
  endpoint: string,
  accept?: Accept,
  showPreview? :boolean,
}

interface FileLineProps {
  file: FileDTO,
  showPreview? :boolean,
  url: string;
  onDelete?: (id: number) => void
}

function FileLine(props: FileLineProps) {
  const {
    file,
    showPreview,
    url,
    onDelete,
  } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div className="file" key={file.id}>
      <div className={styles.wrapPreview}>
        <div className={styles.wrapPreview}>
          {
            showPreview && (
              <div className={styles.preview}>
                <img src={url} alt="" />
              </div>
            )
          }
          <p>{file.fileName}</p>
        </div>
      </div>
      <div>
        <Button href={url} target="_blank"><DownloadIcon /></Button>
        <Button onClick={() => setOpen(true)}><DeleteIcon color="warning" /></Button>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
        >
          <DialogTitle>
            Delete File?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete the file&nbsp;
              <b>
                &quot;
                {file.fileName}
                &quot;
              </b>
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              autoFocus
              disabled={!onDelete}
              onClick={() => {
                setOpen(false);
                if (file.id) onDelete?.(file.id);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default function FileInput(props: FileInputProps) {
  const {
    endpoint,
    accept,
  } = props;

  const { data: files, refetch } = useQuery([endpoint], async () => {
    const data = await repository.get<FileDTO[]>(endpoint);
    return data.data;
  }, { staleTime: 0 });

  const upload = useMutation([endpoint, 'update'], async (selectedFile: File) => {
    const formData = new FormData();
    formData.append('file', selectedFile);
    const result = await repository.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  });

  const deleteFile = useMutation([endpoint], async (params: { id: string }) => {
    const { id } = params;
    const result = await repository.delete(`${endpoint}/${id}`);

    return result.data;
  });

  const onDrop = async (newFiles: File[]) => {
    await Promise.all(newFiles.map((file) => upload.mutateAsync(file)));

    await refetch({ cancelRefetch: true });
  };

  const onDelete = async (id?: number) => {
    if (id === undefined) {
      return;
    }
    await deleteFile.mutateAsync({ id: id.toString() });
    await refetch();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className={styles.fileinput}>
      <div
        {...getRootProps({
          className: 'dropzone',
          'data-testid': 'dropzone',
        })}
      >
        <p>Drop files here...</p>
        <input
          {...getInputProps()}
        />
      </div>
      <div className={styles.files}>
        {files?.map((file) => (
          <FileLine
            key={file.id}
            file={file}
            url={fileUrl(endpoint, file.id)}
            onDelete={onDelete}
            showPreview
          />
        ))}
      </div>
    </div>
  );
}
