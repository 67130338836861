import ReceiptIcon from '@mui/icons-material/Receipt';
import createProductionItem from './createProductionItemContainer';
import editProductionItem from './editProductionItemContainer';
import productionItemList from './list';
import showProductionItem from './showProductionItemContainer';

export default {
  name: 'manufacturing/production-items',
  icon: ReceiptIcon,
  list: productionItemList,
  create: createProductionItem,
  edit: editProductionItem,
  show: showProductionItem,
  options: {
    label: 'Production Items',
  },
};
