/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchasingPurchaseOrderLineSolo
 */
export interface PurchasingPurchaseOrderLineSolo {
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    comment?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    confirmedDeliveryDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    quantityTypeId: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    purchaseOrderName: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasingPurchaseOrderLineSolo
     */
    purchaseOrderId: number;
}

/**
 * Check if a given object implements the PurchasingPurchaseOrderLineSolo interface.
 */
export function instanceOfPurchasingPurchaseOrderLineSolo(value: object): value is PurchasingPurchaseOrderLineSolo {
    if (!('rawMaterialId' in value) || value['rawMaterialId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('purchaseOrderName' in value) || value['purchaseOrderName'] === undefined) return false;
    if (!('purchaseOrderId' in value) || value['purchaseOrderId'] === undefined) return false;
    return true;
}

export function PurchasingPurchaseOrderLineSoloFromJSON(json: any): PurchasingPurchaseOrderLineSolo {
    return PurchasingPurchaseOrderLineSoloFromJSONTyped(json, false);
}

export function PurchasingPurchaseOrderLineSoloFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchasingPurchaseOrderLineSolo {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'confirmedDeliveryDate': json['confirmedDeliveryDate'] == null ? undefined : (new Date(json['confirmedDeliveryDate'])),
        'quantityTypeId': json['quantityTypeId'],
        'purchaseOrderName': json['purchaseOrderName'],
        'purchaseOrderId': json['purchaseOrderId'],
    };
}

export function PurchasingPurchaseOrderLineSoloToJSON(value?: PurchasingPurchaseOrderLineSolo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'confirmedDeliveryDate': value['confirmedDeliveryDate'] == null ? undefined : ((value['confirmedDeliveryDate'] as any).toISOString()),
        'quantityTypeId': value['quantityTypeId'],
        'purchaseOrderName': value['purchaseOrderName'],
        'purchaseOrderId': value['purchaseOrderId'],
    };
}

