import React from 'react';
import messages from 'i18n/en';
import { SelectInput, SelectInputProps } from 'react-admin';
import { Path } from 'react-hook-form';
import { Option } from 'utils/enumToOptions';
import useTranslate from 'utils/translate';

export interface EnumSelectFieldProps<EnumType extends Record<string, string>> extends SelectInputProps {
  label?: Path<typeof messages>,
  choices: Option<EnumType>[],
}

export default function EnumSelectField<EnumType extends Record<string, string>>(
  props: EnumSelectFieldProps<EnumType>,
) {
  const {
    source,
    label,
    choices,
    ...rest
  } = props;
  const translate = useTranslate();
  return (
    <SelectInput
      {...rest}
      source={source}
      label={label ? translate(label) : undefined}
      choices={choices ?? []}
      optionText={(choice: typeof props.choices[number]) => translate(choice.label)}
      variant="outlined"
    />
  );
}
