import * as React from 'react';
import {
  TextField,
} from 'react-admin';

import { BusinessPartnerSchema } from 'api/models/BusinessPartnerSchema';
import urls from 'app/urls';
import List from 'components/list/List';
import { ActionButtons } from 'components/list/actionButtons';
import Datagrid from 'components/list/dataGrid';
import { Source } from 'utils/source';
import ListActions from '../../components/layout/actions/list';
import styles from './businessPartner.module.scss';
import filters from './filters';

export default React.memo(() => {
  const s = Source<BusinessPartnerSchema>();
  return (
    <List
      filters={filters}
      actions={<ListActions exporter />}
    >
      <Datagrid className={styles.bp_table}>
        <TextField {...s('name')} />
        <TextField {...s('phone')} />
        <TextField {...s('taxId')} />
        <TextField {...s('address.countryId')} sortBy="country" />
        <TextField {...s('address.zipcode')} sortBy="zipcode" />
        <TextField {...s('address.city')} sortBy="city" />
        <TextField {...s('address.state')} sortBy="state" />
        <ActionButtons url={urls.BusinessPartners_Get.url} label="action" />
      </Datagrid>
    </List>
  );
});
