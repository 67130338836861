import ReceiptIcon from '@mui/icons-material/Receipt';
import createShelf from './createShelfContainer';
import shelfList from './list';
import showShelf from './show';
import editShelf from './editShelfContainer';

export default {
  name: 'stock/shelf',
  icon: ReceiptIcon,
  list: shelfList,
  create: createShelf,
  edit: editShelf,
  show: showShelf,
  options: {
    label: 'resources.stock/stockLocations/shelf.name',
  },
};
