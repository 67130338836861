import * as React from 'react';
import {
  BooleanField,
  Show, useRecordContext,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import ShowDate from 'components/commons/showDate';
import ShowBooleanField from 'components/commons/showBooleanField';
import { AuthUserDetailEntity } from 'api';
import { Source } from 'utils/source';
import EnumField from 'components/fields/enumField';
import Genders from './enums';

function FullNameFieldCustom(props: any) {
  const { className } = props;
  const record = useRecordContext<AuthUserDetailEntity>();

  if (record) {
    return (
      <Typography
        className={className}
        variant="body2"
        display="content"
        textAlign="left"
      >
        {record.information?.firstname}
        {' '}
        {record.information?.lastname}
      </Typography>
    );
  }
  return null;
}
export default React.memo(() => {
  const s = Source<AuthUserDetailEntity>();

  return (
    <Show actions={<ShowActions />}>
      <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
        <Grid item xs={12} md={12}>
          <ShowTitle />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
          <ShowLabel label="resources.auth/users.fields.fullname" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <FullNameFieldCustom className="highLightField" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.username" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('username')} className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.email" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('email')} className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.phone" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('information.phone')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.birthday" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate {...s('information.birthday')} showTime={false} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.gender" />
        </Grid>
        <Grid item xs={8} md={9}>
          <EnumField {...s('information.gender')} choices={Genders} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.address" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('information.address')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.lastLogin" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate {...s('lastLogin')} showTime />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.lastLogout" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowDate {...s('lastLogout')} showTime />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.isActive" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowBooleanField {...s('isActive')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.isSpecialAccess" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowBooleanField {...s('isSpecialAccess')} />
        </Grid>
        {/* <Grid className="show-row-odd" item xs={2} md={1.5} />
          <Grid item xs={2} md={1.5}>
            <ShowLabel label="resources.auth/users.fields.groups" />
          </Grid>
          <Grid className="show-row-odd" item xs={8} md={9}>
            <GroupsFieldCustom />
          </Grid> */}
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.status" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <BooleanField {...s('isActive')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/users.fields.note" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('information.note')} />
        </Grid>
        <Grid item xs={2} md={1.5} />
      </Grid>
    </Show>
  );
});
