/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductBillOfResourcesDTO } from './ProductBillOfResourcesDTO';
import {
    ProductBillOfResourcesDTOFromJSON,
    ProductBillOfResourcesDTOFromJSONTyped,
    ProductBillOfResourcesDTOToJSON,
} from './ProductBillOfResourcesDTO';

/**
 * 
 * @export
 * @interface StockMoveCreateEntity
 */
export interface StockMoveCreateEntity {
    /**
     * 
     * @type {Date}
     * @memberof StockMoveCreateEntity
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveCreateEntity
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveCreateEntity
     */
    sourceStockLocationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveCreateEntity
     */
    destinationStockLocationId?: number | null;
    /**
     * 
     * @type {ProductBillOfResourcesDTO}
     * @memberof StockMoveCreateEntity
     */
    billOfResources?: ProductBillOfResourcesDTO;
}

/**
 * Check if a given object implements the StockMoveCreateEntity interface.
 */
export function instanceOfStockMoveCreateEntity(value: object): value is StockMoveCreateEntity {
    return true;
}

export function StockMoveCreateEntityFromJSON(json: any): StockMoveCreateEntity {
    return StockMoveCreateEntityFromJSONTyped(json, false);
}

export function StockMoveCreateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveCreateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'sourceStockLocationId': json['sourceStockLocationId'] == null ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': json['destinationStockLocationId'] == null ? undefined : json['destinationStockLocationId'],
        'billOfResources': json['billOfResources'] == null ? undefined : ProductBillOfResourcesDTOFromJSON(json['billOfResources']),
    };
}

export function StockMoveCreateEntityToJSON(value?: StockMoveCreateEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'comment': value['comment'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
        'billOfResources': ProductBillOfResourcesDTOToJSON(value['billOfResources']),
    };
}

