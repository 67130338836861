import React from 'react';
import { Edit } from 'react-admin';
import EditActions from 'components/layout/actions/edit';
import ShelfForm from './form/ShelfForm';

export default function EditShelfContainer() {
  return (
    <Edit
      redirect="show"
      actions={<EditActions />}
    >
      <ShelfForm />
    </Edit>
  );
}
