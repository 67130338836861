/* eslint-disable max-len */
import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Create,
  ListButton,
  TopToolbar,
} from 'react-admin';

import RawMaterialForm from './rawMaterialForm';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canSearch = hasAccess({ url: 'product/raw-materials/', httpMethod: 'GET' });

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <RawMaterialForm />
    </Create>
  );
});
