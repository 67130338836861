/* AUTOGENERATED, DO NOT EDIT! */
/* eslint-disable */
const translations = {
  "auth/groups": {
    "fields": {
      "description": "Mô tả về nhóm",
      "id": "Khóa",
      "name": "Tên nhóm",
      "typeName": "Loại"
    },
    "name": "Nhóm tài khoản |||| Nhóm tài khoản"
  },
  "auth/users": {
    "fields": {
      "Group": "Nhóm tài khoản",
      "Phone": "Số điện thoại",
      "Query": "Tìm kiếm",
      "address": "Địa chỉ",
      "birthday": "Ngày sinh",
      "confirmPassword": "Nhập lại mật khẩu",
      "email": "Email",
      "firstname": "Họ",
      "fullname": "Tên đầy đủ",
      "gender": "Giới tính",
      "groupIds": "Nhóm tài khoản",
      "groups": "Nhóm tài khoản",
      "isActive": "Kích hoạt TK",
      "isSpecialAccess": "",
      "lastLogin": "Đăng nhập lần cuối",
      "lastLogout": "Đăng xuất lần cuối",
      "lastname": "Họ",
      "note": "Ghi chú",
      "password": "Mật khẩu",
      "phone": "Số điện thoại",
      "status": "Trạng thái",
      "username": "Tên đăng nhập"
    },
    "name": "Người dùng"
  },
  "billOfResources": {
    "fields": {
      "amount": "Số lượng",
      "comment": "Bình luận",
      "dependencies": "Các khối lượng công việc phụ thuộc",
      "description": "Mô tả về khối lượng công việc",
      "detail": "thông tin khối lượng công việc",
      "id": "Id",
      "lines": "Các dòng tài nguyên",
      "name": "Tên khối lượng công việc",
      "products": "Danh sách sản phẩm",
      "resourceName": "Tên tài nguyên",
      "resources": "Tài nguyên"
    },
    "name": "Hóa đơn nguyên liệu"
  },
  "business-partner/business-partners": {
    "fields": {
      "address": "Địa chỉ",
      "address1": "Địa chỉ 1",
      "address2": "Địa chỉ 2",
      "addressName": "Tên địa chỉ",
      "city": "Thành phố",
      "countryId": "Quốc gia",
      "countryName": "Quốc gia",
      "name": "Tên",
      "phone": "Điện thoại",
      "state": "Tiểu bang",
      "taxId": "Mã số thuế",
      "zipcode": "Mã bưu điện"
    },
    "name": "Đối tác kinh doanh"
  },
  "product/categories": {
    "fields": {
      "color": "Màu sắc",
      "description": "Mô tả",
      "fullName": "Tên đầy đủ",
      "name": "Tên",
      "parentId": "Cha",
      "position": "Vị trí",
      "query": "Tìm kiếm"
    },
    "name": "Loại sản phẩm"
  },
  "product/productionItems": {
    "fields": {
      "amount": "Số lượng",
      "batchNumber": "Số lô",
      "comment": "Ghi chú",
      "plannedEndDate": "Ngày kết thúc dự kiến",
      "plannedExportDate": "Ngày xuất khẩu dự kiến",
      "serialNo": "Số seri"
    }
  },
  "product/products": {
    "fields": {
      "billofresources": "Hóa đơn tài nguyên",
      "categoryId": "Loại sản phẩm",
      "categoryName": "Loại sản phẩm",
      "currencyId": "Đơn vị tiền tệ",
      "currencyName": "Đơn vị tiền tệ",
      "detail": "Thông tin sản phẩm",
      "isActive": "Đang hoạt động",
      "name": "Tên",
      "price": "Giá",
      "quantityTypeId": "Loại số lượng",
      "quantityTypeName": "Loại số lượng",
      "stateCode": "Trạng thái hoạt động",
      "stockTracingCode": "Kho dự trữ",
      "stockTracingName": "Kho dự trữ"
    },
    "name": "Sản phẩm |||| Sản phẩm",
    "tabs": {
      "billofresources": "Hóa đơn nguyên vật liệu",
      "detail": "Thông tin chi tiết",
      "openOrders": "Đơn hàng mở",
      "stockLine": "Số lượng tồn kho"
    }
  },
  "product/raw-materials": {
    "fields": {
      "articleNo": "Số bài viết",
      "businessPartnerId": "Đối tác",
      "businessPartnerName": "Đối tác",
      "categoryId": "Loại nguyên liệu",
      "categoryName": "Loại",
      "currencyId": "Đơn vị tiền tệ",
      "currencyName": "Đơn vị tiền tệ",
      "decimals": "Số thập phân",
      "detail": "Thông tin nguyên liệu",
      "isActive": "Kích hoạt",
      "leadTime": "Thời gian sản xuất",
      "moq": "MOQ",
      "name": "Tên nguyên liệu",
      "price": "Giá",
      "quantityTypeId": "Loại số lượng",
      "quantityTypeName": "Loại số lượng",
      "stateCode": "Trạng thái",
      "stockConditioning": "TODO",
      "stockTracingCode": "Kho dự trữ",
      "stockTracingName": "Kho dự trữ",
      "supplierConditioning": "TODO",
      "supplierName": "Supplier",
      "unit": "Đơn vị",
      "image": "Hình ảnh",
      "hsCode": "Mã HS",
      "articleNumber": "Số bài viết",
      "weight": "Trọng Lượng",
      "size": "Kích thước",
      "color": "Màu sắc",
      "isReadyToSell": "Sẵn sàng để bán",
      "productionNumberSuffix": "Tiền tố số sản xuất"
    },
    "name": "Nguyên liệu thô|||| Nguyên liệu thô"
  },
  "purchasing": {
    "purchaseOrder": {
      "Amount": "Số lượng",
      "DoneAmount": "",
      "OpenAmount": "",
      "ConfirmedDeliveryDate": ""
    }
  },
  "stock/incomingShipment": {
    "fields": {
      "comment": "Bình luận",
      "estimatedTimeofArrival": "ETA (Thời gian đến dự kiến)",
      "estimatedTimeofDeparture": "ETD (Thời gian khởi hành dự kiến)",
      "receiveShipment": "Nhập kho",
      "shipmentName": "Tên lô hàng",
      "state": "Trạng thái",
      "supplier": "Nhà cung cấp",
      "transportType": "Loại vận chuyển"
    },
    "name": "Lô hàng nhập"
  },
  "stock/inventoryControl": {
    "fields": {
      "addInventoryLine": "Kiểm tra hàng tồn kho",
      "comment": "Bình luận",
      "finishedDate": "Ngày hoàn thành",
      "plannedDate": "Ngày dự định",
      "status": "Trạng thái",
      "stockLocationName": "Tên vị trí kho"
    },
    "name": "Kiểm soát hàng tồn kho"
  },
  "stock/inventoryControlLine": {
    "fields": {
      "articleName": "Tên bài viết",
      "articleNo": "Số bài viết",
      "changeAmount": "Số lượng thay đổi",
      "lotNo": "Số lô"
    }
  },
  "stock/stock-moves": {
    "fields": {
      "applied": "Thực thi",
      "appliedBy": "Người thực thi",
      "appliedDate": "Ngày thực thi",
      "appliedSince": "Thực thi từ",
      "appliedUntil": "Thực thi đến",
      "applier": "Người thực thi",
      "recipient": "",
      "comment": "Bình luận",
      "creator": "Người tạo",
      "planed": "Dự định",
      "planedSince": "Dự định từ",
      "planedUntil": "Dự định đến",
      "plannedDate": "Ngày dự định",
      "resourceId": "ID tài nguyên",
      "source_location": "Vị trí nguồn",
      "stockMoveLines": "Các dòng di chuyển kho",
      "target_location": "Vị trí đích",
      "totalAmount": "Tổng số lượng"
    },
    "name": "Di chuyển hàng tồn kho"
  },
  "stock/stockLocations/shelf": {
    "fields": {
      "comment": "Ghi chú",
      "name": "Tên",
      "picture": "Ảnh",
      "stockLocationName": "Vị trí kho"
    },
    "name": "Kệ"
  },
  "stock/stocklines": {
    "fields": {
      "amount": "Số lượng",
      "categoryId": "Loại sản phẩm",
      "delFlag": "Đã xoá",
      "id": "Id",
      "isDelete": "Đã xoá",
      "lotNo": "Số lô",
      "name": "Hàng tồn kho",
      "resourceId": "ID tài nguyên",
      "resourceName": "Tên tài nguyên"
    },
    "name": "Hàng tồn kho |||| Hàng tồn kho"
  }
};
export default translations;
