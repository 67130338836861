import repositories from '.';
import api from './api';
import { getApiQuery } from './base';

const resource = '/master/common';

export const useCurrencies = getApiQuery(api.commonGetCurrencies, { staleTime: undefined });
export const useAppState = getApiQuery(api.commonGetAppState, { staleTime: undefined });
export const useCountries = getApiQuery(api.commonGetCountries, { staleTime: Infinity });
export const useEnumQuery = getApiQuery(api.commonGetEnums, { staleTime: Infinity });

export const useQuantityType = (id: number | undefined) => {
  const { data } = useEnumQuery();

  return data?.quantityTypes.find((x) => x.id === id);
};

export const useCurrency = (id: string | undefined) => {
  const { data } = useCurrencies();

  return data?.find((x) => x.id === id);
};

export default {
  getAppSetting: () => repositories.get(`${resource}/setting`),
  getAppState: () => repositories.get(`${resource}/app-state`),
};
