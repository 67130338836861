import StockLocationChooser from 'components/chooser/stockLocationChooser';
import DateTimeRangeInput from 'components/commons/dateTimeRangeInput';
import EnumSelectField from 'components/input/enumSelectField';
import UserInput from 'components/input/reference/user';
import React from 'react';
import { BooleanInput } from 'react-admin';
import { StockMoveTypes } from 'repositories/stock/stockMoves';

function StockMoveTypeFilter({ source }: any) {
  return (
    <EnumSelectField
      source={source}
      choices={StockMoveTypes}
    />
  );
}

export default [
  <StockMoveTypeFilter source="moveType" alwaysOn />,
  <BooleanInput source="isOpen" />,
  <DateTimeRangeInput source="planed" since="planedSince" until="planedUntil" />,
  <DateTimeRangeInput source="applied" since="appliedSince" until="appliedUntil" />,
  <StockLocationChooser source="sourceStockLocation" />,
  <StockLocationChooser source="destinationStockLocation" />,
  <UserInput source="appliedBy" />,
  <UserInput source="createdBy" />,
];
