import { useCallback } from 'react';
import { useLogout } from 'react-admin';
import { useAppState } from 'repositories/app';

interface PermissionQuery {
  url?: string;
  httpMethod?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  methodName?: string;
}

const trimUrl = (url: string): string => url
  .replace(/^\/+|\/+$/g, '') // trim start and end slashes
  .replace(/^api\/1\.0\//g, '') // remove api/1.0 prefix
  .replace(/\{[^{^}]+\}/g, '{}');

const useHasAccess = () => {
  const logout = useLogout();
  // eslint-disable-next-line max-len
  const { data } = useAppState({}, {
    staleTime: 5 * 60 * 1000,
    onError: (err: any) => {
      if (err?.status === 401) {
        logout();
      }
    },
  });

  const hasAccess = useCallback((query: PermissionQuery) => {
    try {
      if (!data) {
        return false;
      }

      const matchingPermissions = data.permissions.filter((permission) => {
        if (query.url && trimUrl(query.url) !== trimUrl(permission.url)) return false;
        if (query.httpMethod && query.httpMethod !== permission.httpMethod) return false;
        if (query.methodName && query.methodName !== permission.methodName) return false;

        return true;
      });

      if (matchingPermissions.length > 0) {
        return matchingPermissions[0].isAuthorized;
      }

      return false;
    } catch {
      return false;
    }
  }, [data]);

  return hasAccess;
};
export default useHasAccess;
