/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { ProductListSchema } from './ProductListSchema';
import {
    ProductListSchemaFromJSON,
    ProductListSchemaFromJSONTyped,
    ProductListSchemaToJSON,
} from './ProductListSchema';

/**
 * 
 * @export
 * @interface PaginatedListProductSchema
 */
export interface PaginatedListProductSchema {
    /**
     * 
     * @type {Array<ProductListSchema>}
     * @memberof PaginatedListProductSchema
     */
    list: Array<ProductListSchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListProductSchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListProductSchema interface.
 */
export function instanceOfPaginatedListProductSchema(value: object): value is PaginatedListProductSchema {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListProductSchemaFromJSON(json: any): PaginatedListProductSchema {
    return PaginatedListProductSchemaFromJSONTyped(json, false);
}

export function PaginatedListProductSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListProductSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ProductListSchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListProductSchemaToJSON(value?: PaginatedListProductSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(ProductListSchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

