import useHasAccess from 'hooks/useHasAccess';
import React from 'react';
import {
  EditButton,
  ShowButton,
} from 'react-admin';

interface ActionButtonProps {
  url: string;
  // eslint-disable-next-line
  label: string;
}

export function ActionButtons(props: ActionButtonProps) {
  const {
    url,
  } = props;
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url, httpMethod: 'PUT' });
  // const canDelete = hasAccess({ url, httpMethod: 'DELETE' });
  const canShow = hasAccess({ url, httpMethod: 'GET' });

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {canShow && <ShowButton label="" />}
      {canPut && <EditButton label="" />}
    </div>
  );
}
// {canDelete && <DeleteButton label="" mutationMode="pessimistic" />}
