export default function localeDate(value: string | Date | undefined | null) {
  if (!value) {
    return '';
  }

  let tempValue: Date;

  if (typeof (value) === 'string') {
    tempValue = new Date(value);
  } else {
    tempValue = value;
  }

  return tempValue.toLocaleDateString();
}
