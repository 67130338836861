/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { ProductCategorySchema } from './ProductCategorySchema';
import {
    ProductCategorySchemaFromJSON,
    ProductCategorySchemaFromJSONTyped,
    ProductCategorySchemaToJSON,
} from './ProductCategorySchema';

/**
 * 
 * @export
 * @interface PaginatedListCategorySchema
 */
export interface PaginatedListCategorySchema {
    /**
     * 
     * @type {Array<ProductCategorySchema>}
     * @memberof PaginatedListCategorySchema
     */
    list: Array<ProductCategorySchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListCategorySchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListCategorySchema interface.
 */
export function instanceOfPaginatedListCategorySchema(value: object): value is PaginatedListCategorySchema {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListCategorySchemaFromJSON(json: any): PaginatedListCategorySchema {
    return PaginatedListCategorySchemaFromJSONTyped(json, false);
}

export function PaginatedListCategorySchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListCategorySchema {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(ProductCategorySchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListCategorySchemaToJSON(value?: PaginatedListCategorySchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(ProductCategorySchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

