import {
  Configuration,
  FetchParams,
  Middleware,
  ResponseContext,
} from 'api';
import { DefaultApi } from 'api/apis/DefaultApi';
import { HttpError } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

export const apiKey = `${process.env.REACT_APP_API_KEY}`;

export const getCookieValue = (name: string) => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop()
);
export const getCsrf = () => getCookieValue('X-CSRF-TOKEN');

const middleware: Middleware = {
  pre: async (context: FetchParams) => {
    const xsrf = getCsrf();
    const headers: Record<string, string> = {
      'x-apikey': apiKey,
      'x-lang': 'en',
      'x-requestid': uuidv4(),
      'content-type': 'application/json',
    };
    if (xsrf) {
      headers['X-CSRF-TOKEN'] = xsrf;
    }

    const options: FetchParams = {
      url: window.location.origin + context.url,
      init: {
        ...context.init,
        headers,
        // credentials: 'same-origin',
      },
    };

    return options;
  },
  post: async (context: ResponseContext) => {
    const { response } = context;

    if (response.status === 200) {
      return response;
    }
    const text = await response.text();

    const getText = () => {
      try {
        const jsondata = JSON.parse(text);
        const errorText = `mai.msg.${jsondata.msgNo}`;
        return [errorText, jsondata.data];
      } catch {
        return [text, {}];
      }
    };

    const [message, body] = getText();

    throw new HttpError(message, response.status, body);
  },

};

const api = new DefaultApi(new Configuration({

  middleware: [middleware],
  basePath: '',
}));

export default api;
