import * as React from 'react';
import { useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {
  Form,
  required,
  TextInput,
  PasswordInput,
  useLogin,
  useNotify,
} from 'react-admin';
import Box from '@mui/material/Box';
import background from 'assets/imgs/common/background.jpg';
import useTranslate from 'utils/translate';

function MyLoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    login({ username, password }).catch(() => {
      setLoading(false);
      notify('Invalid email or password');
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `url(${background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          >
            MAI System
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="username"
                label={translate('mai.username')}
                disabled={loading}
                inputProps={{ maxLength: 50 }}
                validate={required()}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <PasswordInput
                source="password"
                label={translate('mai.password')}
                disabled={loading}
                inputProps={{ maxLength: 50 }}
                validate={required()}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                variant="outlined"
                helperText={false}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && (
                <CircularProgress size={25} thickness={2} />
              )}
              {translate('mai.signin')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
}

export default MyLoginPage;
