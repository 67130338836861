import { defaultTheme } from 'react-admin';

const customTheme = {
  components: {
    ...defaultTheme.components,
    MuiTextInput: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
  },
};

export const darkTheme = {
  ...defaultTheme,
  ...customTheme,
  palette: {
    mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
  },
};

export const lightTheme = {
  ...defaultTheme,
  ...customTheme,
};
