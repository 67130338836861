import * as React from 'react';
import { Layout, LayoutProps, useNotify } from 'react-admin';
import AppBar from '../components/shared/appBar';
import menu from './menu';

export default function MaiLayout(props: LayoutProps) {
  const notify = useNotify();
  React.useEffect(() => {
    const showNotify = (e: any) => {
      const { detail } = e;
      notify(detail.content, {
        type: detail.type,
      });
    };
    document.removeEventListener('ApiError', showNotify);
    document.addEventListener('ApiError', showNotify);
  }, [notify]);
  return (<Layout {...props} appBar={AppBar} menu={menu} />);
}
