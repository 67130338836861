import React from 'react';
import { Create } from 'react-admin';
import ShelfForm from './form/ShelfForm';

export default function CreateShelfContainer() {
  return (
    <Create redirect="show">
      <ShelfForm />
    </Create>
  );
}
