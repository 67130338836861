import {
  FormControl, InputLabel, MenuItem, Select, SelectProps,
} from '@mui/material';
import { StockLocationSchema } from 'api';
import React from 'react';
import { useInput } from 'react-admin';
import { useStockLocations } from 'repositories/stock/stockLocations';
import useTranslate from 'utils/translate';

export interface StockLocationChooserProps extends Omit<SelectProps, 'onChange'> {
  value?: number | undefined,
  onChange?: (value: number | undefined) => void,
  source?: string,
  onChangeValue?: (value: StockLocationSchema | undefined) => void,

  placeholder?: undefined | string,
  label?: undefined | string,
}

export default function StockLocationChooser(props: StockLocationChooserProps) {
  const {
    value,
    onChange,
    onChangeValue,
    source,
    ...rest
  } = props;

  const locations = useStockLocations();
  const translate = useTranslate();

  const {
    field,
  } = useInput({
    onChange,
    source: source || '',
  });

  const selectedId = value === undefined ? field.value : value;

  return (
    <FormControl>
      <InputLabel>{translate('mai.stockLine.selectStockLine')}</InputLabel>
      <Select
        {...rest}
        label="Stock Location"
        value={selectedId}
        style={{ minWidth: '200px' }}
        onChange={(event) => {
          if (event.target.value === null) {
            onChangeValue?.(undefined);
          } else {
            const item = locations?.data?.list?.find((l) => l.id === event.target.value);
            if (item) {
              onChangeValue?.(item);
            }
          }
          // @ts-ignore
          onChange?.(event.target.value);
          field.onChange(event.target.value);
        }}
        variant="outlined"
      >
        {locations?.data?.list?.map((location) => (
          <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>
        ))}
      </Select>

    </FormControl>
  );
}
