import { Grid } from '@mui/material';
import { PurchasingPurchaseOrderCreate } from 'api';
import EnumSelectField from 'components/input/enumSelectField';
import CustomDatePicker from 'components/input/date/CustomDatePicker';
import BusinessPartnerInput from 'components/input/reference/businessPartner';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { Source } from 'utils/source';
import { deliveryAddresses } from './enums';

export default function PurchaseOrderCreate() {
  const s = Source<PurchasingPurchaseOrderCreate>();

  return (
    <Create redirect="edit">
      <SimpleForm>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid item xs={2} md={2} />

          <Grid item xs={8} md={8}>
            <BusinessPartnerInput {...s('businessPartnerId')} required />
          </Grid>
          <Grid item xs={2} md={2} />

          <Grid item xs={2} md={2} />
          <Grid item xs={8} md={8}>
            <TextInput {...s('prefix')} required />
          </Grid>
          <Grid item xs={2} md={2} />

          <Grid item xs={2} md={2} />
          <Grid item xs={4} md={4}>
            <CustomDatePicker
              {...s('plannedDate')}
              required
              fullWidth
              variant="outlined"
              helperText={false}
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <EnumSelectField
              {...s('deliveryAddress')}
              choices={deliveryAddresses}
              fullWidth
              helperText={false}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
}
