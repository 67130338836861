function removeDuplicate<T>(data: T[], key: (arg0: T) => any) {
  const result: T[] = [];

  data.forEach((x) => {
    if (result.find((y) => key(y) === key(x)) === undefined) {
      result.push(x);
    }
  });

  return result;
}

export interface ObjectWithParent {
  id?: number;
  parentId?: number | null;
}

export type ObjectWithChildren<T extends {}> = T & {
  children: ObjectWithChildren<T>[];
};

export function MakeTree<T extends ObjectWithParent>(elements: T[]) {
  const flatElements: ObjectWithChildren<T>[] = removeDuplicate(elements, (arg0: T) => arg0.id)
    .map((x) => ({ ...x, children: [] }));
  const tree = flatElements.filter((x) => x.parentId === undefined);

  flatElements.forEach((elem) => {
    if (elem.parentId) {
      const parentElem = flatElements.find((x) => x.id === elem.parentId);

      if (parentElem === undefined) {
        throw new Error();
      }

      parentElem.children.push(elem);
    }
  });

  return tree;
}
