import BulkActions from 'components/layout/actions/bulkActions';
import useResourcePermissions from 'components/layout/actions/resourcePermissions';
import React from 'react';
import { DatagridProps, Datagrid as RADatagrid } from 'react-admin';

export default function Datagrid(props: DatagridProps) {
  const {
    children,
    ...rest
  } = props;
  const resourcePermissions = useResourcePermissions();

  const bulkActionButtons = React.useMemo(
    () => (resourcePermissions.delete ? <BulkActions isDelete /> : false),
    [resourcePermissions],
  );
  return <RADatagrid rowClick="show" bulkActionButtons={bulkActionButtons} {...rest}>{children}</RADatagrid>;
}
