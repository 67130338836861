/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockSplitRequest
 */
export interface StockSplitRequest {
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    stockLineId: number;
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    amountPerUnit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockSplitRequest
     */
    num?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockSplitRequest
     */
    comment?: string | null;
}

/**
 * Check if a given object implements the StockSplitRequest interface.
 */
export function instanceOfStockSplitRequest(value: object): value is StockSplitRequest {
    if (!('stockLineId' in value) || value['stockLineId'] === undefined) return false;
    return true;
}

export function StockSplitRequestFromJSON(json: any): StockSplitRequest {
    return StockSplitRequestFromJSONTyped(json, false);
}

export function StockSplitRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockSplitRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'stockLineId': json['stockLineId'],
        'amountPerUnit': json['amountPerUnit'] == null ? undefined : json['amountPerUnit'],
        'num': json['num'] == null ? undefined : json['num'],
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function StockSplitRequestToJSON(value?: StockSplitRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'stockLineId': value['stockLineId'],
        'amountPerUnit': value['amountPerUnit'],
        'num': value['num'],
        'comment': value['comment'],
    };
}

