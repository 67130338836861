import { Route } from 'react-router';
import React from 'react';
import { Authenticated } from 'react-admin';
import incomingShipmentCreate from './incomingShipmentCreate';
import incomingShipmentEdit from './incomingShipmentEdit';
import incomingShipmentList from './incomingShipmentList';
import incomingShipmentShow from './incomingShipmentShow';
import ReceiveShipments from './receive';

export default {
  name: 'stock/incoming-shipments',
  list: incomingShipmentList,
  create: incomingShipmentCreate,
  edit: incomingShipmentEdit,
  show: incomingShipmentShow,
  options: {
    label: 'Incoming Shipments',
  },
  children: [
    <Route
      path=":id/receive"
      element={<Authenticated><ReceiveShipments /></Authenticated>}
    />,
  ],
};
