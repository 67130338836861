/* AUTOGENERATED, DO NOT EDIT! */
/* eslint-disable */
const translations = {
  "address": "Address",
  "amount": "Amount",
  "auth": {
    "lockAccount": "Lock",
    "msg": {
      "confirm_password_invalidate": "The confirm password is not match with password",
      "password_format_invalidate": "The password must have at least 8 character, \\\\n            contains at least one digit, uppercase, lowercase letters, special character"
    },
    "resetPassword": "Reset Password",
    "unlockAccount": "UnLock"
  },
  "birthday": "Date of birth",
  "categories": "Categories",
  "changePassword": "Change password",
  "comment": "Comment",
  "configuration": "Configuration",
  "confirm": "Confirm",
  "confirmPassword": "Confirm password",
  "custom_group": "Custom group",
  "dark": "Dark",
  "darklightmode": "Dark/Light Mode",
  "dashboard": "Dashboard",
  "day": "day",
  "default_group": "Default group",
  "detail": "Details",
  "downloadTranslations": "Download Translations",
  "email": "Email",
  "firstname": "First name",
  "gender": "Gender",
  "genders": {
    "Female": "Female",
    "Male": "Male",
    "Other": "Other"
  },
  "group": "Group",
  "groups": "Groups",
  "incomingShipment": {
    "receive": {
      "ActualAmount": "Actual Amount",
      "Amount": "Amount",
      "ArticleNo": "Article No",
      "Difference": "Difference",
      "LotNo": "Lot No",
      "PlannedAmount": "Planned Amount",
      "Product": "Product",
      "Resource": "Resource"
    },
    "shipmentState": {
      "Done": "Done",
      "DuringShipment": "During Shipment",
      "Error": "Error",
      "Processing": "Processing",
      "WaitingForDeparture": "Waiting for Departure"
    },
    "transportType": {
      "Air": "Air",
      "Express": "Express",
      "Other": "Other",
      "Sea": "Sea",
      "Truck": "Truck"
    }
  },
  "inventoryControl": {
    "inventoryControlStatus": {
      "Applied": "Applied",
      "Unapplied": "Unapplied"
    },
    "quantityInput": "Quantity Input"
  },
  "isActive": "Active",
  "keyword": "Keyword",
  "lastLogin": "Last login",
  "lastLogout": "Last logout",
  "lastname": "Last name",
  "light": "Light",
  "locking": "Locking",
  "logout": "Logout",
  "menu": {
    "business_partners": "Business partners",
    "categories": "Categories",
    "dashboard": "Dashboard",
    "groups": "Groups",
    "incoming_shipments": "Incoming Shipments",
    "inventory_controls": "Inventory Controls",
    "moves": "Moves",
    "parties": "Parties",
    "production": "Production",
    "production_items": "Production List",
    "products": "Products",
    "purchase_order": "Purchase Order",
    "purchasing": "Purchasing",
    "raw_materials": "Raw materials",
    "receive_shipments": "Receive Shipments",
    "shelf": "Shelf",
    "stock": "Stock",
    "stock_lines": "Stock lines",
    "stocksplit": "Split",
    "users": "Users",
    "workloads": "Workloads"
  },
  "msg": {
    "ACCOUNT_WAS_LOCKED": "The account has been temporarily locked, please contact the administrator for assistance.",
    "CATEGORY_PARENT_NOT_FOUND": "Category parent not found",
    "EMAIL_HAD_USED": "This email is already registered. Please use another email!",
    "FORBIDDEN": "Forbidden",
    "GROUP_HAVE_ACCOUNT": "There is an account in this group, so it can't be deleted, please delete all accounts of this group first.",
    "GROUP_NAME_HAD_USED": "This group name is already registered. Please use another group name!",
    "GROUP_NAME_INVALIDATE": "Name of group invalidate.",
    "HAS_ASSOCIATED_RECORDS": "Record has associated records!",
    "LOTNO_ALREADY_EXIST": "Lot no already exists. Please render another lot no!",
    "MUST_BE_NUMBER": "The value must be a number",
    "NOT_ALLOWED": "Not allowed",
    "NOT_FOUND": "Requested data not found.",
    "NO_VALID_DATA_FOUND": "No valid data found. Please scan again",
    "SERVER_ERROR": "The system failed, please try again later.",
    "STOCKLINE_INVALIDATE": "Stock line invalidate",
    "UNIQUE_CONSTRAINT": "Unique constraint",
    "USERNAME_HAD_USED": "This username is already registered. Please use another username!",
    "USERNAME_OR_PASSWORD_NOT_CORRECT": "Incorrect account or password",
    "VALIDATION_FAILED": "Validation error",
    "YOU_NOT_HAVE_PERMISSION": "You do not have permission to perform this action."
  },
  "newPassword": "New Password",
  "note": "Note",
  "oldPassword": "Old Password",
  "password": "Password",
  "permissions": "Permissions",
  "phone": "Phone",
  "private_group": "Private group",
  "product": {
    "categoryId": "Category",
    "stateActive": "Active",
    "stateCode": "Active",
    "stateInactive": "Inactive",
    "stateNull": "Either"
  },
  "profile": "Profile",
  "rawMaterials": {
    "stockTracingCode": {
      "Lot": "Lot",
      "None": "None",
      "Serial": "Serial"
    }
  },
  "resource": "Resource",
  "resources": "Resources",
  "second": "second",
  "signin": "SIGN IN",
  "status": "Status",
  "stockLine": {
    "selectStockLine": "StockLine"
  },
  "stockMove": {
    "apply_btn": "APPLY",
    "apply_confirm_content": "Do you want to apply this stock move?",
    "apply_confirm_title": "Apply stock move",
    "apply_failed": "Apply failed.",
    "apply_success": "Apply successful",
    "undo_apply_btn": "UNDO",
    "undo_apply_confirm_content": "Do you want to undo this stock move?",
    "undo_apply_confirm_title": "Undo stock move",
    "undo_apply_failed": "Undo failed.",
    "undo_apply_success": "Undo successful"
  },
  "stockRawMaterials": {
    "suplierEntered": "Supplier entered"
  },
  "user": "User",
  "username": "Username",
  "using": "Using",
  "validation": {
    "phone": "The phone number is invalid"
  }
};
export default translations;
