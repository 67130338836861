import messages from 'i18n/en';
import { useTranslate as useRatranslate } from 'react-admin';
import { Path } from 'react-hook-form';

export type TranslationKey = Path<typeof messages>;

export default function useTranslate() {
  const translate: (path: TranslationKey) => string = useRatranslate();

  return translate;
}
