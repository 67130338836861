/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessPartnerAddressSchema
 */
export interface BusinessPartnerAddressSchema {
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    zipcode: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    countryId: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BusinessPartnerAddressSchema
     */
    city: string;
}

/**
 * Check if a given object implements the BusinessPartnerAddressSchema interface.
 */
export function instanceOfBusinessPartnerAddressSchema(value: object): value is BusinessPartnerAddressSchema {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('zipcode' in value) || value['zipcode'] === undefined) return false;
    if (!('countryId' in value) || value['countryId'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    return true;
}

export function BusinessPartnerAddressSchemaFromJSON(json: any): BusinessPartnerAddressSchema {
    return BusinessPartnerAddressSchemaFromJSONTyped(json, false);
}

export function BusinessPartnerAddressSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessPartnerAddressSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address1': json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'zipcode': json['zipcode'],
        'countryId': json['countryId'],
        'state': json['state'] == null ? undefined : json['state'],
        'city': json['city'],
    };
}

export function BusinessPartnerAddressSchemaToJSON(value?: BusinessPartnerAddressSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'address1': value['address1'],
        'address2': value['address2'],
        'zipcode': value['zipcode'],
        'countryId': value['countryId'],
        'state': value['state'],
        'city': value['city'],
    };
}

