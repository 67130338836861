/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StockShipmentLineSchema
 */
export interface StockShipmentLineSchema {
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    rawMaterialId: number;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StockShipmentLineSchema
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockShipmentLineSchema
     */
    purchaseOrderLineId?: number | null;
}

/**
 * Check if a given object implements the StockShipmentLineSchema interface.
 */
export function instanceOfStockShipmentLineSchema(value: object): value is StockShipmentLineSchema {
    if (!('rawMaterialId' in value) || value['rawMaterialId'] === undefined) return false;
    if (!('amount' in value) || value['amount'] === undefined) return false;
    return true;
}

export function StockShipmentLineSchemaFromJSON(json: any): StockShipmentLineSchema {
    return StockShipmentLineSchemaFromJSONTyped(json, false);
}

export function StockShipmentLineSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockShipmentLineSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'rawMaterialId': json['rawMaterialId'],
        'amount': json['amount'],
        'comment': json['comment'] == null ? undefined : json['comment'],
        'purchaseOrderLineId': json['purchaseOrderLineId'] == null ? undefined : json['purchaseOrderLineId'],
    };
}

export function StockShipmentLineSchemaToJSON(value?: StockShipmentLineSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'rawMaterialId': value['rawMaterialId'],
        'amount': value['amount'],
        'comment': value['comment'],
        'purchaseOrderLineId': value['purchaseOrderLineId'],
    };
}

