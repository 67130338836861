import {
  Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ProductRawMaterialEntity } from 'api';
import styles from './popup.module.scss';
import { PopupSupplier, SupplierScanResult } from './barcodeHandler';

interface BarcodePaperProps {
  value?: any,
  placeholder: string
}

function BarcodePaper(props: BarcodePaperProps) {
  const {
    value,
    placeholder,
  } = props;

  const text = value === undefined ? placeholder : value;

  return (
    <Paper className={styles.barcodeField + (value === undefined ? ' missing' : '')}>
      <Typography>
        {text}
      </Typography>
    </Paper>
  );
}

interface PopupProps extends React.PropsWithChildren {
  onClose: () => void,
  open: boolean,
  header: string,
  resource?: ProductRawMaterialEntity,
}

function Popup(props: PopupProps) {
  const {
    open,
    onClose,
    header,
    resource,
    children,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.popup}
    >
      <DialogTitle className="header">
        <Typography typography="h5">{header}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography typography="h5">
            {resource?.articleNo}
            {resource?.name}
          </Typography>
          {children}
        </Stack>

      </DialogContent>
    </Dialog>
  );
}

interface SupplierPopupProps {
  state?: SupplierScanResult,
  onClose: () => void,
}

export function EdelridPopup(props: SupplierPopupProps) {
  const {
    state,
    ...rest
  } = props;

  return (
    <Popup
      open={state?.supplierPopup === PopupSupplier.Edelrid}
      resource={state?.resource}
      header="Edelrid Barcode"
      {...rest}
    >
      <BarcodePaper
        value={state?.stockLotName}
        placeholder="Stock Lot"
      />

    </Popup>
  );
}

export function PorcherPopup(props: SupplierPopupProps) {
  const {
    state,
    ...rest
  } = props;
  return (
    <Popup
      open={state?.supplierPopup === PopupSupplier.Porcher}
      resource={state?.resource}
      header="Porcher Barcode"
      {...rest}
    >

      <BarcodePaper
        value={state?.stockLotName}
        placeholder="Stock Lot"
      />
      <BarcodePaper
        value={state?.amount}
        placeholder="Amount"
      />

    </Popup>
  );
}
