import React from 'react';
import { ReferenceInput, SelectInput, TextInput } from 'react-admin';

export default [
  <TextInput source="query" alwaysOn />,
  <ReferenceInput
    label="resources.product/products.fields.name"
    alwaysOn
    source="productId"
    reference="product/products"
  >
    <SelectInput optionText="name" fullWidth />
  </ReferenceInput>,
];
