import urls from 'app/urls';
import ListActions from 'components/layout/actions/list';
import List from 'components/list/List';
import { ActionButtons } from 'components/list/actionButtons';
import Datagrid from 'components/list/dataGrid';
import * as React from 'react';
import {
  TextField,
} from 'react-admin';
import { ColorField } from 'react-admin-color-picker';
import styles from './category.module.scss';
import filters from './filters';

export default React.memo(() => (
  <List filters={filters} actions={<ListActions exporter />}>
    <Datagrid className={styles.ctgTable}>
      <TextField source="name" />
      <TextField source="fullName" />
      <ColorField source="color" />
      <TextField source="position" />
      <TextField source="description" />
      <ActionButtons url={urls.Categories_GetDetail.url} label="action" />
    </Datagrid>
  </List>
));
