import * as React from 'react';
import { Toolbar, SaveButton, ToolbarProps } from 'react-admin';

interface CustomToolbarProps extends ToolbarProps {
  hasEdit?: boolean,
  isSave?: boolean,
  isDelete?: boolean,
  alwaysEnable?: boolean,
}

export default function CustomToolBar(props: CustomToolbarProps) {
  const {
    hasEdit,
    alwaysEnable,
    ...rest
  } = props;

  return (
    <>
      <div style={{ height: '64px' }} />
      <Toolbar
        {...rest}
        sx={{
          position: 'fixed', bottom: '0', zIndex: 3, width: '100%',
        }}
      >
        <SaveButton alwaysEnable={alwaysEnable} />
      </Toolbar>
    </>
  );
}
