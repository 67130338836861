import * as React from 'react';
import {
  Show,
} from 'react-admin';
import { Grid } from '@mui/material';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';
import { ColorField } from 'react-admin-color-picker';

export default function () {
  return (
    <Show actions={<ShowActions />}>
      <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
        <Grid item xs={12} md={12}>
          <ShowTitle />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
          <ShowLabel label="resources.product/categories.fields.name" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="name" className="highLightField" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.product/categories.fields.fullName" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="fullName" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.product/categories.fields.position" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="position" className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.product/categories.fields.description" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="description" />
        </Grid>

        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.product/categories.fields.color" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ColorField source="color" />
        </Grid>
        <Grid item xs={2} md={1.5} />
      </Grid>
    </Show>
  );
}
