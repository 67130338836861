/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserUpdateEntity
 */
export interface AuthUserUpdateEntity {
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    lastname?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthUserUpdateEntity
     */
    gender?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserUpdateEntity
     */
    birthday?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuthUserUpdateEntity
     */
    address?: string | null;
}

/**
 * Check if a given object implements the AuthUserUpdateEntity interface.
 */
export function instanceOfAuthUserUpdateEntity(value: object): value is AuthUserUpdateEntity {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function AuthUserUpdateEntityFromJSON(json: any): AuthUserUpdateEntity {
    return AuthUserUpdateEntityFromJSONTyped(json, false);
}

export function AuthUserUpdateEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserUpdateEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'birthday': json['birthday'] == null ? undefined : (new Date(json['birthday'])),
        'address': json['address'] == null ? undefined : json['address'],
    };
}

export function AuthUserUpdateEntityToJSON(value?: AuthUserUpdateEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'email': value['email'],
        'phone': value['phone'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'gender': value['gender'],
        'birthday': value['birthday'] == null ? undefined : ((value['birthday'] as any).toISOString()),
        'address': value['address'],
    };
}

