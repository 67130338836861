/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthLoginUser
 */
export interface AuthLoginUser {
    /**
     * 
     * @type {string}
     * @memberof AuthLoginUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthLoginUser
     */
    password: string;
}

/**
 * Check if a given object implements the AuthLoginUser interface.
 */
export function instanceOfAuthLoginUser(value: object): value is AuthLoginUser {
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function AuthLoginUserFromJSON(json: any): AuthLoginUser {
    return AuthLoginUserFromJSONTyped(json, false);
}

export function AuthLoginUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthLoginUser {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
    };
}

export function AuthLoginUserToJSON(value?: AuthLoginUser | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'username': value['username'],
        'password': value['password'],
    };
}

