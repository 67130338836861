import DateTimeRangeInput from 'components/commons/dateTimeRangeInput';
import EnumSelectField from 'components/input/enumSelectField';
import React from 'react';
import { BooleanInput, TextInput } from 'react-admin';
import { IncomingShipmentSearchRequest } from 'api';
import { Source } from 'utils/source';
import { incomingShipmentState } from './enum';

const s = Source<IncomingShipmentSearchRequest>();

export default [
  <TextInput {...s('query')} alwaysOn />,
  <BooleanInput {...s('isOpen')} defaultValue />,
  <EnumSelectField choices={incomingShipmentState} {...s('state')} />,
  <DateTimeRangeInput source="eta" since={s('etaSince').source} until={s('etaUntil').source} />,
];
