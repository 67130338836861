import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import { Grid, Box } from '@mui/material';
import EditToolBar from 'components/commons/editToolBar';
import EditActions from 'components/layout/actions/edit';
import useHasAccess from 'hooks/useHasAccess';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'auth/groups/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'auth/groups/{id}', httpMethod: 'DELETE' });
  return (
    <Edit
      actions={<EditActions />}
      mutationMode="pessimistic"
      className="mai-form"
    >
      <SimpleForm toolbar={<EditToolBar isSave={canPut} isDelete={canDelete} />}>
        <Box display="flex" flexDirection="column" width="100%" gap="10px">
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="name"
                validate={required()}
                fullWidth
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                helperText={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="description"
                fullWidth
                inputProps={{ maxLength: 200 }}
                variant="outlined"
                helperText={false}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Edit>
  );
});
