import * as React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
  required,
  maxLength,
  regex,
  useUnique,
} from 'react-admin';
import { Grid, Box } from '@mui/material';
import CreateToolBar from 'components/commons/createToolBar';
import useHasAccess from 'hooks/useHasAccess';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPost = hasAccess({ url: 'auth/groups/', httpMethod: 'POST' });
  const canSearch = hasAccess({ url: 'auth/groups/', httpMethod: 'GET' });
  const unique = useUnique();

  return (
    <Create
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <SimpleForm toolbar={<CreateToolBar isSave={canPost} />}>
        <Box display="flex" flexDirection="column" width="100%" gap="10px">
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={8} md={5}>
              <TextInput
                source="id"
                fullWidth
                validate={[
                  required(),
                  maxLength(50),
                  regex(/^[a-zA-Z0-9]+$/),
                  unique(),
                ]}
                helperText={false}
              />
            </Grid>
            <Grid item xs={8} md={5}>
              <TextInput
                source="name"
                validate={[
                  required(),
                  maxLength(50),
                  unique(),
                ]}
                fullWidth
                helperText={false}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} rowSpacing={0.5}>
            <Grid item xs={4} md={1} />
            <Grid item xs={16} md={10}>
              <TextInput
                source="description"
                fullWidth
                inputProps={{ maxLength: 200 }}
                variant="outlined"
                helperText={false}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Create>
  );
});
