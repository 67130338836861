/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';

import {
  TextInput,
  TextInputProps,
} from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import useTranslate, { TranslationKey } from 'utils/translate';

interface SearchInputProps extends TextInputProps {
  placeholder_i18n: TranslationKey
}

function CustomSearchInput(props: SearchInputProps) {
  const {
    placeholder,
    placeholder_i18n,  // eslint-disable-line
    ...rest
  } = props;

  const translate = useTranslate();
  let text = '';
  if (placeholder_i18n) {
    text = translate(placeholder_i18n);
  } else {
    throw new Error('no placeholder defined');
  }

  return (
    <TextInput
      label={text}
      variant="outlined"
      resettable
      inputProps={{ maxLength: 250 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

export default CustomSearchInput;
