/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { PurchasingPurchaseOrderLineShipment } from './PurchasingPurchaseOrderLineShipment';
import {
    PurchasingPurchaseOrderLineShipmentFromJSON,
    PurchasingPurchaseOrderLineShipmentFromJSONTyped,
    PurchasingPurchaseOrderLineShipmentToJSON,
} from './PurchasingPurchaseOrderLineShipment';

/**
 * 
 * @export
 * @interface PaginatedListPurchaseOrderLineShipment
 */
export interface PaginatedListPurchaseOrderLineShipment {
    /**
     * 
     * @type {Array<PurchasingPurchaseOrderLineShipment>}
     * @memberof PaginatedListPurchaseOrderLineShipment
     */
    list: Array<PurchasingPurchaseOrderLineShipment>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListPurchaseOrderLineShipment
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListPurchaseOrderLineShipment interface.
 */
export function instanceOfPaginatedListPurchaseOrderLineShipment(value: object): value is PaginatedListPurchaseOrderLineShipment {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListPurchaseOrderLineShipmentFromJSON(json: any): PaginatedListPurchaseOrderLineShipment {
    return PaginatedListPurchaseOrderLineShipmentFromJSONTyped(json, false);
}

export function PaginatedListPurchaseOrderLineShipmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListPurchaseOrderLineShipment {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(PurchasingPurchaseOrderLineShipmentFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListPurchaseOrderLineShipmentToJSON(value?: PaginatedListPurchaseOrderLineShipment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(PurchasingPurchaseOrderLineShipmentToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

