import { ProfileUpdateRequest } from 'api';
import { useMutation, useQuery } from 'react-query';
import repositories from '..';
import api from '../api';

const resource = '/auth/profile';

export const useProfile = () => useQuery(['Profile'], () => api.profileGet());
export const useMutateProfile = () => useMutation(
  'Profile',
  (params: ProfileUpdateRequest) => api.profileUpdate(params),
);

export default {
  getProfile: () => repositories.get(`${resource}`),
  saveProfile: (data: any) => repositories.put(`${resource}/${data.id ?? 0}`, data),
  changePassword: (data: any) => repositories.put(`${resource}/${data.id ?? 0}/password`, data),
};
