import * as React from 'react';
import {
  Show,
} from 'react-admin';
import { Grid } from '@mui/material';
import { BusinessPartnerSchema } from 'api/models/BusinessPartnerSchema';
import { Source } from 'utils/source';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from '../../components/commons/showTitle';
import ShowLabel from '../../components/commons/showLabel';
import ShowField from '../../components/commons/showField';

export default function () {
  const s = Source<BusinessPartnerSchema>();
  return (
    <Show actions={<ShowActions />}>
      <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
        <Grid item xs={12} md={12}>
          <ShowTitle />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
          <ShowLabel label="resources.business-partner/business-partners.fields.name" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('name')} className="highLightField" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.phone" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('phone')} />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.taxId" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('taxId')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.address1" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('address.address1')} className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.address2" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('address.address2')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.zipcode" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('address.zipcode')} />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.state" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField {...s('address.state')} />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.business-partner/business-partners.fields.countryName" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField {...s('address.countryId')} />
        </Grid>
        <Grid item xs={2} md={1.5} />
      </Grid>
    </Show>
  );
}
