import useHasAccess from 'hooks/useHasAccess';
import * as React from 'react';
import {
  Create,
  ListButton,
  Loading,
  TopToolbar,
} from 'react-admin';
import { useResourcesQuery } from 'repositories/products/product';
import StockMoveForm from './form/stockMoveForm';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canSearch = hasAccess({ url: 'stock/stock-moves/', httpMethod: 'GET' });
  const { data: resources } = useResourcesQuery();

  if (!resources) return <Loading />;

  return (
    <Create
      redirect="show"
      actions={(
        <TopToolbar>
          {canSearch && <ListButton />}
        </TopToolbar>
      )}
      className="mai-form"
    >
      <StockMoveForm />
    </Create>
  );
});
