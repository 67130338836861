/* AUTOGENERATED, DO NOT EDIT! */
/* eslint-disable */
const translations = {
  "address": "Địa chỉ",
  "amount": "Số lượng",
  "auth": {
    "lockAccount": "Khoá",
    "msg": {
      "confirm_password_invalidate": "Xác nhận mật khẩu không khớp với mật khẩu",
      "password_format_invalidate": "Mật khẩu phải có ít nhất 8 ký tự, chứa ít nhất 1 chữ số, chữ hoa, chữ thường, ký tự đặc biệt"
    },
    "resetPassword": "Đặt lại mật khẩu",
    "unlockAccount": "Mở khoá"
  },
  "birthday": "Sinh nhật",
  "categories": "Loại sản phẩm",
  "changePassword": "Đổi mật khẩu",
  "comment": "Ghi chú",
  "configuration": "Cài đặt",
  "confirm": "Xác nhận",
  "confirmPassword": "Nhập lại mật khẩu",
  "custom_group": "Nhóm tuỳ chọn",
  "dark": "Tối",
  "darklightmode": "Chế độ sáng/tối",
  "dashboard": "Trang chủ",
  "day": "ngày",
  "default_group": "Nhóm mặc định",
  "detail": "Thông tin chi tiết",
  "downloadTranslations": "Tải xuống bản dịch",
  "email": "Email",
  "firstname": "Họ",
  "gender": "Giới tính",
  "genders": {
    "Female": "Nữ",
    "Male": "Nam",
    "Other": "Khác"
  },
  "group": "Nhóm tài khoản",
  "groups": "Nhóm tài khoản",
  "incomingShipment": {
    "receive": {
      "ActualAmount": "Số lượng thực tế",
      "Amount": "Số lượng",
      "ArticleNo": "Số bài viết",
      "Difference": "Khác biệt",
      "LotNo": "Số lô",
      "PlannedAmount": "Số lượng dự kiến",
      "Product": "Sản phẩm",
      "Resource": "Nguồn tài nguyên"
    },
    "shipmentState": {
      "Done": "Hoàn thành",
      "DuringShipment": "Trong khi vận chuyển",
      "Error": "Lỗi",
      "Processing": "Đang xử lý",
      "WaitingForDeparture": "Đang chờ khởi hành"
    },
    "transportType": {
      "Air": "Đường hàng không",
      "Express": "Nhanh",
      "Other": "Khác",
      "Sea": "Đường biển",
      "Truck": "Đường bộ"
    }
  },
  "inventoryControl": {
    "inventoryControlStatus": {
      "Applied": "Đã thực thi",
      "Unapplied": "Chưa thực thi"
    },
    "quantityInput": "Số lượng trong kho"
  },
  "isActive": "Kích hoạt",
  "keyword": "Từ khoá",
  "lastLogin": "Đăng nhập lần cuối",
  "lastLogout": "Đăng xuất lần cuối",
  "lastname": "Tên",
  "light": "Sáng",
  "locking": "Đang khoá",
  "logout": "Đăng xuất",
  "menu": {
    "business_partners": "Đối tác",
    "categories": "Loại sản phẩm",
    "dashboard": "Bảng điều khiển",
    "groups": "Nhóm tài khoản",
    "incoming_shipments": "Nhập hàng",
    "inventory_controls": "Kiểm kho",
    "moves": "Chuyển kho",
    "parties": "Nhóm",
    "production": "Sản xuất",
    "production_items": "",
    "products": "Sản phẩm",
    "purchase_order": "Đơn đặt hàng",
    "purchasing": "Mua hàng",
    "raw_materials": "Nguyên liệu thô",
    "receive_shipments": "Nhập kho",
    "shelf": "Kệ",
    "stock": "Kho",
    "stock_lines": "Tồn kho",
    "stocksplit": "Tách",
    "users": "Người dùng",
    "workloads": "Khối lượng công việc"
  },
  "msg": {
    "ACCOUNT_WAS_LOCKED": "Tài khoản đã tạm thời bị khóa, vui lòng liên hệ với quản trị viên để được hỗ trợ.",
    "CATEGORY_PARENT_NOT_FOUND": "Không tìm thấy danh mục cha",
    "EMAIL_HAD_USED": "Email này đã được đăng ký. Vui lòng sử dụng email khác!",
    "FORBIDDEN": "Bị cấm",
    "GROUP_HAVE_ACCOUNT": "Có một tài khoản trong nhóm này nên không thể xóa được, trước tiên hãy xóa tất cả các tài khoản của nhóm này.",
    "GROUP_NAME_HAD_USED": "Tên nhóm này đã được đăng ký. Vui lòng sử dụng tên nhóm khác!",
    "GROUP_NAME_INVALIDATE": "Tên của nhóm không hợp lệ.",
    "HAS_ASSOCIATED_RECORDS": "Bản ghi có các bản ghi liên quan!",
    "LOTNO_ALREADY_EXIST": "Số lô này đã tồn tại, vui lòng nhập một số lô khác!",
    "MUST_BE_NUMBER": "Giá trị phải là một số!",
    "NOT_ALLOWED": "Không được phép",
    "NOT_FOUND": "Dữ liệu được yêu cầu không được tìm thấy.",
    "NO_VALID_DATA_FOUND": "Không tìm thấy dữ liệu hợp lệ. Vui lòng quét lại!",
    "SERVER_ERROR": "Hệ thống có lỗi, vui lòng thử lại sau.",
    "STOCKLINE_INVALIDATE": "Hàng tồn kho không hợp lệ",
    "UNIQUE_CONSTRAINT": "Ràng buộc duy nhất",
    "USERNAME_HAD_USED": "Tên người dùng này đã được đăng ký. Vui lòng sử dụng tên người dùng khác!",
    "USERNAME_OR_PASSWORD_NOT_CORRECT": "Tài khoản hoặc mật khẩu không chính xác.",
    "VALIDATION_FAILED": "Lỗi xác thực",
    "YOU_NOT_HAVE_PERMISSION": "Bạn không có quyền thực hiện hành động này."
  },
  "newPassword": "Mật khẩu mới",
  "note": "Ghi chú",
  "oldPassword": "Mật khẩu cũ",
  "password": "Mật khẩu",
  "permissions": "Quyền hạn",
  "phone": "Số điện thoại",
  "private_group": "Nhóm bí mật",
  "product": {
    "categoryId": "Loại sản phẩm",
    "stateActive": "Hoạt động",
    "stateCode": "Trạng thái hoạt động",
    "stateInactive": "Không hoạt động",
    "stateNull": "Tất cả"
  },
  "profile": "Thông tin cá nhân",
  "rawMaterials": {
    "stockTracingCode": {
      "Lot": "Nhiều",
      "None": "Không",
      "Serial": "Định kỳ"
    }
  },
  "resource": "Tài nguyên",
  "resources": "Tài nguyên",
  "second": "giây",
  "signin": "ĐĂNG NHẬP",
  "status": "Trạng thái",
  "stockLine": {
    "selectStockLine": "Tồn kho"
  },
  "stockMove": {
    "apply_btn": "THỰC THI",
    "apply_confirm_content": "Bạn có muốn thực thi việc di chuyển mặt hàng này không?",
    "apply_confirm_title": "Thực thi di chuyển mặt hàng",
    "apply_failed": "Thực thi thất bại",
    "apply_success": "Đã thực thi thành công",
    "undo_apply_btn": "HOÀN TÁC",
    "undo_apply_confirm_content": "Bạn có muốn hoàn tác việc di chuyển mặt hàng này không?",
    "undo_apply_confirm_title": "Hoàn tác di chuyển mặt hàng",
    "undo_apply_failed": "Hoàn tác thất bại",
    "undo_apply_success": "Hoàn tác thành công"
  },
  "stockRawMaterials": {
    "suplierEntered": "Nhà cung cấp đã nhập"
  },
  "user": "",
  "username": "Tên đăng nhập",
  "using": "Đang sử dụng",
  "validation": {
    "phone": "Số điện thoại không đúng"
  }
};
export default translations;
