import React from 'react';
import {
  TextInput,
  SelectInput,
} from 'react-admin';
import CustomSearchInput from 'components/commons/customSearchInput';
import { useCategories } from 'repositories/products/categories';

function ParentSelectGroup({ source }: any) {
  const { data: categories } = useCategories();
  if (!categories) return null;

  const filteredCategories = categories?.filter((e: any) => e.position < 4);
  return (
    <SelectInput
      source={source}
      choices={filteredCategories ?? []}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="query" alwaysOn placeholder_i18n="mai.keyword" />,
  <TextInput source="name" variant="outlined" inputProps={{ maxLength: 250 }} resettable />,
  <ParentSelectGroup source="parentId" variant="outlined" />,
];
