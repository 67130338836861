import QuantityInput from 'components/input/quantity/quantityInput';
import * as React from 'react';
import { TextInputProps } from 'react-admin';
import { useResourceQuery } from 'repositories/products/product';
// import styles from './product.module.scss';

interface ResourceQuantityInputProps extends TextInputProps {
  resourceId: number | undefined,
  resourceType?: 'Resource' | 'RawMaterial',
  source: string,
  label: string | undefined
  className: string | undefined
  value?: number | string
  onChange?: (event: { target: { name: string; value: string } }) => void;
  // onChange?: (value: number) => void
}

export function ResourceQuantityInput(props: ResourceQuantityInputProps) {
  const {
    resourceId, disabled, source, label, className, value, onChange, resourceType = 'Resource', ...rest
  } = props;

  const { data: resource } = useResourceQuery(
    resourceId || 0,
    { enabled: resourceType === 'Resource' && !!resourceId },
  );
  const { data: rawMaterial } = useResourceQuery(
    resourceId || 0,
    { enabled: resourceType === 'RawMaterial' && !!resourceId },
  );

  const quantityTypeId = resource ? resource.quantityTypeId : rawMaterial?.quantityTypeId;

  return (
    <QuantityInput
      {...rest}
      disabled={disabled || !resourceId}
      className={className}
      label={label}
      source={source}
      value={value}
      onChange={onChange}
      quantityType={quantityTypeId}
    />
  );
}

export default ResourceQuantityInput;
