import React from 'react';
import {
  AutocompleteInput,
  TextInput,
} from 'react-admin';
import CustomSearchInput from '../../components/commons/customSearchInput';
import { useCountries } from '../../repositories/app';

function SelectGroup({ source }: any) {
  const { data } = useCountries();
  if (!data) return null;

  return (
    <AutocompleteInput
      source={source}
      choices={data}
      variant="outlined"
    />
  );
}

export default [
  <CustomSearchInput source="query" alwaysOn placeholder_i18n="mai.keyword" />,
  <TextInput source="phone" placeholder="phone" variant="outlined" inputProps={{ maxLength: 50 }} resettable />,
  <TextInput source="tax" placeholder="tax" variant="outlined" inputProps={{ maxLength: 50 }} resettable />,
  <SelectGroup source="countryId" placeholder="country" variant="outlined" />,
  <TextInput source="state" placeholder="state" variant="outlined" inputProps={{ maxLength: 250 }} resettable />,
];
