import React from 'react';
import {
  AutocompleteInput,
  AutocompleteInputProps,
  ReferenceInput,
  required as requiredValidator,
} from 'react-admin';

interface UserInputProps extends AutocompleteInputProps {
  source: string;
  required?: boolean;
}

export default function UserInput(props: UserInputProps) {
  const { source, required, ...rest } = props;
  return (
    <ReferenceInput
      source={source}
      reference="auth/users"
      isRequired={false}
    >
      <AutocompleteInput
        {...rest}
        validate={required ? requiredValidator() : undefined}
        optionText="username"
        getOptionLabel={(el) => { console.log('el', el); return el.Username; }}
        filterToQuery={(query: string) => ({ query })}
      />
    </ReferenceInput>
  );
}
