import React from 'react';
import styles from './colorBadge.module.scss';

interface BadgesProps {
  children: React.ReactNode;
}

interface StatusBadgeProps {
  green: boolean;
  orange?: boolean;
  hidden?: boolean;
  name?: string;
  children?: React.ReactNode;
}

export function Badges(props: BadgesProps): JSX.Element {
  const {
    children,
  } = props;

  return (
    <div className={styles.badges}>
      {children}
    </div>
  );
}

export function StatusBadge(props: StatusBadgeProps): JSX.Element {
  const {
    green,
    orange,
    hidden,
    children,
    name,
  } = props;
  let badgeClass = styles.badge;
  if (green) {
    badgeClass += ` ${styles.green}`;
  } else if (orange) {
    badgeClass += ` ${styles.orange}`;
  } else {
    badgeClass += ` ${styles.red}`;
  }
  if (hidden) {
    badgeClass += ` ${styles.hidden}`;
  }

  return (
    <div className={badgeClass} aria-label={name || ''}>
      {children}
    </div>
  );
}
