import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './app/App';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  // setup sentry
  Sentry.init({
    dsn: 'https://5a29e2a754ca7dc92b3ec06b04ba24d7@o4507706464927744.ingest.de.sentry.io/4507706471284816',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/mai\.advance\.ch\/api/],
    // Session Replay

    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,

    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.Suspense>
    <App />
  </React.Suspense>,
);
