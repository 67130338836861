// import { BusinessPartnersSearchRequest } from 'api';
import { useQuery } from 'react-query';
import api from 'repositories/api';
// import api from '../api';

// eslint-disable-next-line import/prefer-default-export, max-len
export const useBusinessPartners = () => useQuery(['BusinessPartnerBusinessPartners'], async () => {
  const bp = await api.businessPartnersSearch({
    limit: 9000,
  });
  return bp.list;
});
