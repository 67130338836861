/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthUserEntity
 */
export interface AuthUserEntity {
    /**
     * 
     * @type {number}
     * @memberof AuthUserEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthUserEntity
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserEntity
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserEntity
     */
    lastLogin?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthUserEntity
     */
    lastLogout?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserEntity
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthUserEntity
     */
    isSpecialAccess: boolean;
}

/**
 * Check if a given object implements the AuthUserEntity interface.
 */
export function instanceOfAuthUserEntity(value: object): value is AuthUserEntity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isAdmin' in value) || value['isAdmin'] === undefined) return false;
    if (!('isSpecialAccess' in value) || value['isSpecialAccess'] === undefined) return false;
    return true;
}

export function AuthUserEntityFromJSON(json: any): AuthUserEntity {
    return AuthUserEntityFromJSONTyped(json, false);
}

export function AuthUserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthUserEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'lastLogin': json['lastLogin'] == null ? undefined : (new Date(json['lastLogin'])),
        'lastLogout': json['lastLogout'] == null ? undefined : (new Date(json['lastLogout'])),
        'isActive': json['isActive'],
        'isAdmin': json['isAdmin'],
        'isSpecialAccess': json['isSpecialAccess'],
    };
}

export function AuthUserEntityToJSON(value?: AuthUserEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'username': value['username'],
        'email': value['email'],
        'lastLogin': value['lastLogin'] == null ? undefined : ((value['lastLogin'] as any).toISOString()),
        'lastLogout': value['lastLogout'] == null ? undefined : ((value['lastLogout'] as any).toISOString()),
        'isActive': value['isActive'],
        'isAdmin': value['isAdmin'],
        'isSpecialAccess': value['isSpecialAccess'],
    };
}

