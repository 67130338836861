import * as React from 'react';
import {
  TopToolbar,
  ListButton,
  ShowButton,
  Show,
} from 'react-admin';
import { StockLinesLineDetail, StockMoveDetail } from 'api';
import { useParams } from 'react-router';
import { usePlannedStockMoveLines, useStockMoveDetail } from 'repositories/stock/stockMoves';
import './stockMoveLine.module.scss';
import useScanDetection from 'hooks/useScanDetection';
import constants from 'utils/constants';
import useNotify from 'utils/notify';
import api from 'repositories/api';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useTranslate from 'utils/translate';
import { useShelf } from 'repositories/stock/stockShelf';
import ShowQuantity from 'components/commons/showQuantity';
import { useStockLine } from 'repositories/stock/stockLines';

function StockLineDisplay(props: { line: StockLinesLineDetail, picked?: boolean, index: number }) {
  const { line, picked, index } = props;
  const shelf = useShelf({ id: line.stockShelfId }, { enabled: !!line.stockShelfId });
  return (
    <TableRow sx={{ color: picked ? 'green' : 'red' }}>
      <TableCell>{index}</TableCell>
      <TableCell>{line.articleNo}</TableCell>
      <TableCell>{line.resourceName}</TableCell>
      <TableCell><ShowQuantity value={line.amount} quantityTypeId={line.quantityTypeId} /></TableCell>
      <TableCell>{line.stockLot.name}</TableCell>
      <TableCell>{line.stockLot.supplierName}</TableCell>
      <TableCell>{shelf.data?.name}</TableCell>
    </TableRow>
  );
}

interface StockLinePopUpProps {
  stockMove?: StockMoveDetail,
  plannedLines?: StockLinesLineDetail[],
  onFinish: () => void,
}

function StockLinePopUp(props: StockLinePopUpProps) {
  const {
    stockMove,
    plannedLines,
    onFinish,
  } = props;

  const notify = useNotify();
  const translate = useTranslate();
  const [unplannedLineId, setUnplannedLineId] = React.useState<number>();
  const { data: unplannedLine } = useStockLine({ id: unplannedLineId as number }, { enabled: !!unplannedLineId });

  React.useEffect(() => {
    if (!unplannedLineId || !unplannedLine) {
      return;
    }
    if (!plannedLines?.find((line) => line.resourceId === unplannedLine?.resourceId)) {
      notify('unplanned item', { type: 'error' });
      setUnplannedLineId(undefined);
    }
  }, [unplannedLine, unplannedLineId]);

  const addStockLine = React.useCallback(async (line: StockLinesLineDetail) => {
    if (stockMove === undefined) {
      notify('data not ready', { type: 'error' });
      return;
    }
    const response = await api.stockMovesAddLine({
      id: stockMove.id,
      stockCreateLineDTO: {
        stockLineId: line.id,
        moveCompleteLine: true,
        amount: line.amount,
      },
    });
    if (response) {
      setUnplannedLineId(undefined);
      onFinish();
    }
  }, [stockMove, api.stockMovesAddLine]);

  useScanDetection({
    averageWaitTime: 100,
    onComplete: async (code) => {
      const barCodeScanData = code.toString().trim();
      console.log(`barcode detected ${barCodeScanData}`);

      const match = constants.Labels.STOCKLINE.regex.exec(barCodeScanData);
      if (match) {
        const stockLineId = parseInt(match[1], 10);
        const stockLine = plannedLines?.find((line) => line.id === stockLineId);
        if (!stockLine) {
          if (stockMove?.lines.find((line) => line.stockLine.id === stockLineId)) {
            notify('line already picked', { type: 'warning' });
          } else {
            setUnplannedLineId(stockLineId);
          }
          return;
        }
        await addStockLine(stockLine);
      } else {
        notify('QR not found', { type: 'error' });
      }
    },
    minLength: 2,
  });
  const onClose = () => setUnplannedLineId(undefined);

  return (
    <Dialog
      disablePortal
      disableRestoreFocus
      open={!!unplannedLine}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle
        className="header"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography typography="h5">
          Add unplanned Line?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '100%',
        }}
        >
          <Typography>
            {unplannedLine?.articleNo}
            -
            {unplannedLine?.resourceName}
            <br />
            {unplannedLine?.stockLot.name}
            <br />
            {unplannedLine?.stockLot.supplierName}
          </Typography>
          <Button
            size="small"
            onClick={() => addStockLine(unplannedLine!)}
          >
            {translate('ra.action.save')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default function ShowPlannedStockLines() {
  const { id } = useParams();
  const stockMoveId = Number(id);

  const translate = useTranslate();
  const { data: stockMove, refetch: refetch1 } = useStockMoveDetail(
    { id: stockMoveId as number },
    { enabled: !!stockMoveId },
  );
  const { data: plannedStockLines, refetch: refetch2 } = usePlannedStockMoveLines(
    { id: stockMoveId as number },
    { enabled: !!stockMoveId },
  );

  const tableRef = React.useRef<HTMLTableElement | null>(null);
  const scrollToRow = (index: number) => {
    if (tableRef.current) {
      const rows = tableRef.current.getElementsByTagName('tr');
      if (rows[index]) {
        rows[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const onFinish = React.useCallback(() => {
    const indexLastPickedItem = stockMove?.lines?.length;
    refetch1();
    refetch2();
    if (!Number.isNaN(indexLastPickedItem) && indexLastPickedItem) {
      scrollToRow(indexLastPickedItem);
    }
  }, []);

  return (
    <Show
      title=" "
      actions={(
        <TopToolbar>
          <ListButton resource="stock/stock-moves" />
          <ShowButton resource="stock/stock-moves" record={stockMove} />
        </TopToolbar>
      )}
    >
      <StockLinePopUp stockMove={stockMove} plannedLines={plannedStockLines} onFinish={onFinish} />
      <TableContainer ref={tableRef}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
              <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
              <TableCell>{translate('mai.incomingShipment.receive.Amount')}</TableCell>
              <TableCell>{translate('mai.incomingShipment.receive.LotNo')}</TableCell>
              <TableCell>{translate('resources.stock/stocklines.fields.lotNo')}</TableCell>
              <TableCell>{translate('resources.stock/stockLocations/shelf.name')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stockMove?.lines.map((line, index) => (
              <StockLineDisplay line={line.stockLine} picked key={line.id} index={index + 1} />
            ))}
            {plannedStockLines?.map((line, index) => (
              <StockLineDisplay line={line} key={line.id} index={-(index + 1)} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Show>
  );
}
