/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Paging } from './Paging';
import {
    PagingFromJSON,
    PagingFromJSONTyped,
    PagingToJSON,
} from './Paging';
import type { StockShelfSchema } from './StockShelfSchema';
import {
    StockShelfSchemaFromJSON,
    StockShelfSchemaFromJSONTyped,
    StockShelfSchemaToJSON,
} from './StockShelfSchema';

/**
 * 
 * @export
 * @interface PaginatedListShelfSchema
 */
export interface PaginatedListShelfSchema {
    /**
     * 
     * @type {Array<StockShelfSchema>}
     * @memberof PaginatedListShelfSchema
     */
    list: Array<StockShelfSchema>;
    /**
     * 
     * @type {Paging}
     * @memberof PaginatedListShelfSchema
     */
    paging: Paging;
}

/**
 * Check if a given object implements the PaginatedListShelfSchema interface.
 */
export function instanceOfPaginatedListShelfSchema(value: object): value is PaginatedListShelfSchema {
    if (!('list' in value) || value['list'] === undefined) return false;
    if (!('paging' in value) || value['paging'] === undefined) return false;
    return true;
}

export function PaginatedListShelfSchemaFromJSON(json: any): PaginatedListShelfSchema {
    return PaginatedListShelfSchemaFromJSONTyped(json, false);
}

export function PaginatedListShelfSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedListShelfSchema {
    if (json == null) {
        return json;
    }
    return {
        
        'list': ((json['list'] as Array<any>).map(StockShelfSchemaFromJSON)),
        'paging': PagingFromJSON(json['paging']),
    };
}

export function PaginatedListShelfSchemaToJSON(value?: PaginatedListShelfSchema | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': ((value['list'] as Array<any>).map(StockShelfSchemaToJSON)),
        'paging': PagingToJSON(value['paging']),
    };
}

