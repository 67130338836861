import { AppQuantityTypeOption, StockLinesLineDetail } from 'api';
import { ShowQuantityBase } from 'components/commons/showQuantity';
import dayjs from 'dayjs';
import React from 'react';
import QRCode from 'react-qr-code';
import constants from 'utils/constants';
import exportPDF from 'utils/exportPDF';
import styles from './qrCode.module.scss';

export interface StockLineQrCodeProps extends StockLinesLineDetail {
  quantityType?: AppQuantityTypeOption
}

const formatId = (id: number) => {
  let remaining = id;
  const chars = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
  const base = chars.length;
  const result = [];
  while (remaining > 0) {
    result.push(remaining % base);
    remaining = Math.floor(remaining / base);
  }

  return result.reverse().map((x) => chars[x]).join('');
};

function StockLineQrCode(props: StockLineQrCodeProps) {
  const {
    id,
    resourceName,
    articleNo,
    stockLot,
    labelId,
    amount,
    quantityType,
  } = props;

  console.log(id, formatId(id));

  const formattedDate = dayjs(stockLot.date).format('YYYY MM DD');
  return (
    <div className={styles.qr_code}>
      <div className="title">
        {resourceName}
      </div>
      <div className="content">
        <div style={{ minWidth: '42mm', fontSize: '12px' }}>
          <div style={{ fontSize: '20px' }}>
            <span className="bold">#&nbsp;</span>
            <span className="bold">
              {articleNo}
            </span>
          </div>
          <div style={{ fontSize: '14px' }}>
            <span className="bold">QTY: </span>
            <span className="bold">
              <ShowQuantityBase value={amount} quantityType={quantityType} />
            </span>
          </div>
          <div style={{ fontSize: '14px' }}>
            <span className="bold">ARR: </span>
            <span className="bold">{formattedDate}</span>
          </div>
          <div>
            <span className="bold">LOT: </span>
            <span style={{ maxWidth: '35mm' }}>
              {stockLot.name}
              &nbsp;(
              {labelId}
              )
            </span>
          </div>
          {stockLot.supplierName && (
            <div>
              <span className="bold">BATCH: </span>
              <span style={{ maxWidth: '35mm' }}>{stockLot.supplierName}</span>
            </div>
          )}
        </div>
        <QRCode
          className="code"
          value={`SL${id}_${amount}`}
        />
      </div>
    </div>
  );
}

export default function exportStockLineQrCode(data: StockLinesLineDetail[], quantityTypes?: AppQuantityTypeOption[]) {
  exportPDF(
    (
      <>
        {data.map((x) => (
          <StockLineQrCode
            {...x}
            quantityType={quantityTypes?.find((q) => q.id === x.quantityTypeId)}
          />
        ))}
      </>
    ),
    constants.SizePrint.k58,
  );
}
