import PurchaseOrderCreate from './create';
import PurchaseOrderEdit from './edit';
import PurchaseOrderList from './list';
import PurchaseOrderShow from './show';

export default {
  name: 'purchasing/purchase-order',
  list: PurchaseOrderList,
  show: PurchaseOrderShow,
  create: PurchaseOrderCreate,
  edit: PurchaseOrderEdit,
  options: {
    label: 'Purchase Orders',
  },
};
