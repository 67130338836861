/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Paging
 */
export interface Paging {
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    totalPages: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    total: number;
}

/**
 * Check if a given object implements the Paging interface.
 */
export function instanceOfPaging(value: object): value is Paging {
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('totalPages' in value) || value['totalPages'] === undefined) return false;
    if (!('limit' in value) || value['limit'] === undefined) return false;
    if (!('total' in value) || value['total'] === undefined) return false;
    return true;
}

export function PagingFromJSON(json: any): Paging {
    return PagingFromJSONTyped(json, false);
}

export function PagingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Paging {
    if (json == null) {
        return json;
    }
    return {
        
        'page': json['page'],
        'totalPages': json['totalPages'],
        'limit': json['limit'],
        'total': json['total'],
    };
}

export function PagingToJSON(value?: Paging | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'page': value['page'],
        'totalPages': value['totalPages'],
        'limit': value['limit'],
        'total': value['total'],
    };
}

