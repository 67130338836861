/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockQuantityTypeEntity } from './StockQuantityTypeEntity';
import {
    StockQuantityTypeEntityFromJSON,
    StockQuantityTypeEntityFromJSONTyped,
    StockQuantityTypeEntityToJSON,
} from './StockQuantityTypeEntity';

/**
 * 
 * @export
 * @interface ProductRawMaterialEntity
 */
export interface ProductRawMaterialEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    articleNo?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialEntity
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductRawMaterialEntity
     */
    isUniqueLotNo: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    currencyId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    stockTracing: ProductRawMaterialEntityStockTracingEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    quantityTypeId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductRawMaterialEntity
     */
    categories: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    supplierId: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    leadTime?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    supplierArticleNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    supplierArticleName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    supplierQuantityTypeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    supplierQuantityFactor?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    moq?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    reserveQuantity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    stockConditioning?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    supplierConditioning?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    weightPerUnit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    hsCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    originCountryId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    incoterm?: ProductRawMaterialEntityIncotermEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRawMaterialEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    currencyName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    currencySymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRawMaterialEntity
     */
    supplierName: string;
    /**
     * 
     * @type {StockQuantityTypeEntity}
     * @memberof ProductRawMaterialEntity
     */
    quantityType: StockQuantityTypeEntity;
    /**
     * 
     * @type {StockQuantityTypeEntity}
     * @memberof ProductRawMaterialEntity
     */
    supplierQuantityType?: StockQuantityTypeEntity;
}


/**
 * @export
 */
export const ProductRawMaterialEntityStockTracingEnum = {
    None: 'None',
    Lot: 'Lot',
    Serial: 'Serial'
} as const;
export type ProductRawMaterialEntityStockTracingEnum = typeof ProductRawMaterialEntityStockTracingEnum[keyof typeof ProductRawMaterialEntityStockTracingEnum];

/**
 * @export
 */
export const ProductRawMaterialEntityIncotermEnum = {
    Cfr: 'CFR',
    Cif: 'CIF',
    Ddu: 'DDU',
    Exw: 'EXW',
    Fob: 'FOB'
} as const;
export type ProductRawMaterialEntityIncotermEnum = typeof ProductRawMaterialEntityIncotermEnum[keyof typeof ProductRawMaterialEntityIncotermEnum];


/**
 * Check if a given object implements the ProductRawMaterialEntity interface.
 */
export function instanceOfProductRawMaterialEntity(value: object): value is ProductRawMaterialEntity {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('isActive' in value) || value['isActive'] === undefined) return false;
    if (!('isUniqueLotNo' in value) || value['isUniqueLotNo'] === undefined) return false;
    if (!('currencyId' in value) || value['currencyId'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('stockTracing' in value) || value['stockTracing'] === undefined) return false;
    if (!('quantityTypeId' in value) || value['quantityTypeId'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('supplierId' in value) || value['supplierId'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('currencyName' in value) || value['currencyName'] === undefined) return false;
    if (!('currencySymbol' in value) || value['currencySymbol'] === undefined) return false;
    if (!('supplierName' in value) || value['supplierName'] === undefined) return false;
    if (!('quantityType' in value) || value['quantityType'] === undefined) return false;
    return true;
}

export function ProductRawMaterialEntityFromJSON(json: any): ProductRawMaterialEntity {
    return ProductRawMaterialEntityFromJSONTyped(json, false);
}

export function ProductRawMaterialEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRawMaterialEntity {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'articleNo': json['articleNo'] == null ? undefined : json['articleNo'],
        'isActive': json['isActive'],
        'isUniqueLotNo': json['isUniqueLotNo'],
        'currencyId': json['currencyId'],
        'price': json['price'],
        'stockTracing': json['stockTracing'],
        'quantityTypeId': json['quantityTypeId'],
        'categories': json['categories'],
        'image': json['image'] == null ? undefined : json['image'],
        'supplierId': json['supplierId'],
        'leadTime': json['leadTime'] == null ? undefined : json['leadTime'],
        'supplierArticleNo': json['supplierArticleNo'] == null ? undefined : json['supplierArticleNo'],
        'supplierArticleName': json['supplierArticleName'] == null ? undefined : json['supplierArticleName'],
        'supplierQuantityTypeId': json['supplierQuantityTypeId'] == null ? undefined : json['supplierQuantityTypeId'],
        'supplierQuantityFactor': json['supplierQuantityFactor'] == null ? undefined : json['supplierQuantityFactor'],
        'moq': json['moq'] == null ? undefined : json['moq'],
        'reserveQuantity': json['reserveQuantity'] == null ? undefined : json['reserveQuantity'],
        'stockConditioning': json['stockConditioning'] == null ? undefined : json['stockConditioning'],
        'supplierConditioning': json['supplierConditioning'] == null ? undefined : json['supplierConditioning'],
        'weightPerUnit': json['weightPerUnit'] == null ? undefined : json['weightPerUnit'],
        'hsCode': json['hsCode'] == null ? undefined : json['hsCode'],
        'originCountryId': json['originCountryId'] == null ? undefined : json['originCountryId'],
        'incoterm': json['incoterm'] == null ? undefined : json['incoterm'],
        'id': json['id'],
        'currencyName': json['currencyName'],
        'currencySymbol': json['currencySymbol'],
        'supplierName': json['supplierName'],
        'quantityType': StockQuantityTypeEntityFromJSON(json['quantityType']),
        'supplierQuantityType': json['supplierQuantityType'] == null ? undefined : StockQuantityTypeEntityFromJSON(json['supplierQuantityType']),
    };
}

export function ProductRawMaterialEntityToJSON(value?: ProductRawMaterialEntity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'articleNo': value['articleNo'],
        'isActive': value['isActive'],
        'isUniqueLotNo': value['isUniqueLotNo'],
        'currencyId': value['currencyId'],
        'price': value['price'],
        'stockTracing': value['stockTracing'],
        'quantityTypeId': value['quantityTypeId'],
        'categories': value['categories'],
        'image': value['image'],
        'supplierId': value['supplierId'],
        'leadTime': value['leadTime'],
        'supplierArticleNo': value['supplierArticleNo'],
        'supplierArticleName': value['supplierArticleName'],
        'supplierQuantityTypeId': value['supplierQuantityTypeId'],
        'supplierQuantityFactor': value['supplierQuantityFactor'],
        'moq': value['moq'],
        'reserveQuantity': value['reserveQuantity'],
        'stockConditioning': value['stockConditioning'],
        'supplierConditioning': value['supplierConditioning'],
        'weightPerUnit': value['weightPerUnit'],
        'hsCode': value['hsCode'],
        'originCountryId': value['originCountryId'],
        'incoterm': value['incoterm'],
        'id': value['id'],
        'currencyName': value['currencyName'],
        'currencySymbol': value['currencySymbol'],
        'supplierName': value['supplierName'],
        'quantityType': StockQuantityTypeEntityToJSON(value['quantityType']),
        'supplierQuantityType': StockQuantityTypeEntityToJSON(value['supplierQuantityType']),
    };
}

