/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppUserPermissions
 */
export interface AppUserPermissions {
    /**
     * 
     * @type {string}
     * @memberof AppUserPermissions
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof AppUserPermissions
     */
    httpMethod: string;
    /**
     * 
     * @type {string}
     * @memberof AppUserPermissions
     */
    methodName: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppUserPermissions
     */
    isAuthorized: boolean;
}

/**
 * Check if a given object implements the AppUserPermissions interface.
 */
export function instanceOfAppUserPermissions(value: object): value is AppUserPermissions {
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('httpMethod' in value) || value['httpMethod'] === undefined) return false;
    if (!('methodName' in value) || value['methodName'] === undefined) return false;
    if (!('isAuthorized' in value) || value['isAuthorized'] === undefined) return false;
    return true;
}

export function AppUserPermissionsFromJSON(json: any): AppUserPermissions {
    return AppUserPermissionsFromJSONTyped(json, false);
}

export function AppUserPermissionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppUserPermissions {
    if (json == null) {
        return json;
    }
    return {
        
        'url': json['url'],
        'httpMethod': json['httpMethod'],
        'methodName': json['methodName'],
        'isAuthorized': json['isAuthorized'],
    };
}

export function AppUserPermissionsToJSON(value?: AppUserPermissions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'url': value['url'],
        'httpMethod': value['httpMethod'],
        'methodName': value['methodName'],
        'isAuthorized': value['isAuthorized'],
    };
}

