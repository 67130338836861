import React from 'react';
import {
  SimpleForm,
  HttpError,
  useRefresh,
  Edit,
  Toolbar,
  ShowButton,
  useResourceContext,
  useCreatePath,
} from 'react-admin';
import {
  Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { StockInventoryControlLineCreate, StockInventoryControlLineSchema } from 'api';
import useTranslate from 'utils/translate';
import useNotify from 'utils/notify';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'repositories/api';
import useHasAccess from 'hooks/useHasAccess';
import { useInventoryControlDetail, useInventoryControlLines } from 'repositories/stock/inventoryControls';
import urls from 'app/urls';
import { useApiCall } from 'repositories/base';
import InventoryControlPopup from './popup/popupAddLine';

interface InventoryControlTableProps {
  lines: StockInventoryControlLineSchema[];
  onDelete?: (id: number) => void;
}

export function InventoryControlTable(props: InventoryControlTableProps) {
  const { lines, onDelete } = props;
  const translate = useTranslate();

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.ArticleNo')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.Resource')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.LotNo')}</TableCell>
            <TableCell>{translate('mai.incomingShipment.receive.Amount')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((row, index: number) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row?.articleNo}</TableCell>
              <TableCell>{row?.articleName}</TableCell>
              <TableCell>{row?.lotNo}</TableCell>
              <TableCell>{row?.amount.toLocaleString('en-US')}</TableCell>
              <TableCell>
                <IconButton
                  color="error"
                  onClick={() => (row.id && onDelete && onDelete(row.id))}
                  disabled={!onDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function () {
  const translate = useTranslate();
  const hasAccess = useHasAccess();
  const navigate = useNavigate();
  const createPath = useCreatePath();
  const notify = useNotify();
  const deleteLineRequest = useApiCall(api.inventoryControlDeleteLine);
  const resource = useResourceContext();

  const { id: rawId } = useParams();
  const id = Number(rawId);
  const { data } = useInventoryControlDetail({ id });
  const lines = useInventoryControlLines({ id });

  const canFinish = hasAccess(urls.InventoryControl_SetFinshedInventoryControl);
  const showPath = createPath({ type: 'show', resource, id });

  const refresh = useRefresh();

  const handleSave = async (dataSubmit: StockInventoryControlLineCreate) => {
    if (dataSubmit.amount === undefined || dataSubmit.stockLineId === undefined) {
      throw new Error(`invalid amount ${dataSubmit}`);
    }
    const response = await api.inventoryControlAddLine({
      id,
      stockInventoryControlLineCreate: {
        amount: dataSubmit.amount,
        stockLineId: dataSubmit.stockLineId,
        comment: dataSubmit.comment,
        shelfId: dataSubmit.shelfId,
      },
    });
    if (response.code === 200) {
      lines.refetch();
      notify('Record created', { type: 'success' });
    }
  };

  const handleConfirm = async (isFinished: boolean) => {
    try {
      const response = await api.inventoryControlSetFinshedInventoryControl({ id, isFinished });
      if (response.code === 200) {
        refresh();
        notify('Record confirmed', { type: 'success' });
      }
    } catch (e) {
      if (e instanceof HttpError) {
        notify('Not have permisson', { type: 'error' });
      }
    }
  };
  const deleteLine = async (lineId: number) => {
    await deleteLineRequest({ id, lineId });
    refresh();
  };

  return (
    <div>
      <Edit>
        {lines.data && (
          <InventoryControlTable lines={lines.data} onDelete={data?.finishedDate ? undefined : deleteLine} />
        )}
      </Edit>
      <SimpleForm
        mode="onSubmit"
        toolbar={
          (
            <Toolbar>

              {!data?.applyDate
                && canFinish
                && (
                  <Button
                    onClick={() => {
                      if (!data?.applyDate && data?.finishedDate) {
                        handleConfirm(false);
                      } else {
                        handleConfirm(true);
                        navigate(showPath);
                      }
                    }}
                  >
                    {!data?.finishedDate ? translate('ra.action.finish') : translate('ra.action.open')}
                  </Button>
                )}
            </Toolbar>
          )
        }
        // @ts-ignore
        onSubmit={handleSave}
      >
        {data && <InventoryControlPopup onSubmit={handleSave} inventoryControl={data} />}
      </SimpleForm>
      <ShowButton />
    </div>
  );
}
