import * as React from 'react';
import {
  Edit,
  SimpleForm,
} from 'react-admin';
import EditToolBar from 'components/commons/editToolBar';
import useHasAccess from 'hooks/useHasAccess';
import { useGroupsQuery } from 'repositories/auth/users';
import EditActions from './editActions';
import UserForm from './userForm';

export default React.memo(() => {
  const hasAccess = useHasAccess();
  const canPut = hasAccess({ url: 'auth/users/{id}', httpMethod: 'PUT' });
  const canDelete = hasAccess({ url: 'auth/users/{id}', httpMethod: 'DELETE' });
  const canSearch = hasAccess({ url: 'auth/users/', httpMethod: 'GET' });
  const canShow = hasAccess({ url: 'auth/users/{id}', httpMethod: 'GET' });
  const { data: groupsData } = useGroupsQuery();

  if (!groupsData) return null;
  // const { groups } = groupsData;

  return (
    <div className="mai-form">
      <Edit
        actions={(
          <EditActions
            isShow={canShow}
            isList={canSearch}
          />
        )}
        mutationMode="pessimistic"
      >
        <SimpleForm toolbar={<EditToolBar isSave={canPut} isDelete={canDelete} />}>
          <UserForm />
        </SimpleForm>
      </Edit>
    </div>
  );
});
