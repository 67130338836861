/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockMovePlan } from './StockMovePlan';
import {
    StockMovePlanFromJSON,
    StockMovePlanFromJSONTyped,
    StockMovePlanToJSON,
} from './StockMovePlan';
import type { StockMoveLineEntity } from './StockMoveLineEntity';
import {
    StockMoveLineEntityFromJSON,
    StockMoveLineEntityFromJSONTyped,
    StockMoveLineEntityToJSON,
} from './StockMoveLineEntity';

/**
 * 
 * @export
 * @interface StockMoveDetail
 */
export interface StockMoveDetail {
    /**
     * 
     * @type {number}
     * @memberof StockMoveDetail
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof StockMoveDetail
     */
    plannedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    comment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveDetail
     */
    sourceStockLocationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveDetail
     */
    destinationStockLocationId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StockMoveDetail
     */
    appliedDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveDetail
     */
    appliedBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    recipient?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    applier?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StockMoveDetail
     */
    totalAmount: number;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    createdBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    sourceStockLocationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StockMoveDetail
     */
    destinationStockLocationName?: string | null;
    /**
     * 
     * @type {Array<StockMoveLineEntity>}
     * @memberof StockMoveDetail
     */
    lines: Array<StockMoveLineEntity>;
    /**
     * 
     * @type {StockMovePlan}
     * @memberof StockMoveDetail
     */
    billOfResources?: StockMovePlan;
    /**
     * 
     * @type {boolean}
     * @memberof StockMoveDetail
     */
    canUndo: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StockMoveDetail
     */
    canApply: boolean;
}

/**
 * Check if a given object implements the StockMoveDetail interface.
 */
export function instanceOfStockMoveDetail(value: object): value is StockMoveDetail {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('totalAmount' in value) || value['totalAmount'] === undefined) return false;
    if (!('lines' in value) || value['lines'] === undefined) return false;
    if (!('canUndo' in value) || value['canUndo'] === undefined) return false;
    if (!('canApply' in value) || value['canApply'] === undefined) return false;
    return true;
}

export function StockMoveDetailFromJSON(json: any): StockMoveDetail {
    return StockMoveDetailFromJSONTyped(json, false);
}

export function StockMoveDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMoveDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'plannedDate': json['plannedDate'] == null ? undefined : (new Date(json['plannedDate'])),
        'comment': json['comment'] == null ? undefined : json['comment'],
        'sourceStockLocationId': json['sourceStockLocationId'] == null ? undefined : json['sourceStockLocationId'],
        'destinationStockLocationId': json['destinationStockLocationId'] == null ? undefined : json['destinationStockLocationId'],
        'appliedDate': json['appliedDate'] == null ? undefined : (new Date(json['appliedDate'])),
        'appliedBy': json['appliedBy'] == null ? undefined : json['appliedBy'],
        'recipient': json['recipient'] == null ? undefined : json['recipient'],
        'applier': json['applier'] == null ? undefined : json['applier'],
        'totalAmount': json['totalAmount'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'sourceStockLocationName': json['sourceStockLocationName'] == null ? undefined : json['sourceStockLocationName'],
        'destinationStockLocationName': json['destinationStockLocationName'] == null ? undefined : json['destinationStockLocationName'],
        'lines': ((json['lines'] as Array<any>).map(StockMoveLineEntityFromJSON)),
        'billOfResources': json['billOfResources'] == null ? undefined : StockMovePlanFromJSON(json['billOfResources']),
        'canUndo': json['canUndo'],
        'canApply': json['canApply'],
    };
}

export function StockMoveDetailToJSON(value?: StockMoveDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'plannedDate': value['plannedDate'] == null ? undefined : ((value['plannedDate'] as any).toISOString()),
        'comment': value['comment'],
        'sourceStockLocationId': value['sourceStockLocationId'],
        'destinationStockLocationId': value['destinationStockLocationId'],
        'appliedDate': value['appliedDate'] == null ? undefined : ((value['appliedDate'] as any).toISOString()),
        'appliedBy': value['appliedBy'],
        'recipient': value['recipient'],
        'applier': value['applier'],
        'totalAmount': value['totalAmount'],
        'createdBy': value['createdBy'],
        'sourceStockLocationName': value['sourceStockLocationName'],
        'destinationStockLocationName': value['destinationStockLocationName'],
        'lines': ((value['lines'] as Array<any>).map(StockMoveLineEntityToJSON)),
        'billOfResources': StockMovePlanToJSON(value['billOfResources']),
        'canUndo': value['canUndo'],
        'canApply': value['canApply'],
    };
}

