import * as React from 'react';
import {
  UserMenu,
  MenuItemLink,
  useLogout,
  useUserMenu,
  useTheme,
  useLocales,
  useLocaleState,
  useI18nProvider,
} from 'react-admin';
import { MouseEvent, useState } from 'react';
// import SettingsIcon from '@mui/icons-material/Settings';
import LockIcon from '@mui/icons-material/Lock';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import useTranslate from 'utils/translate';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';
import { GetMessagesCsv } from 'i18n/i18nProvider';
import useHasAccess from 'hooks/useHasAccess';
import DownloadIcon from '@mui/icons-material/Download';
import { useHijack } from 'resources/auth/users/hijack';

const ProfileMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="/profile"
      primaryText={translate('mai.profile')}
      leftIcon={<ManageAccountsIcon />}
      onClick={onClose}
    />
  );
});

const ChangePasswordMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="/change-password"
      primaryText={translate('mai.changePassword')}
      leftIcon={<LockIcon />}
      onClick={onClose}
    />
  );
});

const ReleaseHijack = React.forwardRef((props, ref) => {
  // const translate = useTranslate();
  const hijack = useHijack();
  if (!hijack.isActive) {
    return null;
  }
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="#"
      primaryText="Release Hijack"
      leftIcon={<ExitIcon />}
      onClick={hijack.reset}
      disabled={!hijack.isActive}
    />
  );
});

const LogoutMenu = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const logout = useLogout();
  const translate = useTranslate();
  const handleClick = () => {
    logout();
    onClose();
  };
  return (
    <MenuItemLink
      ref={ref}
      {...props}
      to="#"
      primaryText={translate('mai.logout')}
      leftIcon={<ExitIcon />}
      onClick={handleClick}
    />
  );
});

const ToggleThemeMenu = React.forwardRef((props, ref) => {
  const [theme, setTheme] = useTheme();

  const handleTogglePaletteType = (): void => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  const toggleThemeText = theme === 'dark' ? 'mai.dark' : 'mai.light';

  return (
    <MenuItemLink
      ref={ref}
      to="#"
      primaryText={toggleThemeText}
      leftIcon={theme === 'dark' ? (
        <Brightness7Icon />
      ) : (
        <Brightness4Icon />
      )}
      onClick={handleTogglePaletteType}
    />
  );
});

function DownloadTranslations() {
  const translate = useTranslate();
  const i18nProvider = useI18nProvider();
  const hasAccess = useHasAccess();
  const download = () => {
    const locales = i18nProvider.getLocale();
    console.log('download translations', locales);
    // @ts-ignore
    GetMessagesCsv();
  };

  if (!hasAccess({ url: 'auth/users', httpMethod: 'POST' })) {
    // can not create user => !admin
    return null;
  }

  return (
    <MenuItemLink
      onClick={download}
      to="#"
      primaryText={translate('mai.downloadTranslations')}
      leftIcon={<DownloadIcon />}
    />
  );
}

const LocalesMenu = React.forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const languages = useLocales({
    locales: [
      { locale: 'en', name: 'English' },
      { locale: 'vi', name: 'Tiếng việt' },
      { locale: '-', name: 'DEBUG' },
    ],
  });
  const [locale, setLocale] = useLocaleState();

  const getNameForLocale = (loc: string): string => {
    const language = languages.find((lang) => lang.locale === loc);
    return language ? language.name : '';
  };

  const changeLocale = (loc: string) => (): void => {
    setLocale(loc);
    setAnchorEl(null);
  };

  const handleLanguageClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const content = (
    <>
      <Button
        color="inherit"
        aria-controls="simple-menu"
        aria-label=""
        aria-haspopup="true"
        onClick={handleLanguageClick}
        endIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {getNameForLocale(locale)}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.locale}
            onClick={changeLocale(language.locale)}
            selected={language.locale === locale}
          >
            {language.name}
          </MenuItem>
        ))}
        <DownloadTranslations />
      </Menu>
    </>
  );

  return (
    <MenuItemLink
      ref={ref}
      to="#"
      primaryText={content}
      leftIcon={<LanguageIcon />}
      onClick={handleLanguageClick}
    />
  );
});

// const ConfigurationMenu = React.forwardRef((props, ref) => {
//   const { onClose } = useUserMenu();
//   const translate = useTranslate();
//   return (
//     <MenuItemLink
//       ref={ref}
//       {...props}
//       to="/configuration"
//       primaryText={translate('mai.configuration')}
//       leftIcon={<SettingsIcon />}
//       onClick={onClose}
//     />
//   );
// });

export default React.memo((props: any) => (
  <UserMenu {...props}>
    <ProfileMenu />
    <ChangePasswordMenu />
    <LocalesMenu />
    <ToggleThemeMenu />
    <ReleaseHijack />
    <LogoutMenu />
    {/* <ConfigurationMenu /> */}
  </UserMenu>
));
