import * as React from 'react';
import { Typography } from '@mui/material';
import useTranslate from 'utils/translate';

interface TitleProps {
  className?: string;
}

export default React.memo((props: TitleProps) => {
  const translate = useTranslate();
  const { className } = props;
  return (
    <Typography
      className={className}
      variant="caption"
      display="block"
      textAlign="left"
      sx={{ fontSize: 'large', fontWeight: 'bold' }}
    >
      {translate('mai.detail')}
    </Typography>
  );
});
