import React from 'react';
import { Create } from 'react-admin';
import ProductionItemForm from './form/ProductionItemForm';

export default function CreateProductionContainer() {
  return (
    <Create redirect="show">
      <ProductionItemForm />
    </Create>
  );
}
