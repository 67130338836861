/* tslint:disable */
/* eslint-disable */
/**
 * MAI.API HTTP API
 * The MAI.API Service HTTP API
 *
 * The version of the OpenAPI document: v1.0
 * Contact: smn@advance.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { StockPlannedMoveLine } from './StockPlannedMoveLine';
import {
    StockPlannedMoveLineFromJSON,
    StockPlannedMoveLineFromJSONTyped,
    StockPlannedMoveLineToJSON,
} from './StockPlannedMoveLine';

/**
 * 
 * @export
 * @interface StockMovePlan
 */
export interface StockMovePlan {
    /**
     * 
     * @type {Array<StockPlannedMoveLine>}
     * @memberof StockMovePlan
     */
    lines: Array<StockPlannedMoveLine>;
}

/**
 * Check if a given object implements the StockMovePlan interface.
 */
export function instanceOfStockMovePlan(value: object): value is StockMovePlan {
    if (!('lines' in value) || value['lines'] === undefined) return false;
    return true;
}

export function StockMovePlanFromJSON(json: any): StockMovePlan {
    return StockMovePlanFromJSONTyped(json, false);
}

export function StockMovePlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockMovePlan {
    if (json == null) {
        return json;
    }
    return {
        
        'lines': ((json['lines'] as Array<any>).map(StockPlannedMoveLineFromJSON)),
    };
}

export function StockMovePlanToJSON(value?: StockMovePlan | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lines': ((value['lines'] as Array<any>).map(StockPlannedMoveLineToJSON)),
    };
}

