import * as React from 'react';
import { Menu, MenuItemLink, MenuProps } from 'react-admin';
import useTranslate from 'utils/translate';
import useHasAccess from 'hooks/useHasAccess';
import { matchPath } from 'react-router';
import SubMenu from './submenu';
import menus, { ChildMenu, ParentMenu } from './menus';

export default function (props: MenuProps) {
  const translate = useTranslate();
  const { onMenuClick } = props;
  const [currentMenus, setCurrentMenus] = React.useState(menus);
  const hasAccess = useHasAccess();

  React.useEffect(() => {
    const activeMenus: ParentMenu[] = menus?.map((menu) => {
      const children = menu.children.filter((child) => {
        console.log(child.url, child.permissionEndpoint);
        if (!child.url && !child.permissionEndpoint) {
          return true;
        }
        const result = hasAccess(child.permissionEndpoint ?? { url: child.url, httpMethod: 'GET' });
        return result;
      });

      const isOpen = !!children.find((child) => matchPath({ path: child.url, end: false }, window.location.pathname));

      return {
        ...menu,
        children,
        isOpen,
      };
    }).filter((menu) => menu.children.length > 0);
    setCurrentMenus(activeMenus);
  }, [hasAccess]);

  const handleToggle = (name: string) => {
    const tmpMenu = [...currentMenus];
    tmpMenu.forEach((item, idx) => {
      if (item.name === name) {
        tmpMenu[idx].isOpen = tmpMenu[idx].isOpen !== true;
      } else {
        tmpMenu[idx].isOpen = false;
      }
    });
    setCurrentMenus(tmpMenu);
  };
  return (
    <Menu
      {...props}
      sx={{
        width: 250,
        '&.RaMenu-closed': {
          width: 50,
        },
      }}
      className="submenu"
    >
      {(currentMenus ?? []).map((item: ParentMenu) => (
        item.children.length === 0 && item.url
          ? (
            <MenuItemLink
              key={item.name}
              to={item.url}
              primaryText={item.name}
              leftIcon={item.icon}
              onClick={onMenuClick}
              className="single-menu"
            />
          )
          : (
            <SubMenu
              key={item.name}
              handleToggle={() => handleToggle(item.name)}
              isOpen={item.isOpen}
              name={translate(item.name)}
              icon={item.icon}
            >
              {item.children.map((child: ChildMenu) => (
                <MenuItemLink
                  key={child.name}
                  to={child.url}
                  leftIcon={child.icon}
                  primaryText={child.name}
                  onClick={onMenuClick}
                />
              ))}
            </SubMenu>
          )
      ))}
    </Menu>
  );
}
