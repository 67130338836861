import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useLocaleState } from 'react-admin';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import allLocales from '@fullcalendar/core/locales-all';

export default React.memo(() => {
  const [locale] = useLocaleState();

  return (
    <Card>
      <Title title="mai.dashboard" />
      <CardContent>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          locales={allLocales}
          locale={locale}
        />
      </CardContent>
    </Card>
  );
});
