import { AuthProvider, HttpError, UserIdentity } from 'react-admin';
import { retry } from 'repositories/base';
import api, { getCsrf } from '../repositories/api';

const authProvider: AuthProvider = {
  login: async (params) => {
    await api.loginLogin({
      authLoginUser: {
        ...params,
      },
      useCookie: true,
    });
    let beforeUrl = localStorage.getItem('beforeUrl');
    if (beforeUrl === undefined || beforeUrl === null || beforeUrl === '' || beforeUrl === '/login') {
      beforeUrl = '/';
    }
    localStorage.removeItem('beforeUrl');
    return Promise.resolve({ redirectTo: beforeUrl });
  },
  logout: async () => {
    try {
      await api.loginLogout();
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 401) {
          console.log(e);
        }
      }
    }
    localStorage.removeItem('identity');
  },
  checkAuth: () => {
    const csrf = getCsrf();
    if (csrf) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getIdentity: async () => {
    const appstate = localStorage.getItem('identity');
    if (appstate) {
      const state: UserIdentity = JSON.parse(appstate);
      return state;
    }

    const res = await api.profileGet();
    const data = {
      id: res.id!,
      fullName: res.username,
      avatar: undefined,
    };
    localStorage.setItem('identity', JSON.stringify(data));
    return data;
  },
  checkError: async () => {
    const result = await retry(() => api.loginGetLogin(), [], 5);

    if (!result) {
      throw new Error('not logged in');
    }
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
