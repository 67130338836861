import { Grid } from '@mui/material';
import { StockShelfSchema } from 'api';
import { CustomImageInput } from 'components/input/image/customImageInput';
import * as React from 'react';
import {
  Loading, ReferenceInput, required, SelectInput, SimpleForm, TextInput, useRecordContext,
} from 'react-admin';

import { useResourcesQuery } from 'repositories/products/product';
import { Source } from 'utils/source';

export default function ShelfForm() {
  const { data: resources } = useResourcesQuery();
  const s = Source<StockShelfSchema>();
  const record = useRecordContext();
  if (!resources) return <Loading />;

  return (
    <SimpleForm>
      <Grid container spacing={2} rowSpacing={0.1}>
        <Grid item xs={2} md={2} />
        <Grid item xs={4} md={4}>
          <TextInput {...s('name')} fullWidth variant="outlined" helperText={false} validate={[required()]} />
        </Grid>
        <Grid item xs={4} md={4}>
          <ReferenceInput label="Stocklocations" {...s('stockLocationId')} reference="stock/stocklocations">
            <SelectInput
              optionText="name"
              fullWidth
              validate={[required()]}
              sx={{ marginTop: '0px' }}
              disabled={record?.isDisableStockLocation ?? false}
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={8} md={8}>
          <TextInput {...s('comment')} fullWidth variant="outlined" helperText={false} />
        </Grid>
        <Grid item xs={2} md={2} />

        <Grid item xs={2} md={2} />
        <Grid item xs={8} md={8}>
          <CustomImageInput
            label="resources.product/raw-materials.fields.image"
            source="image"
          />
        </Grid>
        <Grid item xs={2} md={2} />
      </Grid>
    </SimpleForm>
  );
}
