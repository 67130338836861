import { TypographyProps } from '@mui/material';
import * as React from 'react';
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';
import { useQuantityType } from 'repositories/app';
import ShowQuantity from 'components/commons/showQuantity';

interface QuantityTypeShowProps extends TypographyProps {
  source: string,
  // eslint-disable-next-line react/require-default-props
  idsource?: string;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  label?: string;

}

export default function ({ source, idsource = 'quantityTypeId' }: QuantityTypeShowProps) {
  const record = useRecordContext();
  const quantityType = useQuantityType(get(record, idsource));

  return <ShowQuantity value={get(record, source)} quantityTypeId={quantityType?.id} />;
}
