import BalanceIcon from '@mui/icons-material/Balance';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import {
  Button as RaButton, useNotify,
} from 'react-admin';
import styles from './pasteBillOfResources.module.scss';

const reArticleName = /^([0-9]{4})$/;
const reAmountLine = /^([,\s]?[-0-9.]+)$/;

function toFloat(x: string) {
  return parseFloat(x.replaceAll(/\s(\d{3})/g, '$1').replaceAll(/,(\d{3})/g, '$1'));
}

function stripResult(data: (number | undefined)[]) {
  const reverseData = data.reverse();
  const strippedResult = reverseData
    .slice(reverseData.findIndex((x) => x !== undefined))
    .reverse();

  return strippedResult;
}

function usePasteHandler() {
  const [articleNos, setArticleNos] = React.useState<number[]>([]);
  const [amounts, setAmounts] = React.useState<(number | undefined)[]>([]);
  const [headerLines, setHeaderLines] = React.useState<number | null>();
  const [totalLines, setTotalLines] = React.useState<number | null>();

  const notify = useNotify();

  const reset = () => {
    setArticleNos([]);
    setAmounts([]);
    setHeaderLines(null);
  };

  const pasteHandler = React.useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
    const pasteData = event.clipboardData.getData('text/plain');
    const rawLines = pasteData.replaceAll('\r\n', '\n').split('\n');
    const hasArticleNos = articleNos.length > 0;

    if (!hasArticleNos) {
      const headerLinesTemp = rawLines.findIndex((line) => (
        line.match(reArticleName)
      ));
      const lines = rawLines
        .slice(headerLinesTemp)
        .map((line) => {
          if (line.match(reAmountLine)) {
            return parseInt(line, 10);
          }
          return undefined;
        });

      const strippedResult = stripResult(lines);

      if (strippedResult.length < 2 && strippedResult.find((x) => x === undefined)) {
        throw new Error(`wrong data: ${pasteData}`);
      } else {
        // @ts-ignore
        setArticleNos(strippedResult);
        setTotalLines(rawLines.length);
        setHeaderLines(headerLinesTemp);
      }
    } else {
      if (headerLines === null || (totalLines || 0) < rawLines.length) {
        console.log(headerLines, totalLines, rawLines.length);
        notify('line length mismatch', { type: 'error' });
        return;
        // throw new Error('line length mismatch');
      }

      const lines = rawLines.slice(headerLines);
      const result = stripResult(
        lines
          .map((line) => {
            if (line.length > 0) {
              return toFloat(line);
            }
            return undefined;
          }),
      );

      if (result.length <= articleNos.length && result.length > 0) {
        setAmounts(stripResult(result));
      } else {
        console.log(result.length, articleNos.length);
        // throw new Error('mismatched length!');
        notify('line length mismatch', { type: 'error' });
        throw new Error(`line length mismatch: ${pasteData}`);
      }
    }
  }, [notify, articleNos, setArticleNos, setTotalLines, setAmounts]);

  const items = React.useMemo((): [number, number][] => articleNos.map((value, index) => [
    value,
    amounts[index] || 0,
  ]), [articleNos, amounts]);

  return {
    articleNos,
    amounts,
    items,
    pasteHandler,
    reset,
  };
}

interface ItemListProps {
  items: [number, number][]
}
function ItemList(props: ItemListProps) {
  const {
    items,
  } = props;

  if (items.length > 0) {
    return (
      <div className="table">
        <table>
          <thead>
            <th>ArticleNo</th>
            <th className="amount">Amount</th>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr>
                <td>{item[0]}</td>
                <td className="amount">{item[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <div>
      <p>Paste here</p>
    </div>
  );
}

interface PasteBillOfResourcesProps {
  onSubmit: (data: [number, number][]) => Promise<void>;
}

export default function PasteBillOfResources(props: PasteBillOfResourcesProps) {
  const {
    onSubmit,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [pasteConfirmed, setPasteConfirmed] = React.useState(false);
  const {
    items, articleNos, amounts, pasteHandler, reset: resetItems,
  } = usePasteHandler();

  const close = React.useCallback(() => {
    setOpen(false);
    resetItems();
    setPasteConfirmed(false);
  }, [setOpen, resetItems, setPasteConfirmed]);

  const submit = React.useCallback(
    async () => {
      await onSubmit(items);
      close();
    },
    [close, onSubmit, items],
  );

  return (
    <>
      <RaButton
        onClick={() => setOpen((value) => !value)}
        label="Create from Balance"
        startIcon={<BalanceIcon />}
      />
      <Dialog
        className={styles.paste_dialog}
        open={open}
        onClose={close}
        onPaste={pasteConfirmed ? undefined : pasteHandler}
      >
        <DialogTitle>Create from Balance</DialogTitle>
        <DialogContent
          className={styles.dialog_content}
          autoFocus
        >
          <Typography>
            Paste here the following columns from the production file:
          </Typography>
          <ul>
            <li className={clsx('task', articleNos.length > 0 && 'done')}>
              Article No
            </li>
            <li className={clsx('task', amounts.length > 0 && 'done')}>
              Balance column
            </li>
          </ul>
          <ItemList items={items} />
          <DialogActions>
            <Button
              disabled={items.length < 1}
              onClick={() => resetItems()}
            >
              Reset
            </Button>
            <Button
              disabled={items.length < 1 || amounts.length < 1}
              onClick={() => submit()}
            >
              Continue
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
