import * as React from 'react';
import { Show } from 'react-admin';
import { Grid } from '@mui/material';
import ShowActions from 'components/layout/actions/show';
import ShowTitle from 'components/commons/showTitle';
import ShowLabel from 'components/commons/showLabel';
import ShowField from 'components/commons/showField';

export default function () {
  return (
    <Show actions={<ShowActions />}>
      <Grid container rowSpacing={1} spacing={1} sx={{ marginLeft: '3px', marginTop: '3px', marginBottom: '10px' }}>
        <Grid item xs={12} md={12}>
          <ShowTitle />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} sx={{ borderBottom: '1' }}>
          <ShowLabel label="resources.auth/groups.fields.id" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="id" className="highLightField" />
        </Grid>
        <Grid className="show-row-odd" item xs={2} md={1.5} />
        <Grid item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/groups.fields.name" />
        </Grid>
        <Grid item xs={8} md={9}>
          <ShowField source="name" className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/groups.fields.typeName" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="typeName" className="highLightField" />
        </Grid>
        <Grid item xs={2} md={1.5} />
        <Grid className="show-row-odd" item xs={2} md={1.5}>
          <ShowLabel label="resources.auth/groups.fields.description" />
        </Grid>
        <Grid className="show-row-odd" item xs={8} md={9}>
          <ShowField source="description" />
        </Grid>
        <Grid item xs={2} md={1.5} />
      </Grid>
    </Show>
  );
}
