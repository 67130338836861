import React, { useMemo } from 'react';
import {
  Identifier, Validator, useInput,
} from 'react-admin';
import { useCategories } from 'repositories/products/categories';
import { ProductCategorySchema } from 'api';
import { MakeTree, ObjectWithChildren } from 'utils/tree';
import {
  Box, Button, Chip, Collapse,
  FormControl, IconButton, InputLabel, Popover, Select, SelectProps,
} from '@mui/material';
import pop from 'utils/pop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import styles from './categoryChoose.module.scss';

export interface CategoryChooseProps {
  value?: Identifier[] | null,
  placeholder?: undefined | string,
  label?: undefined | string | React.ReactNode,
  onChange: (selectedValue: Identifier[] | null) => void,
}

const useCategoryTree = () => {
  const categories = useCategories();
  return React.useMemo(
    () => MakeTree((categories?.data || []) as Category[]),
    [categories.data],
  );
};

type Category = Omit<ProductCategorySchema, 'id'> & {
  id: number
};

interface CategoryListItemProps {
  category: ObjectWithChildren<Category>,
  selectedIds: number[],
  onClick: (id: Category) => void,
}

function CategoryListItem(props: CategoryListItemProps) {
  const {
    category,
    selectedIds,
    onClick,
  } = props;

  const hasChildren = React.useMemo(() => category.children.length > 0, []);
  const isActive = React.useMemo(
    () => selectedIds && selectedIds.findIndex((id) => category.id === id) >= 0,
    [selectedIds, category],
  );
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    function hasActiveChildren(cat: ObjectWithChildren<Category>) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of cat.children) {
        if (selectedIds && selectedIds.findIndex((id) => child.id === id) >= 0) {
          setOpen(true);
          return;
        }
        hasActiveChildren(child);
      }
    }

    hasActiveChildren(category);
  }, [selectedIds]);

  return (
    <div className={styles.category_list_item}>
      <div className={clsx('title', isActive && 'active')}>
        <IconButton disabled={!hasChildren} onClick={() => setOpen(!open)}>
          <ExpandMoreIcon />
        </IconButton>
        <Button onClick={(event) => { event.preventDefault(); onClick(category); }}>{category.name}</Button>
      </div>
      <Collapse className="children" in={open}>
        {category.children.sort((cat1, cat2) => (cat1.name > cat2.name ? 1 : -1)).map((child) => (
          <CategoryListItem
            category={child}
            key={child.id}
            selectedIds={selectedIds}
            onClick={onClick}
          />
        ))}
      </Collapse>
    </div>
  );
}

export default function CategoryChoose(props: CategoryChooseProps) {
  const {
    label,
    placeholder,
    onChange,
    value,
  } = props;

  const cleanValue = (value || undefined) as number[]; // remove null value

  const choices = useCategoryTree();
  const categories = useCategories();

  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const open = (event: React.SyntheticEvent<Element>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const selectedCategories = useMemo(
    () => cleanValue?.map((id) => categories.data?.find((x) => x.id === id)) || [],
    [cleanValue, choices],
  );

  const handleSelect = (cat: Category) => {
    const existingIndex = cleanValue?.findIndex((id) => id === cat.id);
    let newValues: Identifier[];

    if (existingIndex !== undefined && existingIndex >= 0) {
      newValues = cleanValue ? pop(cleanValue, existingIndex) : [];
    } else {
      newValues = cleanValue ? [...cleanValue, cat.id] : [cat.id];
    }
    onChange(newValues);
  };

  const maxHeight = React.useMemo(
    () => (anchorEl ? (window.innerHeight - anchorEl.getBoundingClientRect().bottom - 10) : undefined),
    [anchorEl, window.innerHeight],
  );

  return (
    <FormControl
      className={styles.category_chooser}
      sx={{ minWidth: 200 }}
      size="small"
    >
      <InputLabel id="select-label">{label ?? placeholder}</InputLabel>
      <Select
        labelId="select-label"
        value={selectedCategories}
        open={false}
        onOpen={open}
        renderValue={(items) => items.map((cat) => (
          <Chip style={cat?.color ? { backgroundColor: cat.color } : undefined} key={cat?.id} label={cat?.name} />
        ))}
      />
      <Popover
        id="options"
        className="options"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box maxHeight={maxHeight}>
          {
            choices.map(
              (child) => (
                <CategoryListItem
                  category={child}
                  key={child.id}
                  selectedIds={cleanValue}
                  onClick={handleSelect}
                />
              ),
            )
          }
        </Box>
      </Popover>
    </FormControl>
  );
}

// type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface CategoryInputProps extends Omit<SelectProps, 'onChange' | 'value'> {
  onChange?: (value: Identifier[] | null) => void,
  value?: Identifier[] | null
  source: string;
  parse?: (value: any) => any,
  format?: (value: any) => any,
  validate?: Validator | Validator[],
  isLoading?: boolean;
  isFetching?: boolean;
}

export function CategoryInput(props: CategoryInputProps) {
  const {
    defaultValue,
    parse,
    format,
    onBlur,
    onChange,
    resource,
    source,
    validate,
    isLoading,
    isFetching,
    ...rest
  } = props;

  const { field } = useInput({
    defaultValue,
    parse,
    format,
    onBlur,
    onChange,
    resource,
    source,
    validate,
  });

  const onChangeValue = (categories: Identifier[] | null) => {
    field.onChange(categories);
    onChange?.(categories);
  };

  return (
    <CategoryChoose
      {...rest}
      {...field}
      value={field.value}
      onChange={onChangeValue}
    />
  );
}
