import rawMaterialList from './list';
import editRawMaterial from './editRawMaterialContainer';
import createRawMaterial from './createRawMaterialContainer';
import showRawMaterial from './showRawMaterialContainer';

export default {
  name: 'product/raw-materials',
  list: rawMaterialList,
  edit: editRawMaterial,
  create: createRawMaterial,
  show: showRawMaterial,
};
