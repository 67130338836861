import {
  Checkbox, Container, FormControlLabel, Grid,
} from '@mui/material';
import { StockSplitRequest } from 'api';
import StockLineChooser from 'components/chooser/stock/stockLine';
import QuantityInput from 'components/input/quantity/quantityInput';
import React from 'react';
import {
  CreateView,
  NumberInput, TextInput, Title, Toolbar, useRedirect, useResourceContext,
} from 'react-admin';
import { FormProvider, useForm } from 'react-hook-form';
import api from 'repositories/api';
import { useEnumQuery } from 'repositories/app';
import { Source } from 'utils/source';
import exportStockLineQrCode from '../stockLine/qrCode';
import { useStockData } from '../stockMove/lines/popup/popupAddMoveLine';

interface StockSplitData extends StockSplitRequest {
  stockLocationId: number;
  stockLineId: number;
  amount: number;
  shelfId?: number;
}

export default function CreateStockSplit() {
  const form = useForm<StockSplitData>();
  const s = Source<StockSplitData>();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const [splitByAmount, setSplitByAmount] = React.useState(false);
  const [maxAmount, setMaxAmount] = React.useState(0);
  const { data: enums } = useEnumQuery();

  const perUnit = form.watch('amountPerUnit');
  const units = form.watch('num');

  const submit = (async (formData: StockSplitRequest) => {
    const result = await api.stockSplitCreate({
      stockSplitRequest: formData,
    });

    exportStockLineQrCode(result.lines
      .map((line) => {
        const stockLine = line.destinationStockLine ? line.destinationStockLine : line.stockLine;
        return {
          ...stockLine,
        };
      }), enums?.quantityTypes);
    redirect('show', resource, result.id);
  });

  const stockLine = useStockData(form, submit);

  return (

    <form {...form} onSubmit={form.handleSubmit(submit)}>
      <FormProvider
        {...form}
      >
        <CreateView>
          <Title title="Create Stock Split" />
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <StockLineChooser
                  {...s('stockLineId')}
                  onChangeValue={(line) => setMaxAmount(line.amount)}
                  stockLocation={form.watch('stockLocationId')}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={splitByAmount}
                      onChange={() => setSplitByAmount(!splitByAmount)}
                    />
                  )}
                  label="Split By Bucket size"
                />
              </Grid>
              {splitByAmount ? (
                <Grid item xs={12} md={6}>
                  <QuantityInput
                    {...s('amountPerUnit')}
                    max={maxAmount}
                    quantityType={stockLine?.quantityTypeId}
                    disabled={!!units}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <NumberInput {...s('num')} max={maxAmount} label="units" disabled={!!perUnit} fullWidth />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextInput {...s('comment')} fullWidth multiline />
              </Grid>
            </Grid>

          </Container>

          <Toolbar />
        </CreateView>
      </FormProvider>
    </form>
  );
}
