import * as React from 'react';
import {
  TextField,
  DateField,
  BooleanField,
  EmailField,
} from 'react-admin';
import ListActions from 'components/layout/actions/list';
import { Source } from 'utils/source';
import { AuthUserEntity } from 'api';
import urls from 'app/urls';
import { ActionButtons } from 'components/list/actionButtons';
import List from 'components/list/List';
import Datagrid from 'components/list/dataGrid';
import filters from './filters';
import { HijackButton } from './hijack';

export default React.memo(() => {
  const s = Source<AuthUserEntity>();

  return (
    <List filters={filters} filterDefaultValues={{ isActive: true }} actions={<ListActions exporter />}>
      <Datagrid optimized>
        <TextField {...s('username')} />
        <EmailField {...s('email')} />
        <DateField {...s('lastLogin')} showTime />
        <DateField {...s('lastLogout')} showTime />
        <BooleanField {...s('isActive')} />
        <BooleanField {...s('isSpecialAccess')} />
        <ActionButtons url={urls.Users_Update.url} label="action" />
        <HijackButton label="Hijack" />
      </Datagrid>
    </List>
  );
});
