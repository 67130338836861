import stockLineList from './listStock';
import showStock from './showStock';

export default {
  name: 'stock/stock',
  list: stockLineList,
  show: showStock,
  options: {
    label: 'Stock',
  },
};
