import * as React from 'react';
import {
  ListButton, TopToolbar,
} from 'react-admin';
import { ToolbarProps } from '@mui/material';
import useResourcePermissions from './resourcePermissions';

export default function CreateActions(props: ToolbarProps) {
  const permissions = useResourcePermissions();

  return (
    <TopToolbar {...props}>
      {permissions.list && <ListButton />}
    </TopToolbar>
  );
}
